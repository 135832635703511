import React, {useState} from "react";
import ExpressInterestSignUpCss from "./ExpressInterestSignUp.module.css"
import {connect} from "react-redux";
import EIQuestionnaireCss from "../ExpressInterestQuestionnaire/ExpressInterestQuestionnaire.module.css";
import ReactSecondaryButton from "../../../assets/js/ReactSecondaryButton";
import {useHistory} from "react-router-dom";
import {userDetailsService} from "../../../services/userDetailsService";
import ModalUnScrollable from "../../../components/common/Model/ModalUnScrollable";
import Loading from "../../../components/common/Loading/Loading";
import {ENROLLED, WAITLISTED} from "../../../Constants/Status";
import {processQuestionnaire} from "../../../util/EnrollmentFlow";

function ExpressInterestSignUp(props) {

    let vehicleList =  props?.user?.userVehicles || []

    vehicleList = vehicleList.filter((items=>(items.status !==ENROLLED && items.status!==WAITLISTED)))

    let {selectedValues, onChange, selectedVehicleList, onVinSelect, width} = props;
    const [confirmSignUp, setConfirmSignUp] = useState(false)
    const [signUpClicked, setSignUpClicked] = useState(false)

    const history =  useHistory();

    const handleSubmit = async () => { //selectedValues, selectedVehicleList

        setSignUpClicked(true)
        await userDetailsService.expressInterestSignUp(processQuestionnaire(selectedValues, selectedVehicleList)).then(()=>setConfirmSignUp(true))

    }

    const navigateToAccount =()=>{
        history.push("/account")
        window.location.reload()
    }

    return (
<div>
    <div className="fds-p--t-3" style={width <= 650 ? { padding: "0 40px 0 40px"}: {}} data-testid="outerDiv" >
        {signUpClicked ? <Loading/>:
            <>
                <div className={ExpressInterestSignUpCss.yourVehiclesTitle}> Your Vehicles</div>
                <div>
                    <fieldset className="fmc-check">
                        { vehicleList.map( (items, i) => {
                            return <div className={"fds-m--t-2"} key={i}>
                                <label>
                                    <input type="checkbox"
                                           name="checkbox-item-1"
                                           value={items.vin}
                                           id = {items.vin}
                                           data-testid={items.vin}
                                           checked={selectedVehicleList.includes(items.vin)}
                                           onChange={onVinSelect}
                                           className={ExpressInterestSignUpCss.textInputBody}/>
                                    <span className={ExpressInterestSignUpCss.textInputBody}> {items.modelYear} {items.modelName} </span>
                                </label>
                                <span className={ExpressInterestSignUpCss.vinNumber}> VIN: {items.vin} </span>
                            </div>
                        })}
                    </fieldset>
                </div>

                <div className={ExpressInterestSignUpCss.eapResearchPanel}> EAP Research Panel</div>

                <div className={ExpressInterestSignUpCss.helpDrive}>Help drive the future of Ford</div>
                <p className={ExpressInterestSignUpCss.description}>
                    While you wait for word about membership, we have another great way you can help shape the future of Ford <br/>
                    — the Early Access Research Panel. Panel members are asked to join informal chats (via video conference), quick <br/>
                    surveys and other research opportunities. It’s a lighter way to participate.
                </p>

                <div>
                    <fieldset className="fmc-check">
                        {["Yes, I am in", "No, maybe later"].map( items => {
                            return (<label className="fds-p--l-1" key={items}>
                                <input type="radio" name="question9" value={items}
                                       onChange={onChange} checked={selectedValues["question9"] === items }/>
                                <span className={EIQuestionnaireCss.optionsStyle}> {items} </span>
                            </label>)

                        })}
                    </fieldset>
                </div>

                <div className={" fds-align--center fds-p--b-1 fds-p--t-3"}>

                    <button className="fmc-button"
                            disabled={selectedVehicleList.length === 0}
                            onClick={handleSubmit}
                            data-testid={"signupButton"} >
                        Sign up<span className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"/>
                    </button>

                </div>
                <div className="fds-align--center fds-p--b-1">
                    <button className="fmc-text-button fds-m--t-2"
                            onClick={() => props.onPrevious("Sign Up")}
                            data-testid={"goBackToQuestionnaire"}>
                        Go back
                    </button>
                </div>
            </>}


    </div>
    <ModalUnScrollable
        id="expressSignUp"
        data-cy = "expressSignUpPopUp"
        show={confirmSignUp}
        close={
            <ReactSecondaryButton
                style={{ float: "right", padding: "10px" }}
                colorScheme="light"
                onClick={navigateToAccount}
                data-testid="close"
            >
                        <span className="fds-secondary-button__icon-wrapper fds-secondary-button__icon-left">
                            <span className="fds-icon fds-icon fds-font--ford-icons__clear"
                                  aria-label="close"
                                  tabIndex={1}/>
                        </span>
            </ReactSecondaryButton>}
        modelIcon={null}
        modelHeader="Thanks!"
        modalCenter={ExpressInterestSignUpCss.modalCenter}
        modalScroll={ExpressInterestSignUpCss.modalScroll}
        modelSubHeader={"Ford Early Access Program"}
        modelContent={
            <div className={ExpressInterestSignUpCss.modalScroll}>
                <div className="fds-p--t-1" aria-label={"mailto:pearlyac@ford.com will open in new window"}>
                    <p>We appreciate your interest. Watch for an email letting you know if you've been accepted
                        into the program and the next steps to take.</p>
                    <br/>
                </div>
            </div>
        }
        modelFooter={<div style={{ display: "inline" }}>
            <div style={{ padding: "0 20px", display: "inline-block" }}>
                <button className="fmc-button"  onClick={navigateToAccount}
                        data-testid ="gotoMyAccount">
                    <span data-testid={"goToMyAccoutBtn"} className="fds-primary-button__button-text ">
                                Go to My Account
                            </span>
                </button>


            </div>
        </div>}
    />
</div>

    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user,
    };
};

export default connect(mapStateToProps)(ExpressInterestSignUp);


