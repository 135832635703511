import React from "react";
import fmaFunctions from "../../../services/fmaFunctions";
import {useAmplitude} from "useAmplitude/src/useAmplitude";
import {connect} from "react-redux";
import userIcon from "../../../assets/userProfile.svg";
import powerIcon from "../../../assets/O001 - Power.svg"
import FordLogo from "../../../assets/FordOval-white.svg"

import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderCss from "./Header.module.css";


const Header = (props) => {

    const pathName = window.location.pathname;
    const {logger} = useAmplitude();

    const login = () => {
        logger("join today tapped", {
            buttonLocation: "header",
            screenName: "landing page",
            previousUrl: document.referrer,
        }).then();
        fmaFunctions().login();
    };

    const logout = () => {
        logger("sign out tapped", {
            screenName: "account",
            previousUrl: document.referrer,
        }).then();

        fmaFunctions().logout();
    };

    const handleSignIn = ()=>{
        if (pathName === "/" && (window.fma.isAuthenticated === false)) login();
        else logout();
    }

    return (
        <header>
            <div className={HeaderCss.mainContainer}>
                <div className={HeaderCss.logoTextContainer}>
                    <img src={FordLogo} alt="Ford Icon"/>
                    <span className={HeaderCss.logoTextSeparator}/>
                    <div data-testid="logoText" className={HeaderCss.logoText}> Early Access Program</div>
                </div>

                {(window.fma.isAuthenticated === false || window.fma.isAuthenticated === undefined) ?

                    <div className={HeaderCss.headerSignIn}>
                       <img src={userIcon} alt="userIcon"/>

                        <button className="fmc-text-button" data-dark="" data-testid="signInBtn"onClick={handleSignIn}
                        > Sign In
                        </button>
                    </div> : <div className={HeaderCss.headerSignIn}>
                         <img src={powerIcon} alt="userIcon"/>
                        <button className="fmc-text-button" data-dark="" data-testid="signOutBtn" onClick={logout}
                        > Sign Out
                        </button>
                    </div>
                }
            </div>

        </header>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};

export default connect(mapStateToProps)(Header);
