import HomeModulePart1Css from "./Billboard.module.css";
import classNames from "classnames";
import React from "react";
import fmaFunctions from "../../../services/fmaFunctions";
import {useAmplitude} from "useAmplitude/src/useAmplitude";
import {useHistory} from "react-router-dom";

export default function Billboard() {
  const { logger } = useAmplitude();
  const history = useHistory()
  const login = ()=>{
    logger("sign in button tapped", {
      buttonLocation: "banner",
      screenName: "landing page",
      previousUrl: document.referrer,
    }).then();
    fmaFunctions().login()
  }
  const expressInterest = () => {
    logger("express interest button tapped", {
      buttonLocation: "banner",
      screenName: "landing page",
      previousUrl: document.referrer,
    }).then();
    history.push("/expressInterest")
  };
  return (
      <div
          className={
            HomeModulePart1Css.homeContainer + " fds-layout-grid fds-bg--dark"
          }
      >
        <div>
          <div className="fds-layout-grid__inner fds-type--title1">
            <h1
                data-testid={"billboardHeader"}
                className={classNames(
                    HomeModulePart1Css.verticalSpan300,
                    "fds-layout-grid__cell--span-12 fds-type--title1 fds-m--b-3"
                )}
            >
              Let's build the future together
            </h1>
          </div>
          <div className="fds-layout-grid__inner">

          </div>
          <div className="fds-layout-grid__inner">
            <div
                className={classNames(classNames(HomeModulePart1Css.expressInterestBtn,"fds-layout-grid__cell--span-6"))} >
              <button className="fmc-button" onClick={expressInterest} data-dark data-testid="expressInterestBannerBtn" >Express Interest
                <span className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"/></button>
            </div>

            <div style={{top: "-45px", position: "relative"}}

                 data-testid={"subHeaderText"}
                className={classNames(
                    HomeModulePart1Css.billboardSubHeaderTextContainer,
                    " fds-layout-grid__cell--span-12 fds-color__text--white fds-p--b-2"
                )}
            >
              Already a member? <a data-testid={"singInBtn"} className={HomeModulePart1Css.billboardSubHeaderText} onClick={login}>Sign in</a>
            </div>
          </div>
        </div>
      </div>
  );
}
