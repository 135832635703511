import React, {useEffect, useState} from "react";
import IPCss from "../IntelligentPower.module.css"
import {
    BATTERYHEALTH,
    CUTDOWNCOSTTXT,
    GOEASIERTXT,
    HOWDOESIPWORKPRG1,
    HOWDOESIPWORKPRG2, MOBILECONTROL,
    WHATISINTELLIGENTPOWER, INSIGHTS, INTELLIGENTBACKUPPOWER, BOTTOMWAITLISTTEXT,
} from "../IPConstant/IPConstant";
import CheckMark from "../../../assets/accept.svg"
import CarImage from "../../../assets/23_FRD_F150_AZGR_Lightning_Home_v2.jpg"
import BatteryCharging from "../../../assets/BatteryCharging.svg"
import TruckIcon from "../../../assets/Truck.svg"
import MobileControl from "../../../assets/MobileControl.svg"
import BatteryHealth from "../../../assets/BatteryHealth.svg"
import Insights from "../../../assets/Insights.svg"
import IntelligentBP from "../../../assets/IntelligentBackUp.svg"
import PrivacyIcon from "../../../assets/Ford assist.svg"
import classNames from "classnames";

export default function IntelligentPowerDescription(props) {

    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    })

    const howDoesIntelligentPowerWork = [
        {
            logo: TruckIcon,
            description: "Plug in your F-150 Lightning at home",
            style: IPCss.TruckIcon
        },
        {
            logo: BatteryCharging,
            description: "Power your home during high-cost periods and charge your F-150 Lightning during low-cost periods",
            style: IPCss.BatteryChargingIcon
        },
        {
            logo: CheckMark,
            description: "Save on your utility bill and reduce stress on the power grid",
            style: IPCss.CheckMarkIcon
        }
    ]

    const takeChargeOfYourEnergy = [
        {
            logo: MobileControl,
            title: "Plug in your F-150 Lightning at home",
            description: MOBILECONTROL,
            style: IPCss.MobileControl
        },
        {
            logo: BatteryHealth,
            title: "Battery Health",
            description: BATTERYHEALTH,
            style: IPCss.BatteryHealth
        },
        {
            logo: Insights,
            title: "Insights",
            description: INSIGHTS,
            style: IPCss.CheckMarkIcon
        },
        {
            logo: IntelligentBP,
            title: "Ford Intelligent Backup Power",
            description: INTELLIGENTBACKUPPOWER,
            style: IPCss.IntelligentBP
        }
    ]

    const desktopTrackerInfo = <ul className={classNames("fmc-tracker ", IPCss.trackerContainer)}>
        {howDoesIntelligentPowerWork.map((step, idx) => {
            return (
                <li className={classNames("fmc-tracker__step", IPCss.trackerStepCotnainer)}>
                    <div className="fmc-tracker__dot">
                        <div>
                            <img src={step.logo} className={`${step.style}`}/>
                        </div>

                    </div>
                    <p className={IPCss.iconDesc}>
                        {step.description}</p>

                </li>
            )
        })}
    </ul>

    const mobileTrackerInfo = <div className={IPCss.howDoesIPWorkContainerMob}>
        <div className={IPCss.iconDescContainerM}>
            <div className={IPCss.iconCircleMb}>
                <img src={TruckIcon} className={IPCss.iconStyle}/>

            </div>
            <span className={IPCss.iconDescM}>
                Plug in your F-150 Lightning at home</span>


        </div>

        <div className={IPCss.hrLineMobile}></div>

        <div className={IPCss.iconDescContainerM}>
            <div className={IPCss.iconCircleMb}>
                <img src={BatteryCharging} className={IPCss.BatteryHealthIcon}/>

            </div>
            <span className={IPCss.iconDescM}>
                Power your home during high-cost periods and charge your F-150 Lightning during low-cost
                    periods</span>

        </div>

        <div className={IPCss.hrLineMobile}></div>

        <div className={IPCss.iconDescContainerM}>
            <div className={IPCss.iconCircleMb}>
                <img src={CheckMark} className={IPCss.CheckMarkIcon}/>

            </div>
            <span className={IPCss.iconDescM}>
               Save on your utility bill and reduce stress on the power grid</span>

        </div>
    </div>


    return (
        <div>

            <div className={IPCss.whatIsIPContainer}>
                <h5 className={"fmc-type--heading5"}>What is Ford Intelligent Power?</h5>
                <p>{WHATISINTELLIGENTPOWER}</p>
            </div>

            <div className={IPCss.howDoesIPWorkContainer}>
                <h5 className={"fmc-type--heading5"}>How Does Ford Intelligent Power Work?</h5>
                <p>{HOWDOESIPWORKPRG1}</p>
                <p className={IPCss.howDoesIPWorkContainerPRG2}>{HOWDOESIPWORKPRG2}</p>
                {width >= 630 ? desktopTrackerInfo : mobileTrackerInfo}
            </div>

            <div className={IPCss.saveMoneyTxt}>
                <div>
                    <h5 className={classNames("fmc-type--heading5", IPCss.mainMiddleHeaderTxt)}>Save Money. Support the
                        Grid.</h5>
                    <p className={IPCss.saveMoneySubHeader}>
                        Cut Down on Electricity Costs
                    </p>
                    <p className={IPCss.saveMoneySubHeaderDesc}>
                        {CUTDOWNCOSTTXT}
                    </p>

                    <p className={IPCss.saveMoneySubHeader}>
                        Go Easier on the Grid
                    </p>

                    <p className={IPCss.saveMoneySubHeaderDesc}>
                        {GOEASIERTXT}
                    </p>
                </div>

                <div>
                    <img src={CarImage} className={IPCss.saveMoneyCarImg}/>
                </div>
            </div>

            <div className={IPCss.takeChargeMain}>
                <p className={IPCss.takeChargeHeaderTitle}>Take Charge of Your Energy</p>

                <div className={IPCss.takeChargeContainer}>

                    {takeChargeOfYourEnergy.map((item, idx) => {
                            return (
                                <div className={IPCss.takeChargeBoxContainer}>
                                    <img src={item.logo} className={IPCss.takeChargeLogoIcon}/>
                                    <p className={IPCss.takeChargeTitle}>{item.title}</p>
                                    <p className={IPCss.takeChargeDesc}>{item.description}</p>
                                </div>
                            )
                        }
                    )}

                </div>
            </div>

            <div>
                <h5 className={classNames("fmc-type--heading5", IPCss.homePowerTransferTitle)}>Ford Home Power Transfer
                    Products</h5>

                <div className={IPCss.powerTransferMain}>
                    <div className={IPCss.one}>
                        1
                    </div>

                    <div className={IPCss.two}>
                      Ford Intelligent Backup <br/>Power
                        <hr/>
                        <p className={IPCss.benefitsMob}>Benefits</p>
                    </div>

                    <div className={IPCss.three}>
                        Ford Intelligent Power <br/>(Early Access Program)
                        <hr className={IPCss.dividerIPEAP}/>
                        <p className={IPCss.benefitsMobBlue}>Benefits</p>

                    </div>

                    <div className={IPCss.four}>
                        Benefits
                    </div>

                    <div className={IPCss.five}>
                        <ul class="fmc-list">
                            <li>
                                Automatically provides up to 9.6kWh of power to your home during a grid power outage
                            </li>
                        </ul>
                    </div>

                    <div className={IPCss.six}>
                        <ul className="fmc-list">
                            <li>
                                Automatically provides up to 9.6kWh of power to your home during high-cost utility windows
                                and creates a charging schedule during low-cost windows
                            </li>
                            <li>
                                Plus, it offers everything that Ford Intelligent Backup Power does
                            </li>
                        </ul>
                    </div>

                    <div className={IPCss.seven}>
                        What's needed
                    </div>

                    <div className={IPCss.eight}>
                        <div className={IPCss.whatsNeededMobBlue}>
                            What's needed
                        </div>
                        <ul class="fmc-list">
                            <li>
                                F-150 Lightning with Standard or Extended Range Battery
                            </li>
                            <li>
                                Sunrun Home Integration System
                            </li>
                            <li>
                                Ford Charge Station Pro
                            </li>
                        </ul>
                    </div>

                    <div className={IPCss.nine}>
                        <div className={IPCss.whatsNeededMob}>
                            What's needed
                        </div>

                        <ul class="fmc-list">
                            <li>
                                F-150 Lightning with Standard or Extended Range Battery
                            </li>
                            <li>
                                Sunrun Home Intergration System
                            </li>
                            <li>
                                Ford Charge Station Pro
                            </li>

                            <li>
                                Ford Intelligent Backup Power Enabled
                            </li>
                            <li className={IPCss.nineLastPoint}>
                                Time-of-use Utility Rate Plan <span
                                className="fds-icon fds-icon--32 fds-icon--offset-right-sm fds-font--ford-icons__info-outlined"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className={IPCss.joinWaitListBottom}>
                <button className="fmc-button" onClick={props.handleSurvey}  data-testid="signUpFooterBtn">Sign Up
                    <span
                        className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"/>
                </button>
                {BOTTOMWAITLISTTEXT}

            </div>

            <div className={IPCss.privacyFooter}>
                <div>
                    <img src={PrivacyIcon} className={IPCss.privacyIcon}/>

                    <p className={IPCss.footerHeaderText}>
                        Other important information
                    </p>

                    <p className={IPCss.privacyPolicySubText}>
                        Please see the <a href={"https://www.ford.com/help/privacy/"} target={"_blank"}> FordPass Terms and Privacy Policy</a> for information related to your Ford Account and FordPass services, including the handling of personal information.
                    </p>
                </div>
            </div>

            <div>

            </div>
        </div>
    )

}