export const WHATISINTELLIGENTPOWER=<p>Ford Intelligent Power transforms your F-150 Lightning into an innovative energy storage system that can provide power to your home when electricity costs are high and recharge your truck when costs are lower.
    To learn more, please refer to our Ford Intelligent Power <a style={{textDecoration: "underline"}} href={"https://www.ford.com/support/how-tos/electric-vehicles/home-charging/f-150-lightning-intelligent-power-faqs/"} target={"_blank"}> FAQs article</a >.</p>
export const HOWDOESIPWORKPRG1="Ford Intelligent Power uses data from your time-of-use utility rate plan, driving habits, and vehicle battery health to create a cost-optimized energy schedule around transferring power to your home and charging your truck."
export const HOWDOESIPWORKPRG2="The system is designed to always put your driving needs first, ensuring you'll have enough vehicle range to get to where you need to go. Just plug in your F-150 Lightning while you're at home, and Ford Intelligent Power will do the rest."
export const CUTDOWNCOSTTXT="Save money on your utility bill by using power from your F-150 Lightning instead of the grid during high-cost periods. The vehicle will be automatically charged back up during low-cost periods."
export const GOEASIERTXT = "Powering your home with your F-150 Lightning not only lowers your bill, it also helps alleviate the grid from additional strain."
export const IP = "Ford Intelligent Power"
export const MOBILECONTROL = "See your schedule, override events, change your battery charge reserve, and get alerts on your phone."
export const BATTERYHEALTH = "Ford Intelligent Power monitors battery health to ensure longevity. The amount of energy available to transfer each week will be adjusted based on your driving and charging habits."
export const INSIGHTS = "Understand the impact of offsetting high-cost energy windows by using energy from your vehicle."
export const INTELLIGENTBACKUPPOWER = "If there is a power outage, Ford Intelligent Backup Power will automatically activate and your Ford Intelligent Power schedule will be updated once power is restored."
export const BOTTOMWAITLISTTEXT = <div>Please note that when you join the waitlist for Ford Intelligent Power, you'll receive a special software build and will therefore not be up to date on your software updates sequence. For any questions,
    visit our <a href={"https://www.ford.com/support/how-tos/electric-vehicles/home-charging/f-150-lightning-intelligent-power-faqs/"} target={"_blank"}> FAQs article</a > , or please email us at <a href="mailto:pearlyac@ford.com">pearlyac@ford.com</a></div>



