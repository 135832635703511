import {Route} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import Loading from "../components/common/Loading/Loading";
import fmaFunctions from "../services/fmaFunctions";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

const PrivateRoute = (props) =>
{
    let {component: Component, loading, user, ...rest} = props
    let history = useHistory()
    return !loading ? (
        <Route
            {...rest}
            render={() =>
            {
                if(user?.isAuthenticated){
                    return <Component {...props} />
                }else {
                    localStorage.setItem("path", history.location.pathname)
                    return fmaFunctions().login()
                }
            }
            }
        />
    ) : (
        <Loading/>
    )
};

const mapStateToProps = (state) => {
    return {
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user,
    };
};

export default connect(mapStateToProps)(PrivateRoute);
