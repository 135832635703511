import amplitude from "amplitude-js";

function useAmplitude(userId, userProperties) {
    /* even if Amplitude is off, the instance is still created.
    Communications with the instance that do not go through this
    library obviously ignore its feature flags.*/
    const instance = amplitude.getInstance();

    const featureFlags = {
        debug: process.env.REACT_APP_AMPLITUDE_DEBUG === "Y",
        doNotRecord: process.env.REACT_APP_AMPLITUDE_OFF === "Y",
        raiseExceptions: process.env.REACT_APP_AMPLITUDE_RAISE_EXCEPTIONS === "Y",
    };

    const getPropName = (prop) => {
        if (prop.length < 2) {
            return null;
        }
        // Need to strip spaces and underscores and all that sort of thing here and return camelCase.
        return prop.split("")[0].toLowerCase() + prop.substr(1);
    };

    const getPropValue = (val) => {
        if (!val) {
            return "";
        }
        if (typeof val === "boolean") {
            return val ? "true" : "false";
        }
        if (val.toLowerCase) {
            return val.toLowerCase();
        }
        return val;
    };

    const formatProperties = (properties) => {
        const props = {};

        Object.keys(properties).forEach((prop) => {
            const propName = getPropName(prop);
            if (propName) {
                props[propName] = getPropValue(properties[prop]);
            }
        });

        return props;
    };

    const reportError = (e) => {
        if (featureFlags.debug) {
            console.error("Amplitude error: ", { e });
        }
    };

    const raiseException = (e) => {
        if (featureFlags.raiseExceptions) {
            throw e;
        }
    };
    const logger = async (event, properties, timestamp) => {
        const validVerbs = [
            "tapped",
            "viewed",
            "created",
            "updated",
            "verified",
            "completed",
            "added",
            "removed",
            "cancelled",
            "decision"
        ];

        try {
            if (!event) {
                throw new Error("Event name required");
            }
            if (!event.includes(" ")) {
                throw new Error(
                    "Invalid event. Must begin with a noun and end with an approved verb."
                );
            }
            if (!validVerbs.includes(/[^ ]*$/.exec(event)[0])) {
                throw new Error(
                    `${/[^ ]*$/.exec(event)[0]} is not a valid event verb.`
                );
            }
            if (!properties) {
                throw new Error("Analytics data required");
            }
            if (!properties.screenName) {
                throw new Error("Analytics screen name required.");
            }

            const formattedProperties = formatProperties(properties);

            if (featureFlags.debug) {
                console.info(
                    "%c Amplitude event: " + event,
                    "color: CornflowerBlue; background:white; font-size: 150%;",
                    formattedProperties,
                    { timestamp }
                );
            }

            if (featureFlags.doNotRecord) {
                return;
            } else {
                if (!process.env.REACT_APP_AMPLITUDE_KEY) {
                    throw new Error("Amplitude key not configured.");
                }
            }

            if (timestamp) {
                return instance.logEventWithTimestamp(
                    event.toLowerCase(),
                    formattedProperties
                );
            } else {
                return instance.logEvent(event.toLowerCase(), formattedProperties);
            }
        } catch (e) {
            reportError(e);
            raiseException(e);
        }
    };

    const setUserProfile = (user) => {
        if (typeof user === "string") {
            instance.setUserId(user);
        } else {
            instance.setUserProperties(user);
        }
    };

    try {
        let options = {
            apiEndpoint: process.env.REACT_APP_AMPLITUDE_PROXY_URL
        }
        instance.init(process.env.REACT_APP_AMPLITUDE_KEY,null,options);

        if (userId) {
            instance.setUserId(userId);
        }

        if (userProperties) {
            instance.setUserProperties(userProperties);
        }
    } catch (e) {
        reportError(e);
        raiseException(e);
    }

    return { logger, instance, setUserProfile };
}

const AmplitudeEvent = ({ event, properties, timestamp, userId }) => {
    const { logger } = useAmplitude(userId);
    logger(event, properties, timestamp);
    return null;
};

export { AmplitudeEvent, useAmplitude };