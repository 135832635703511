import Loading from "../components/common/Loading/Loading";

function NetworkLoader(props) {

    return (
        <>
            {
                props.isLoading ? <Loading/> : props.children
            }
        </>
    )


}

export default NetworkLoader