import React from "react";
import HomeText from "./homeModulePart2/HomeText";
import Billboard from "./homeModulePart1/Billboard";

export default function HomeModule() {
    if (window.fma.isAuthenticated === false || window.fma.isAuthenticated === undefined) {
        window.fma.model.config.redirectUrl = window.location.origin + "/consent"
    }
    return (
        <div className="fds-app">
            <Billboard/>
            <HomeText/>
        </div>
    );
}
