import AccountModuleCss from "../account/Account.module.css";
import MyProductSModuleCss from "./MyProducts.module.css";
import HomeModulePart2CSS from "../home/homeModulePart2/HomeText.module.css";
import React, {useEffect, useRef, useState} from "react";
import {FMCTabs} from '@ford/ford-design-system';
import {vehicleDescription} from "../../util/Format";
import classNames from "classnames";
import {connect} from "react-redux";
import MyProductsDefaults from "./MyProductsDefaults";
import {AVAILABLE, ENROLLED, PAST} from "../../Constants/Status";
import MyProductsCard from "./MyProductsCard";

const MyProductsModule = (props) => {
    const tabsRef = useRef(null);

    let enrolledVehicles = props?.vehicleReducer?.enrolledVehicles?.enrolledVehicles || []

    let availableProducts = props?.productReducer?.availableProducts

    useEffect(() => new FMCTabs(tabsRef.current), [])

    const [vehicleChosen, setVehicleChosen] = useState(enrolledVehicles[0])

    const handleVehicleSelect = async (e) => {
        const chosenVehicle = enrolledVehicles.find(items => `${items?.modelYear} ${items?.make} ${items?.modelName} ${items?.vin}` === e.target.value)
        await setVehicleChosen(chosenVehicle)
    }

    let userVehicles = props?.user?.userVehicles || []

    let enrolledVehiclesOnly = userVehicles.filter(items=> items.status ===ENROLLED)

    let enrolledProductsOnly =  vehicleChosen["enrolledProducts"]


    return (
        <div>
            <div className={AccountModuleCss.headerContainerProduct}>
                <div className={AccountModuleCss.menuContainer}>
                    <div className={"fds-display--inline"}>
                        <span onClick={props.backToAccount} className={AccountModuleCss.breadcrumbHover}>Account </span>  >  My Products
                    </div>
                </div>
            </div>
            <div>
                <div className={HomeModulePart2CSS.carouselHeader}>
                    <div className={MyProductSModuleCss.myProductsTitle}>
                        My Products
                    </div>
                </div>
                <div className="js-fmc-tabs fmc-tabs fds-p--t-2" ref={tabsRef} >
                    <div className={MyProductSModuleCss.tabHeaderContainer}>
                        <div className={classNames("fmc-tabs__tablist-wrapper ", MyProductSModuleCss.tabHeaderPadding)} >
                            <div className={classNames("fmc-tabs__tablist", MyProductSModuleCss.tabTextContainer )}
                                 role="tablist"
                                 aria-label="Features">
                                <button
                                    className="fmc-tabs__tab"
                                    role="tab"
                                    aria-selected="true"
                                    aria-controls="FDShTabsImagesPanelCollision"
                                    id="FDShTabsImagesTabCollision"
                                >
                                    <span className={MyProductSModuleCss.tabLabel}> ENROLLED </span>
                                </button>
                                <button
                                    className="fmc-tabs__tab"
                                    role="tab"
                                    aria-selected="false"
                                    aria-controls="FDShTabsImagesPanelClimate"
                                    id="FDShTabsImagesTabClimate"
                                >
                                    <span className={MyProductSModuleCss.tabLabel}> AVAILABLE </span>
                                </button>
                                <button
                                    className="fmc-tabs__tab"
                                    role="tab"
                                    aria-selected="false"
                                    aria-controls="FDShTabsImagesPanelBattery"
                                    id="FDShTabsImagesTabBattery"
                                >
                                    <span className={MyProductSModuleCss.tabLabel}> PAST </span>
                                </button>
                                <span className="fmc-tabs__tab-border"></span>
                            </div>
                        </div>
                        <div className={MyProductSModuleCss.vehicleDropDown}>
                            {(!enrolledVehiclesOnly.length === false) && <select name="vehicles"
                                     id="vehicle-select"
                                     className="fmc-select"
                                     onChange={handleVehicleSelect}>
                                {enrolledVehiclesOnly.map((items) => {
                                    return <option
                                        value={`${items?.modelYear} ${items?.make} ${items?.modelName} ${items?.vin}`}> {vehicleDescription(items)}</option>
                                })}
                            </select>}
                        </div>
                    </div>
                        <div className="fmc-tabs__tabpanels fds-color--gray3" style={{display: "block", paddingTop:"30px"}}>
                            <div id="FDShTabsImagesPanelCollision" className="fmc-tabs__tabpanel" role="tabpanel"
                                 aria-labelledby="FDShTabsImagesTabCollision" tabIndex={0} >
                                { enrolledVehicles.length === 0 || enrolledVehiclesOnly.length===0 ?
                                    <MyProductsDefaults tabName={ENROLLED}/>
                                : <MyProductsCard products={enrolledProductsOnly}
                                                  tabName={ENROLLED}/>
                                }
                            </div>
                            <div id="FDShTabsImagesPanelClimate" className="fmc-tabs__tabpanel" role="tabpanel"
                                 aria-labelledby="FDShTabsImagesTabClimate" tabIndex={-1}>
                                {availableProducts.length === 0 ?
                                    <MyProductsDefaults tabName={AVAILABLE}/>
                                : <MyProductsCard products={availableProducts}
                                                  tabName={AVAILABLE}/>}
                            </div>
                            <div id="FDShTabsImagesPanelBattery" className="fmc-tabs__tabpanel" role="tabpanel"
                                 aria-labelledby="FDShTabsImagesTabBattery" tabIndex={-1}>
                                <MyProductsDefaults tabName={PAST}/>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};

export default connect(mapStateToProps)(MyProductsModule);