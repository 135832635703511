import React, {useEffect, useState} from "react";
import HomeModulePart2CSS from "../home/homeModulePart2/HomeText.module.css";
import {Menu, MenuItem, MenuButton, MenuDivider} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import AccountModuleCss from "./Account.module.css";
import classNames from "classnames";
import {connect} from "react-redux";
import Loading from "../../components/common/Loading/Loading";
import {CustomMenu} from "../../components/common/Menu/CustomMenu";
import {getDynamicCardStyle, vehicleDescription} from "../../util/Format";
import {LEAVEPROGRAM, MANAGEVEHICLES, stepsToEnroll} from "../../Constants/Navigation";
import VehicleImageWithText from "../../components/common/VehicleImage/VehicleImageWithText";
import {INVITED, NOT_INVITED, WAITLISTED} from "../../Constants/Status";
import {ENROLL, WAITLIST} from "../../Constants/CTA";
import {useHistory} from "react-router-dom";
import ConflictVehicle from "../consent/ConflictVehicle/ConflictVehicle";

const AccountModule = (props) => {

    const getButtonStyle = {
        "View": " fmc-text-button--chevron-right fmc-text-button ",
        "Coming Soon": " fmc-text-button "
    }

    const history = useHistory();

    let userVehicles = props?.user?.userVehicles || []

    let notInvitedVehicles = userVehicles.filter( items => items?.status === NOT_INVITED)

    let enrolledVehicles = props?.vehicleReducer?.enrolledVehicles?.enrolledVehicles || []

    let conflictingVehicles = userVehicles.filter(items => items?.conflict === true)

    if(notInvitedVehicles.length === userVehicles.length) {
        history.push("/expressInterest")
    }

    const atLeastOneVehicleEnrolled = enrolledVehicles.length > 0
    const checkEAPEnrollment = props.user?.userProfile?.isEapUser;
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState({
        vin: "",
        "make": "",
        "modelName": "",
        "modelYear": null,
        "status": ""
    })
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("mousedown", () => {
            setMenuOpen(false)
        })
        document.addEventListener("keydown", (e) => {
            if (e.key === 'Escape') {
                setMenuOpen(false)
            }
        })
        setSelectedVehicle(userVehicles[0])
        return window.removeEventListener("mousedown", () => {
            setMenuOpen(false)
        })
    }, [userVehicles])

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    })

    if (!userVehicles.length) return <Loading/>

    const keepMenuOpen = (e) => {
        e.stopPropagation = true;
        e.keepOpen = true;
        setMenuOpen(!menuOpen);
    }

    const getMenuButton = () => {
        return (<MenuButton onClick={keepMenuOpen} data-testid={"vehicle menu"}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <CustomMenu vehicleName={vehicleDescription(selectedVehicle)}
                            status={`${selectedVehicle?.status}`}
                            needChevron={true}
                            menuOpen={menuOpen}/>
            </div>
        </MenuButton>)
    }

    const getDynamicCtaStyle = () => {
        const fixed = "fmc-button "
        const variable = width < 630 ? " fds-p-t--1 " : " fds-m-t-4 "
        return fixed + variable
    }
    if(conflictingVehicles && conflictingVehicles.length) return <ConflictVehicle/>
    return (
        <div>
            <div className={AccountModuleCss.headerContainer}>
                <div className={AccountModuleCss.menuContainer}>
                    <div className={AccountModuleCss.menuItemContainer}>
                    <Menu menuButton={getMenuButton()} transition align={"end"}>
                        {userVehicles.map(items => {
                            return (
                                <React.Fragment key={`${items.vin}`}>
                                    <MenuItem
                                        style={{padding: "1em 1em 0.5em 5em"}}
                                        onClick={() => setSelectedVehicle(items)}>
                                        <CustomMenu vehicleName={vehicleDescription(items)}
                                                    menuOpen={menuOpen}
                                                    status={items.status}/>
                                    </MenuItem>
                                    <MenuDivider/>
                                </React.Fragment>
                            )
                        })}
                        {atLeastOneVehicleEnrolled && [MANAGEVEHICLES, LEAVEPROGRAM].map(items => {
                            return (<React.Fragment key={`${items.trim()}`}>
                                <MenuItem
                                    style={{padding: "1em 1em 0.5em 5em"}}
                                    onClick={() => props.handleMenuClick(items)}>
                                    <CustomMenu vehicleName={items}
                                                menuOpen={menuOpen}
                                                status={""}/>
                                </MenuItem>
                                <MenuDivider/>
                            </React.Fragment>)
                        })}
                    </Menu>
                    </div>
                </div>
                {(width > 630) && <div className={AccountModuleCss.vl}/>}
            </div>
            <div className={classNames(AccountModuleCss.vehicleTopPadding, " fds-color__bg--surface")}>
                <VehicleImageWithText vehicle={selectedVehicle}/>
                <div className="fds-layout-grid fds-align--center">
                    {(([INVITED, NOT_INVITED].includes(selectedVehicle.status) ) || ([NOT_INVITED,WAITLISTED].includes(selectedVehicle.status) && checkEAPEnrollment === "active"))&&
                    <button className={getDynamicCtaStyle()}
                            data-testid="enrollCta"
                            onClick={() => {props.handleMenuClick(((selectedVehicle.status === INVITED) || ((selectedVehicle.status === WAITLISTED || selectedVehicle.status === NOT_INVITED) && checkEAPEnrollment === "active") ) ? ENROLL : WAITLIST, selectedVehicle)}}>
                        {(selectedVehicle.status === INVITED  || (selectedVehicle.status === WAITLISTED || selectedVehicle.status === NOT_INVITED) && checkEAPEnrollment === "active") ? ENROLL : WAITLIST}<span
                        className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"/>
                    </button>}

                    <div style={{paddingTop: "6em", paddingBottom: "6em"}}>
                        <div className=" fds-layout-grid__inner">
                            {width > 630 && <div />}
                            {stepsToEnroll.map((step, i) => {
                                return (
                                    <div className={getDynamicCardStyle(width)} key={`${step.alt}_${i}`}>
                                        <div className="fmc-cards__content">
                                            <div className="fmc-cards__footer">
                                                <div className={
                                                    HomeModulePart2CSS.colStyles +
                                                    " fds-align--center "
                                                }
                                                >
                                                    <div className={AccountModuleCss.imageContainer}>
                                                        <img
                                                            src={step.logo}
                                                            alt={step.alt}
                                                        />
                                                    </div>
                                                    <h3 data-testid={step.header + `CardHeader`}
                                                        className={AccountModuleCss.cardTitle}>
                                                        {step.title}
                                                    </h3>
                                                    <div data-testid={step.header + `Description`}
                                                         className={AccountModuleCss.cardDescription}>
                                                        {step.desc}
                                                    </div>
                                                    <button className={getButtonStyle[step.cta] + " fds-m--t-3"}
                                                            onClick={() => props.handleMenuClick(step.title)}
                                                            disabled={step.title === "Support" && checkEAPEnrollment !== "active"}
                                                            data-testid={`button_${step.title.trim()}`}
                                                    >
                                                        {step.cta}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                    ;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};

export default connect(mapStateToProps)(AccountModule);