import React from "react";
import ReactTopBannerNotification from "../../../assets/js/ReactTopBannerNotification";
import { connect } from "react-redux";
import store from "../../../redux/store/userStore";
import { clearErrors } from "redux/Actions/UserDataActions";

const GlobalErrorHandler = (props) => {

  const onClearErrors = () => {
    store.dispatch(clearErrors());
  };

  return (
    props.error && (
      <ReactTopBannerNotification
        show={!!props.error}
        onCloseMethod={onClearErrors}
      />
    )
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    error: state.userDataReducer.error,
  };
};

export default connect(mapStateToProps)(GlobalErrorHandler);
