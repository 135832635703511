import FinalizeEnrollmentCss from "../FinalizeEnrollment.module.css";
import step2Image from "../../../assets/Car.svg";
import privacyIcon from "../../../assets/Privacy.svg";
import EIOverviewCss from "../../expressInterest/ExpressInterestOverView/ExpressInterestOverview.module.css";
import step1Image from "../../../assets/User.svg";
import step3Image from "../../../assets/home.svg";
import step4Image from "../../../assets/Email.svg";
import * as React from 'react'
import {
    betaLevelSoftwareList, communicationFeedbackList,
    programGuidlinesList
} from "../../expressInterest/ExpressInterestConstants/ExpressInterestConstants";
import {getFormattedList} from "../../../util/Format";
import VinCheckBox from "../VinCheckBox";
import {ENROLLED, NOT_INVITED} from "../../../Constants/Status";
import {isActiveInactive} from "../../../util/EnrollmentFlow";
import Accordion from "../../../components/common/Accordion/Accordion"

const FinalizeEnrollmentDescription = (props) => {

    let userVehicles = props.userVehicles || []

    userVehicles.sort((a, b) => {
        const nameA = a.status.toUpperCase(); // ignore upper and lowercase
        const nameB = b.status.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    let {width} = props;
    const activeInactiveFlow = isActiveInactive(userVehicles)


    const showFordPassTerms = () => {
        window.open('/fpterms', '_blank')
    }

    const showEapTerms = () => {
        window.open('/eapterms', '_blank')
    }

    const titles = [
        "Our expectations",
        "Program guidelines",
        "Beta level software",
        "Communication & Feedback",
        "What to expect from us"]

    const ourExpectationsDesc = <div className={EIOverviewCss.description}>
        Joining the Ford Early Access Program is a commitment. As a member, you'll be part of an early-adopter
        community that requires <br/>active participation and the responsibility to abide by the rules.
    </div>

    const stepsToEnroll = [
        {

            logo: step1Image,
            desc: "You must be 18 years or older",
            alt: "person",
        },
        {
            logo: step2Image,
            desc: "You must own or lease a Ford vehicle",
            alt: "vehicle",
        },
        {
            logo: step3Image,
            desc: "You must be located in the U.S.",
            alt: "location",
        },
        {
            logo: step4Image,
            desc: "You must have an active email address",
            alt: "email",
        },
    ];

    const desktopView = <div className="fds-layout-grid" style={{padding: 0}}>
        <div className="fds-layout-grid__inner fds-p--t-3">
            {stepsToEnroll.map((step, idx) => {
                const commonDivStyle = "fds-align--center fds-layout-grid__cell--span-3"
                return (
                    <div className={commonDivStyle} style={{display: "flex", justifyContent: "space-evenly"}} key={step}>
                        <div>
                            <img
                                src={step.logo}
                                alt={step.alt}
                            />
                            <p className={EIOverviewCss.icomDescription}>
                                {step.desc}
                            </p>
                        </div>
                        {idx === 3 ? null : <div className={EIOverviewCss.vl}/>}
                    </div>

                );
            })}
        </div>
    </div>

    const mobileView = <div style={{padding: "1em"}}>
        {stepsToEnroll.map((step, idx) => {
            const commonDivStyle = " fds-align--center fds-p-t-3"
            return (
                <div
                    key={`${step.desc}`}
                    className={commonDivStyle}
                >
                    <div style={{margin: "2em "}}>
                        <img
                            src={step.logo}
                            alt={step.alt}
                        />
                        <p className="fds-type--body1 fds-align--center fds-p--t-2 fds-p--b-1"
                           style={{color: "#4D4D4D"}}>
                            {step.desc}
                        </p>
                        <div>
                            {idx < 3 ? <hr style={{border: "0.1px solid grey", width: "40%"}}/> : null}
                        </div>

                    </div>
                </div>
            );
        })}
    </div>

    const programGuidelines = <div className={EIOverviewCss.description}>
        All Early Access members must meet the following criteria.
        If they do not, we reserve the right to terminate membership at any time.
        <ul className={"fds-p--b-1"}>
            {programGuidlinesList.map(items => getFormattedList(items))}
        </ul>
        {width < 650 ? mobileView : desktopView}
    </div>

    const betaLevelSoftware = <div className={EIOverviewCss.description}>
        Getting updates before anyone else is awesome, but please keep the following in mind.
        <ul>
            {betaLevelSoftwareList.map(items => getFormattedList(items))}
        </ul>
    </div>

    const communicationAndFeedback = <div className={EIOverviewCss.description}>
        Your engagement is crucial to the success of the program.
        <ul>
            {communicationFeedbackList.map(items => getFormattedList(items))}
        </ul>
        <p data-testid={"communicationFeedBack"} className={EIOverviewCss.feedBack}>
            Please see <a className={EIOverviewCss.feedBack} onClick={showEapTerms} target={"_blank"}> terms </a> for
            important information on what to do if you believe your vehicle has a defect which could cause a crash or
            cause injury or death.
        </p>
    </div>

    const whatToExpectFromUs = <div className={EIOverviewCss.description}>
        We put a lot of faith in our Early Access members.
        That’s why we hold ourselves to a very high standard.
        The success of our program depends on you, so we make it a priority to be there every step
        of the way. You can expect timely support when issues are identified, as well as transparent
        communication. We’ll treat you like family, and we hope you’ll treat us like family, too!
    </div>

    const privacyLink = "https://www.ford.com/support/how-tos/fordpass/manage-my-fordpass-account/fordpass-terms-and-conditions/#privacy_policy"

    let privacyPolicy = <div className={FinalizeEnrollmentCss.privacy}>
        <div data-testid={"privacyPolicyText"}>I have read and agree to
            the Ford Early Access Program Terms, as well
            as the FordPass Terms & Privacy Policy and Ford.com Privacy Policy
        </div>
    </div>

    let agreementStatement = <div className={FinalizeEnrollmentCss.privacy}>
        <div>
            I agree to be contacted by email and by FordPass in-app messaging
            to participate in new Early Access features.
        </div>
    </div>

    const contents = [ourExpectationsDesc, programGuidelines, betaLevelSoftware, communicationAndFeedback, whatToExpectFromUs]

    const headerText = () => {
        return activeInactiveFlow ? "Important Updates" : "Your chance to test new Ford features"
    }

    const headerSubText = ()=>{
        return activeInactiveFlow ?<span>Please take a moment to review our community guidelines and policies before continuing your enrollment. We’re looking forward to having <br/> you on board!</span>:
            <span>
                Please take a moment to review our community guidelines and policies before confirming your
                        enrollment. We’re looking forward to having <br/> you on board!
            </span>
    }

    const formatStatusText=(status)=>{
        if(status===NOT_INVITED) {
            status = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
            status = status.replace(/_[a-zA-Z]/g, " I")
            return status
        }
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
    }
    return (
        <div>
            <div>
                <div className={FinalizeEnrollmentCss.carContainer}>
                    <div data-testid={"circleCarImage"}
                         className={FinalizeEnrollmentCss.circleIcon}>
                        <img alt="car" src={step2Image}/>
                    </div>
                </div>
                <div data-testid="FinalizeEnrollmentDescriptionHeader" className={FinalizeEnrollmentCss.yourChance}>
                    {headerText()}
                    <p className={FinalizeEnrollmentCss.chanceDescription}>
                        {headerSubText()}
                    </p>
                </div>

            </div>

            <div>
                {
                    titles.map((items,i)=>{
                        return (
                            <Accordion accordionTitle={items} accordionContent={contents[i]}/>
                        )
                    })
                }

            </div>

            <div className={FinalizeEnrollmentCss.separatorContainer}>
                <div className={FinalizeEnrollmentCss.circleIcon}><img
                    style={{paddingTop: "14px", width: "29px"}}
                    alt={"privacy icon"}
                    src={privacyIcon}/></div>

                <div className={FinalizeEnrollmentCss.privacyHeader}>Other Important Information</div>
                <p className={FinalizeEnrollmentCss.termsDesc}>
                    Before you can proceed you must read and agree to the
                    <span> <a className={FinalizeEnrollmentCss.privacyLinks} data-testid={"eapTermsLink"}
                    onClick={showEapTerms} target={"_blank"}> Ford Early Access Program Terms </a> </span> as well as the
                    <span>  <a className={FinalizeEnrollmentCss.privacyLinks}
                               data-testid={"fordPassTermsLink"}
                               onClick={showFordPassTerms}
                               target={"_blank"}> FordPass Terms & Privacy Policy   </a>  </span><br/>
                    and <span> <a className={FinalizeEnrollmentCss.privacyLinks} href={privacyLink} target={"_blank"}>  Ford.com Privacy Policy</a> </span>
                    Please read these terms carefully — they are agreements between you and us, and contain important<br/>
                    provisions regarding your use of FordPass, this site, and our privacy practices.
                </p>

                {userVehicles.map((items) => {
                    let consentDetails = props.vehicleConsents[items.vin] || {
                        "privacyPolicy": false,
                        "agreementStatement": false
                    }
                    return (
                        <div key={`${items}`}>
                            <div>
                               {/* <ReactCheckbox id="checkbox1"
                                               initialValue="item-1"
                                               data-testid={`${items.vin}`+"_checkBox1"}
                                               checked={props.selectedVehicles.includes(items.vin)}
                                               caption={
                                                   <span
                                                       className={FinalizeEnrollmentCss.VinCheckBoxStyleContainer}>{items.modelYear} {items.modelName}
                                                       <VinCheckBox
                                                           style={items.status}
                                                           content={formatStatusText(items.status)}/></span>}
                                               inputStyle={{marginBottom: "0"}}
                                               onClick={() => {
                                                   props.onCheckboxClick(items.vin);
                                               }}
                                />*/}


                                <fieldset className="fmc-check">
                                    <label>
                                        <input type="checkbox" name="checkbox-item-1" value="item-1"
                                               id="checkbox1"
                                               checked={props.selectedVehicles.includes(items.vin)}
                                               data-testid={`${items.vin}`+"_checkBox1"}
                                               onClick={() => {
                                                   props.onCheckboxClick(items.vin);
                                               }}
                                        />
                                        <span
                                            className={FinalizeEnrollmentCss.VinCheckBoxStyleContainer}>{items.modelYear} {items.modelName}
                                            <VinCheckBox
                                                style={items.status}
                                                content={formatStatusText(items.status)}/>
                                        </span>
                                    </label>
                                </fieldset>
                            </div>
                            <div className={FinalizeEnrollmentCss.PrivacyContainer}>
                        {/*        <ReactCheckbox id="checkbox1"
                                               initialValue="item-1"
                                               data-testid={`${items.vin}`+"_checkBox1Privacy"}
                                               checked={consentDetails["privacyPolicy"]}
                                               caption={privacyPolicy}
                                               inputStyle={{marginBottom: "0"}}
                                               onClick={() => {
                                                   props.onCheckboxClick(items.vin + " privacyPolicy");
                                               }}
                                >
                                </ReactCheckbox>*/}

                                <fieldset className="fmc-check">
                                    <label>
                                        <input type="checkbox" name="checkbox-item-1" value="item-1"
                                               checked={consentDetails["privacyPolicy"]}
                                               data-testid={`${items.vin}`+"_checkBox1Privacy"}
                                               onClick={() => {
                                                   props.onCheckboxClick(items.vin + " privacyPolicy");
                                               }}
                                        />
                                        <span>
                                            {privacyPolicy}</span>
                                    </label>
                                </fieldset>


                            </div>


                            {items.status !== ENROLLED &&
                            <div className={FinalizeEnrollmentCss.FeatureConsentContainer}>
                                < p data-testid={"weWillContactYouText"} className={FinalizeEnrollmentCss.contactDesc}>
                                    We will contact you when there are new features available for you to experience.
                                </p>
                                <div>
                                  {/*  <ReactCheckbox id="checkbox2"
                                                   initialValue="item-2"
                                                   data-testid={`${items.vin}`+"_checkBox2AgreementStatement"}
                                                   caption={agreementStatement}
                                                   checked={consentDetails["agreementStatement"]}
                                                   onClick={() => {
                                                       props.onCheckboxClick(items.vin + " agreementStatement");
                                                   }}
                                    >
                                    </ReactCheckbox>*/}


                                    <fieldset className="fmc-check">
                                        <label>
                                            <input type="checkbox" name="checkbox-item-1" value="item-1"
                                                   checked={consentDetails["agreementStatement"]}
                                                   data-testid={`${items.vin}`+"_checkBox2AgreementStatement"}
                                                   onClick={() => {
                                                       props.onCheckboxClick(items.vin + " agreementStatement");
                                                   }}
                                            />
                                            <span>
                                            {agreementStatement}</span>
                                        </label>
                                    </fieldset>
                                </div>

                            </div>}

                        </div>
                    )
                })
                }

            </div>
        </div>
    )
}

export default FinalizeEnrollmentDescription