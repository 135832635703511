import React, {useEffect, useState} from "react";
import AccountModuleCss from "./Account.module.css";
import HomeModulePart2CSS from "../home/homeModulePart2/HomeText.module.css";
import MyProductSModuleCss from "../products/MyProducts.module.css";
import {userDetailsService} from "../../services/userDetailsService";
import ViewProfile from "./ViewProfile";
import EditProfile from "./EditProfile";
import NetworkLoader from "../../util/NetworkLoader";
import {useHistory} from "react-router-dom";
import Loading from "../../components/common/Loading/Loading";
import ModalUnScrollable from "../../components/common/Model/ModalUnScrollable";
import ModelCloseButton from "../../components/common/Model/ModelCloseButton";
import Warning from "../../assets/Close.svg";
import ExpressInterestSignUpCss from "../expressInterest/ExpressInterestSignUp/ExpressInterestSignUp.module.css";
import ModelContent from "../../components/common/Model/ModelContent";
import ModelCTAButton from "../../components/common/Model/ModelCTAButton";

const Profile = (props) =>{

    const history= useHistory()
    const [loader, setLoader] = useState(false)
    const [userData, setUserData] = useState({})
    const [showForm, setShowForm]=useState(false)
    const [sync , setSync] = useState(false)
    const [submitLoader , setSubmitLoader] = useState(false)
    const [showErrorPopUp , setShowErrorPopUp] = useState(false)
    const [showFormAfterUpdate , setShowFormAfterUpdate] = useState(false)


    let [profileDetail, setProfileDetail] = useState({
        "question5": "",
        "question6": "",
        "question7": "",
        "question8a": "",
        "question8b": "",
        "question8c": "",
    })

    useEffect(() => {
        let  isMounted = false;
        setLoader(true)

        fetchData().then()

        return(()=>{
            isMounted = true
        })
    }, [showFormAfterUpdate]);

    const fetchData = async () => {
        let data = await userDetailsService.userAccountInfo();
        setUserData(data);
        setLoader(false)
    }

    const handleEditProfile =(e)=>{
        e.preventDefault()
        setProfileDetail(prevState => ({
            ...prevState,
            "question5": userData.age,
            "question6": userData.gender,
            "question7": userData.phoneNumber,
            "question8a": userData.city,
            "question8b": userData.state,
            "question8c": userData.zipcode,
        }));
        setShowForm(true)
    }

    const handleChange = (e) => {
        let {name, value} = e.target;
        if(name === "question6" ) {
            if(value !== "Other(please specify)") delete setProfileDetail["otherGender"]
            else setProfileDetail["otherGender"] = ""
        }

        if(name === "question7" || name === "question8c") value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')

        profileDetail[name] = value
        setProfileDetail(profileDetail)
        setSync(!sync)
    }

    const handleCancel=()=>{
        setShowForm(false)
    }

    const handleSubmit =async ()=>{

     let contract =  {
            "questionnaireAnswerDtos":[
            {
                "answer": profileDetail.question5,
                "questionCode": "question5"
            },
            {
                "answer": profileDetail.question6,
                "questionCode": "question6"
            },
            {
                "answer": profileDetail.question7,
                "questionCode": "question7"
            },
            {
                "answer": `${profileDetail.question8a}, ${profileDetail.question8b}, ${profileDetail.question8c}`,
                "questionCode": "question8"
            }
            ]
        }
        setSubmitLoader(true)
        try {
            const res = await userDetailsService.updateAccount(contract);
            if (res === 200) {
                await fetchData(); 
                setSubmitLoader(false);
                setShowFormAfterUpdate(true);
                setShowForm(false);
            } else {
                setSubmitLoader(false);
                setShowErrorPopUp(true);
            }
        } catch (e) {
            setSubmitLoader(false);
            setShowErrorPopUp(true);
        }

    }
    const navigateToAccount =()=>{
        window.location.reload()
    }
    return (
        <div>
            {submitLoader ? <Loading/> :<div>
                <div className={AccountModuleCss.headerContainerProduct}>
                    <div className={AccountModuleCss.menuContainer}>
                        <div className={"fds-display--inline"}>
                            <span onClick={props.backToAccount}
                                  className={AccountModuleCss.breadcrumbHover}>Account </span> > My Profile
                        </div>
                    </div>
                </div>
                <div>
                    <div className={HomeModulePart2CSS.carouselHeader}>
                        <div className={MyProductSModuleCss.myProductsTitle}
                             data-testid="mainHeaderTxt">

                            {showForm ? "Edit Profile" : "My Profile"}
                        </div>
                    </div>
                </div>
                {showForm ?
                    <EditProfile formValue={profileDetail} onChange={handleChange} handleCancel={handleCancel} handleSubmit={handleSubmit}/> :
                    <ViewProfile userData={userData} loader={loader} handleEdit={handleEditProfile}/>}
            </div>}

            <ModalUnScrollable
                id="expressSignUp"
                data-cy = "expressSignUpPopUp"
                show={showErrorPopUp}
                close={<ModelCloseButton onClick={navigateToAccount}/>}
                modelIcon={ <img src={Warning} alt={"warning"}/> }
                modelHeader="Error"
                modalCenter={ExpressInterestSignUpCss.modalCenter}
                modalScroll={ExpressInterestSignUpCss.modalScroll}
                modelSubHeader={null}
                modelContent={<ModelContent text={"Error Submitting Your User Information!" }/>}
                modelFooter={<ModelCTAButton onClick={navigateToAccount} text={"Go to Account"}/>}
            />

        </div>

    )
}
export default Profile;
