import {requestUserData, successUserData,} from "../redux/Actions/UserDataActions";
import store from "../redux/store/userStore";
import {userDetailsService} from "./userDetailsService";
import fmaFunctions from "./fmaFunctions";

const userAuthentication = () => {
    setTimeout(() => {
        if (store.getState().loading === true) {
            fmaFunctions().login()
            store.dispatch(successUserData(null));
        }
    }, 12000);

    const onAuthenticated = async () => {
        await setUserProfile();
        if (localStorage.getItem("loginTimestamp")===null){
            await userDetailsService.lastLogin();
            const timestamp=new Date().toISOString()
            localStorage.setItem("loginTimestamp",timestamp)
        }
    };

    const onUnauthenticated = async () => {
        store.dispatch(successUserData(null));
    };

    document.body.addEventListener("fma_authenticated", onAuthenticated);
    document.body.addEventListener("fma_unauthenticated", onUnauthenticated);
};

const setUserProfile = async () => {
    store.dispatch(requestUserData());

    const [
        user,
        userVehicleInfo,
        userProductsInfo,
        userInvites,
        genericSurvey,
        questionnaireCompleted
    ] = await userDetailsService.getUserProfile();

    store.dispatch(
        successUserData({
            isAuthenticated: true,
            userProfile: user,
            userVehicles: userVehicleInfo,
            userInvites: userInvites,
            userInvitedProducts: userProductsInfo,
            genericSurvey: genericSurvey,
            questionnaireCompleted:questionnaireCompleted
        }))
};


export {userAuthentication as default, setUserProfile};
