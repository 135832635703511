import React, {useEffect, useState} from "react";
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import MyProductsModule from "../products/MyProductsModule";
import AccountModule from "./AccountModule";
import NoticeTemplate from "./accountSubModules/NoticeTemplate";
import {userDetailsService} from "../../services/userDetailsService";
import store from "../../redux/store/userStore";
import {successProducts, successVehicles} from "../../redux/Actions/UserDataActions";
import {noSpaceLowerCase} from "../../util/Format";
import {
    LEAVEPROGRAM,
    MANAGEVEHICLES,
    MYPRODUCTS,
    PROFILE,
    SUPPORT,
} from "../../Constants/Navigation";
import ContactUs from "../contactUs/ContactUs";
import {ENROLL, WAITLIST} from "../../Constants/CTA";
import Profile from "./Profile";

const AccountNavigation = () => {

    const [menuChosen, setMenuChosen] = useState("account");
    const [vehicleChosen, setVehicleChosen] = useState(null)

    useEffect( () => {
        getAccountData().catch(console.error)
    },[])

    const getAccountData = async () => {
        let [accountEnrolledVehicles,
            accountAvailableProducts,
            accountEnrolledProducts] = await userDetailsService.getAccountInfo().catch((error) => {});
        store.dispatch(
            successProducts({
                enrolledProducts: accountEnrolledProducts,
                availableProducts: accountAvailableProducts,
            })
        );
        store.dispatch(successVehicles({enrolledVehicles: accountEnrolledVehicles}))
    }

    const backToAccount = () => setMenuChosen("account")

    const chosenMenuItem = () => {
        switch (noSpaceLowerCase(menuChosen)) {
            case noSpaceLowerCase(MYPRODUCTS):
                return <MyProductsModule backToAccount={backToAccount}/>

            case noSpaceLowerCase(SUPPORT):
                return <ContactUs backToAccount={backToAccount}/>

            case noSpaceLowerCase(PROFILE):
               return <Profile backToAccount={backToAccount}/>

            case noSpaceLowerCase(MANAGEVEHICLES):
                return <NoticeTemplate backToAccount={backToAccount} title="Manage Vehicle"/>

            case noSpaceLowerCase(LEAVEPROGRAM):
                return <NoticeTemplate backToAccount={backToAccount} title="We’re Sorry to See You Go"/>

            case noSpaceLowerCase(WAITLIST):
                return <NoticeTemplate backToAccount={backToAccount} title="Waitlist in the Ford Early Access Program" vin={vehicleChosen.vin}/>

            case noSpaceLowerCase(ENROLL):
                return <NoticeTemplate backToAccount={backToAccount} title="Enroll in the Ford Early Access Program" vin={vehicleChosen.vin}/>

            default:
                return  <AccountModule handleMenuClick={(item, vehicle) => {setMenuChosen(item) ; setVehicleChosen(vehicle)}}/>
        }
    }

    return chosenMenuItem()


}

export default AccountNavigation;