import React, {useState} from "react";
import EIOverviewCss from "./ExpressInterestOverview.module.css"
import step1Image from "../../../assets/User.svg";
import step2Image from "../../../assets/Car.svg";
import step3Image from "../../../assets/home.svg";
import step4Image from "../../../assets/Email.svg";
import {
    betaLevelSoftwareList,
    communicationFeedbackList,
    programGuidlinesList
} from "../ExpressInterestConstants/ExpressInterestConstants";
import {getFormattedList} from "../../../util/Format";
import ModalUnScrollable from "../../../components/common/Model/ModalUnScrollable";
import ReactSecondaryButton from "../../../assets/js/ReactSecondaryButton";
import ExpressInterestSignUpCss from "../ExpressInterestSignUp/ExpressInterestSignUp.module.css";
import fmaFunctions from "../../../services/fmaFunctions";

export default function ExpressInterestOverview(props) {

    const [showMaybeLater, setShowMaybeLater] = useState(false)
    const stepsToEnroll = [
        {

            logo: step1Image,
            desc: "You must be 18 years or older",
            alt: "person",
        },
        {
            logo: step2Image,
            desc: "You must own or lease a Ford vehicle",
            alt: "vehicle",
        },
        {
            logo: step3Image,
            desc: "You must be located in the U.S.",
            alt: "location",
        },
        {
            logo: step4Image,
            desc: "You must have an active email address",
            alt: "email",
        },
    ];
    let {width} = props

    const handleTakeMeBack = ()=>{
        setShowMaybeLater(false)
    }

    const handleSignOut = ()=>{
        fmaFunctions().logout()
    }

    const showEapTerms = ()=>{
        window.open('/eapterms','_blank')
    }

    const desktopView = <div className="fds-layout-grid" style={{padding: 0}}>
        <div className="fds-layout-grid__inner fds-p--t-3">
            {stepsToEnroll.map((step, idx) => {
                const commonDivStyle = "fds-align--center fds-layout-grid__cell--span-3"
                return (
                    <div className={commonDivStyle} style={{display: "flex", justifyContent: "space-evenly"}} key={idx}>
                        <div>
                            <img
                                src={step.logo}
                                alt={step.alt}
                            />
                            <p className={ EIOverviewCss.icomDescription } >
                                {step.desc}
                            </p>
                        </div>
                        {idx === 3? null : <div className={EIOverviewCss.vl } />}
                    </div>

                );
            })}
        </div>
    </div>

    const mobileView = <div style={{padding: "1em"}}>
            {stepsToEnroll.map((step, idx) => {
                const commonDivStyle = " fds-align--center fds-p-t-3"
                return (
                    <div
                        key={`${idx}_${step}`}
                        className={commonDivStyle}
                    >
                        <div style={ {margin: "2em "} }>
                            <img
                                src={step.logo}
                                alt={step.alt}
                            />
                            <p className="fds-type--body1 fds-align--center fds-p--t-2 fds-p--b-1" style={{color: "#4D4D4D"}}>
                                {step.desc}
                            </p>
                            <div>
                                {idx < 3 ? <hr style={ {border:"0.1px solid grey", width: "40%"} }/> : null}
                            </div>

                        </div>
                    </div>
                );
            })}
    </div>

    return (
        <div>
        <div style={width <= 650 ? { padding: "0 40px 0 40px"}: {}} data-testid="outerDiv">
            <div className={EIOverviewCss.title}>
                Your chance to help drive the future of Ford
            </div>
            <div className={EIOverviewCss.description}>
                Are you a passionate Ford customer? Would you like to help build great Ford products and services for everyone?
                If so, you might be a perfect candidate for the Ford Early Access Program.
                Early Access members are part of an engaged community of Ford owners who get access to beta-level software for vehicles,
                the FordPass app, and other experiences. After they have a chance to assess the product, members provide
                essential feedback that will influence Ford products and experiences now and into the future.
            </div>
            <div className={EIOverviewCss.title}>
                Our expectations
            </div>
            <div className={EIOverviewCss.description}>
                Joining the Ford Early Access Program is a commitment. As a member, you’ll be part of an early-adopter
                community that requires active participation and the responsibility to abide by the rules. If you’re
                ready to express interest, select the link below or continue reading to learn more.
            </div>
            <div className={EIOverviewCss.title}>
                Program Guidelines
            </div>
            <div className={EIOverviewCss.description}>
                All Early Access members must meet the following criteria.
                If they do not, we reserve the right to terminate membership at any time.
                <ul className={"fds-p--b-1"}>
                    {programGuidlinesList.map( items => getFormattedList(items))}
                </ul>
                In addition, please keep the following community guidelines in mind before you express interest:
                {width < 650 ? mobileView : desktopView}
        </div>

        <div className={EIOverviewCss.title}>
            Beta Level Software
        </div>
        <div className={EIOverviewCss.description}>
            Getting updates before anyone else is awesome, but please keep the following in mind.
            <ul>
                {betaLevelSoftwareList.map( items => getFormattedList(items))}
            </ul>
        </div>
        <div className={EIOverviewCss.title}>
            Communications & Feedback
        </div>
        <div className={EIOverviewCss.description}>
            Your engagement is crucial to the success of the program.
            <ul>
                {communicationFeedbackList.map(items => getFormattedList(items))}
            </ul>
            <p className={EIOverviewCss.feedBack}>
                Please see <a className={EIOverviewCss.feedBack} data-testid={"eapTermsLink"} onClick={showEapTerms} target={"_blank"} > terms </a> for important information on what to do if you believe your vehicle has a defect which could cause a crash or cause injury or death.
            </p>
        </div>

        <div className={EIOverviewCss.title}>
            What you can expect from us
        </div>
        <div className={EIOverviewCss.description}>
            We put a lot of faith in our Early Access members.
            That’s why we hold ourselves to a very high standard.
            The success of our program depends on you, so we make it a priority to be there every step
            of the way. You can expect timely support when issues are identified, as well as transparent
            communication. We’ll treat you like family, and we hope you’ll treat us like family, too!
        </div>
        <div className={EIOverviewCss.disclaimer} >
            Please understand that expressing interest in our program does not guarantee your selection for the Ford Early<br/>
            Access Program, as well as does not ensure exposure to every pre-release feature.
        </div>
        <div className="fds-align--center fds-p--t-3">
            <button className="fmc-button" onClick={() => props.onContinue("Overview")} data-testid={"goToQuestionnaire"}>
                Continue <span className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right" />
            </button>
        </div>
        <div className="fds-align--center fds-p--b-3">
            <button onClick={()=>setShowMaybeLater(true)} data-testid={"maybeLaterBtn"} className="fmc-text-button fds-m--t-2">
                Maybe Later
            </button>
        </div>


        </div>

            <ModalUnScrollable
                id="expressSignUp"
                data-cy = "expressSignUpPopUp"
                show={showMaybeLater}
                close={
                    <ReactSecondaryButton
                        style={{ float: "right", padding: "10px" }}
                        colorScheme="light"
                        onClick={handleTakeMeBack}
                        data-testid="close"
                    >
                        <span className="fds-secondary-button__icon-wrapper fds-secondary-button__icon-left">
                            <span className="fds-icon fds-icon fds-font--ford-icons__clear"
                                  aria-label="close"
                                  tabIndex={1}/>
                        </span>
                    </ReactSecondaryButton>}
                modelIcon={null}
                modelHeader="No worries"
                modalCenter={ExpressInterestSignUpCss.modalCenter}
                modalScroll={ExpressInterestSignUpCss.modalScroll}
                modelSubHeader={<div className={EIOverviewCss.subHeaderText}>Ford Early Access Program</div>}
                modelContent={
                    <div className={ExpressInterestSignUpCss.modalScroll}>
                        <div className="fds-p--t-1" >
                            <p className={EIOverviewCss.modalContentText} data-testid={"contentText"} >Come back anytime to express interest.

                            </p>
                            <br/>
                        </div>
                    </div>
                }
                modelFooter={<div style={{ display: "inline" }}>
                    <div style={{ padding: "0 20px", display: "inline-block" }}>
                        <button className="fmc-button" onClick={handleSignOut}
                                data-testid ="gotoMyAccount">
                                Sign Out
                            <span className="fds-icon fds-icon"
                                  data-testid= {"signOutBtn"}
                            >
                        </span>
                        </button>
                    </div>
                    <div className="fds-align--center fds-p--b-3">
                        <button onClick={handleTakeMeBack}  data-testid={"takeMeBack"} className="fmc-text-button fds-m--t-2">
                            Take me back
                        </button>
                    </div>
                </div>}
            />
    </div>

  );
}
