import "./ErrorDisplay.css";
import classNames from "classnames";

const Error = ({error}) => {
    const classList = classNames({
        ErrorHidden: process.env.NODE_ENV === "prod",
    });
    /* error instanceof Error would be ideal, but that errors here. */
    if (typeof error === "object") {
        return (
            <div className={classList} id="#ErrorDetails">
                <p>
                    {error.message} {error.name}
                </p>
                <p>{error.stack}</p>
            </div>
        );
    } else {
        return (
            <div className={classList} id="#ErrorDetails">
                {error}
            </div>
        );
    }
};

export default Error;
