import FinalizeEnrollmentCss from "./FinalizeEnrollment.module.css"

import React, {useEffect, useState} from "react";
import FinalizeEnrollmentDescription from "./finalizeEnrollmentSubModules/FinalizeEnrollmentDescription";
import {connect} from "react-redux";
import {consentService} from "../../services/consentService";
import {userDetailsService} from "../../services/userDetailsService";
import Loading from "../../components/common/Loading/Loading";
import {useHistory} from "react-router-dom";
import {ENROLLED, INVITED, WAITLISTED} from "../../Constants/Status";
import {isActiveInactive} from "../../util/EnrollmentFlow";


function FinalizeEnrollment(props) {

    const history = useHistory()
    let userVehicles = props?.user?.userVehicles || []
    const activeInactiveFlow = isActiveInactive(userVehicles)
    let questionnaireAnswered = props?.user?.questionnaireCompleted

    if (activeInactiveFlow && !Boolean(props.consentStatus)) {
        history.push("/consent")
    }

    if (isActiveInactive(userVehicles) && questionnaireAnswered) {
        history.push("/consent")
    }

    const cs = consentService;
    const uds = userDetailsService;

    let finalizeEnrollment = userVehicles.filter((items => (items.status !== ENROLLED && items.status !== WAITLISTED && items.status === INVITED)))

    const [enrollClicked, setEnrollClicked] = useState(false)
    const [selectedVehicles, setSelectedVehicles] = useState([])
    const [vehicleConsents, setVehicleConsents] = useState({})
    const [sync, setSync] = useState(false)
    const consentDetail = {"privacyPolicy": true, "agreementStatement": true}

    if (finalizeEnrollment.length === 0 && !activeInactiveFlow) history.push("/account")

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    })

    useEffect(() => {
        let vins = []
        let consents = {}
        finalizeEnrollment.forEach(items => {
            vins.push(items.vin)
            consents[items.vin] = consentDetail
        })
        setSelectedVehicles(vins)
        setVehicleConsents(consents)
    }, [])

    const [width, setWidth] = useState(window.innerWidth);

    const onCheckboxClick = (id) => {
        let [clickedVin, category] = id.split(" ")
        let selectedVehiclesCopy = selectedVehicles
        let vehicleConsentsCopy = vehicleConsents
        setSelectedVehicles([])
        setVehicleConsents({})

        if (!Boolean(category)) {
            //outer check box
            if (selectedVehiclesCopy.includes(clickedVin)) {
                selectedVehiclesCopy = selectedVehiclesCopy.filter(items => items !== clickedVin)
                delete vehicleConsentsCopy[clickedVin]
            } else {
                selectedVehiclesCopy.push(clickedVin)
                vehicleConsentsCopy[clickedVin] = consentDetail
            }
        } else {
            let currentConsent = vehicleConsentsCopy[clickedVin]
            currentConsent[category] = !currentConsent[category]
            vehicleConsentsCopy[clickedVin] = currentConsent
        }

        setSelectedVehicles(selectedVehiclesCopy)
        setVehicleConsents(vehicleConsentsCopy)
        setSync(!sync)
    }

    const getFinalizeEnrollmentStatus = () => {
        if (selectedVehicles.length <= 0) return true
        for (const element of selectedVehicles) {
            let currentVin = element
            let currentConsents = vehicleConsents[currentVin]
            if (currentConsents["privacyPolicy"] === false || currentConsents["agreementStatement"] === false) return true
        }
        return false
    }
    const handleConfirmEnrollment = async () => {
        window.scrollTo(0, 0)
        setEnrollClicked(true)
        await Promise.all(selectedVehicles.map(async items => {
            await cs.saveConsents(items)
            await uds.saveUser(items)
        })).then(() => {
            if (questionnaireAnswered) {
               // setConfirmTerms(true)

                history.push("/welcome");
            }else {
                history.push("/consent");
            }
        })
    };

    const handleNext = () => {
        props.onContinue("FinalizeEnrollment")
    }

    const finalizeEnrollmentDescriptionCategory = () => {
        return activeInactiveFlow ? <FinalizeEnrollmentDescription width={width} {...props}/>
            : <FinalizeEnrollmentDescription width={width}
                                             selectedVehicles={selectedVehicles}
                                             vehicleConsents={vehicleConsents}
                                             onCheckboxClick={onCheckboxClick}
                                             userVehicles={finalizeEnrollment}/>
    }

    const handleNullProps = () => {
        return Boolean(props.consentStatus) ? props.consentStatus() : false
    }

    const getButtonStatus = () => {
        return activeInactiveFlow ? handleNullProps() : getFinalizeEnrollmentStatus()
    }

    const getButtonHandler = () => {
        return activeInactiveFlow && !questionnaireAnswered ? handleNext : handleConfirmEnrollment
    }

    const getButtonText = () => {
        if (activeInactiveFlow && !questionnaireAnswered) {
            return "Next"
        } else {
            return "Confirm Enrollment"
        }
    }

    return (
        <div>
            {enrollClicked ? <Loading/> : <>
                <div>
                    <div className={FinalizeEnrollmentCss.topContentContainer}>
                        <div className={FinalizeEnrollmentCss.headerContainer}>
                            <div
                                data-testid={"headerText"}
                                className={FinalizeEnrollmentCss.headerText}>
                                Let's Make it Official
                            </div>
                        </div>

                        <div
                            data-testid={"middleContainer"}
                            className={FinalizeEnrollmentCss.middleContainer}
                        >
                            {finalizeEnrollmentDescriptionCategory()}
                        </div>
                        <div className="fds-align--center fds-p--b-1 fds-m--b-4">

                            <button className="fmc-button" onClick={getButtonHandler()}
                                    disabled={getButtonStatus()}
                                    data-testid={"confirmEnrollmentContainer"}
                            >

                                <span
                                    data-testid={"confirmEnrollment"}
                                >
                                {getButtonText()}
                            </span>

                                <span
                                    className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"/>

                            </button>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};
export default connect(mapStateToProps)(FinalizeEnrollment);
