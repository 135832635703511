import Checkmark from "./../../../assets/Checkmark.svg"
import FinalizeEnrollmentConfirmationCss from "./FinalizeEnrollmentConfirmation.module.css"
import {connect} from "react-redux";
import React from "react";
import ProfilePic from "./../../../assets/Jim Farley.png"
import {useHistory} from "react-router-dom";

const FinalizeEnrollmentConfirmation = (props)=>{

    const userName = props.user?.userProfile?.firstName;
    const history = useHistory()

    const handleAccountNavigation = ()=>{
        history.push("/account")
        history.go(0)
    }

    return(
        <div>
            <div className={FinalizeEnrollmentConfirmationCss.headerContainer}>
            <img alt={"green check mark"} src={Checkmark}/>
                <p className="fds-type--title3 fds-m--t-2">{userName}, you're in!</p>
                <p data-testid={"subHeaderTextConfirmation"}
                   className="fds-type--body1 fds-color__text--dk-gray fds-m--t-1">
                    Welcome to the Early Access Program. We’re so excited to have you join Ford in building together.
                </p>
                <div className="fds-m--t-3">
                    <div className="fmc-cards__card  fds-layout-grid__cell">
                        <div className="fmc-cards__content">
                            <div className="fmc-cards__footer" style={{borderTop: "none", paddingBottom:"3rem"}}>
                                <div className={FinalizeEnrollmentConfirmationCss.cardContainer}>
                                    <div className={FinalizeEnrollmentConfirmationCss.imageContainer}><img alt="Jim Farley Profile" src={ProfilePic}/></div>
                                    <div className={FinalizeEnrollmentConfirmationCss.welcomeMessageContainer}>
                                        <p className={FinalizeEnrollmentConfirmationCss.ceoMessage}>A Message from our CEO
                                        </p>
                                        <p className={FinalizeEnrollmentConfirmationCss.textContainer}>
                                            Thanks for joining the Ford Early Access Program! No<br/>
                                            opinion matters more to us than the opinion of our<br/>
                                            customers. We use your ideas and feedback to make our <br/>
                                            products better. You are truly helping to shape the<br/>
                                            future of Ford.
                                        </p>
                                        <p className={FinalizeEnrollmentConfirmationCss.textContainerWelcome}>Welcome, and enjoy the ride!</p>
                                        <p className={FinalizeEnrollmentConfirmationCss.textContainerCEO}>
                                            Jim Farley
                                        </p>
                                        <p className={FinalizeEnrollmentConfirmationCss.textContainerRole}>
                                            President and CEO<br/>
                                            Ford Motor Company
                                        </p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fds-m--t-4 fds-m--b-3 fds-align--center">
                    <button className="fmc-button" onClick={handleAccountNavigation}
                            data-testid="goToMyAccountBtn">
                        <span
                            data-testid={"confirmEnrollmentContainer"}
                            className="fds-primary-button__button-text fds-primary-button__button-icon--chevron-right ">Go to Account</span>
                    </button>

            </div>
        </div>

    )
}
const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};
export default connect(mapStateToProps)(FinalizeEnrollmentConfirmation);
