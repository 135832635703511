import {
  USER_DATA_REQUEST,
  USER_DATA_ERROR,
  USER_DATA_SUCCESS,
  CLEAR_ERRORS,
  PRODUCTS_SUCCESS,
  VEHICLES_SUCCESS,
  PRODUCTS_ERROR,
  VEHICLES_ERROR,
} from "../ActionTypes/UserDataActionTypes";

const requestUserData = () => {
  return {
    type: USER_DATA_REQUEST,
  };
};

const successUserData = (userData) => {
  return {
    type: USER_DATA_SUCCESS,
    payload: userData,
  };
};

const errorUserData = (error) => {
  return {
    type: USER_DATA_ERROR,
    payload: error,
  };
};

const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

const successProducts = (products) => {
  return {
    type: PRODUCTS_SUCCESS,
    payload: products,
  };
};

const errorProducts = (error) => {
  return {
    type: PRODUCTS_ERROR,
    payload: error,
  };
}

const successVehicles = (vehicles) => {
  return {
    type: VEHICLES_SUCCESS,
    payload: vehicles,
  };
}

const errorVehicles = (error) => {
  return {
    type: VEHICLES_ERROR,
    payload: error,
  };
}

export { requestUserData, successUserData, errorUserData, clearErrors, successProducts, errorProducts, successVehicles, errorVehicles};
