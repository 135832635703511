import React from "react";
import EIQuestionnaireCss from "./ExpressInterestQuestionnaire.module.css";
import InputMask from "react-input-mask";
import {isActiveInactive} from "../../../util/EnrollmentFlow";
import {connect} from "react-redux";


function ExpressInterestQuestionnaire(props) {

    let userVehicles = props?.user?.userVehicles || []

    const isActive = isActiveInactive(userVehicles)

    let {selectedValues, onChange, width} = props;

    const options = {
        "binary": ["Yes", "No"],
        "age": ["18-24", "25-35", "36-44", "45-54", "55-64", "65 or older", "Prefer not to say"],
        "gender": ["Woman", "Man", "Non-binary", "Transgender", "Prefer not to say", "Other(please specify)"],
        "extent": ["Strongly Disagree", "Somewhat Disagree", "Neither agree nor disagree", "Somewhat agree", "Strongly agree"]
    }

    const states = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']

    const agreementLevels = (category) => {
        let answer = selectedValues[category]
        return <div style={{display: "flex", flexDirection: "column", paddingTop: "10px"}}>
            {options["extent"].map((items, i) => {
                return (<label key={`${items}_${i}`}>
                    <input type="radio" name={category}
                           data-testid={`${category}_${items}`}
                           value={items} onChange={onChange} checked={answer === items}/>
                    <span className={EIQuestionnaireCss.optionsStyle}>{items} </span>
                </label>)

            })}
        </div>
    }

    return (
        <div className="fds-p--t-3" style={width <= 650 ? {padding: "0 40px 0 40px"} : {}} data-testid="outerDiv">

            <div className={EIQuestionnaireCss.questionnaireTitle}>
                Questionnaire
            </div>
            <div className={EIQuestionnaireCss.questionnaireDescription}>
                These questions will help us (and you) determine if you’re a good fit for the program.
            </div>
            {!isActive && <div>
                <fieldset className="fmc-check">
                    <legend className={EIQuestionnaireCss.questionStyle}>1. Have you participated in a beta testing
                        program before?
                    </legend>
                    {options["binary"].map((items, i) => {
                        return (<label className="fds-p--l-1" key={`${items}_${i}`}>
                            <input type="radio" name="question1" value={items} onChange={onChange}
                                   data-testId={`question1_${items}`} checked={selectedValues["question1"] === items}/>
                            <span className={EIQuestionnaireCss.optionsStyle}>{items} </span>
                        </label>)

                    })}
                </fieldset>
            </div>}
            {!isActive && <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset>
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        2. Why do you think you would be a good fit for the program?
                    </legend>
                    <textarea id="w3review" rows="4" cols={width > 650 ? "50" : "32"}
                              name="question2"
                              placeholder="Description" value={selectedValues["question2"]}
                              onChange={onChange} maxLength={3000} data-testId={"question2"}/>
                    <div className={EIQuestionnaireCss.charFeedBack}>
                        {`${3000 - selectedValues["question2"].length} characters remaining`}
                    </div>
                </fieldset>
            </div>
            }
            {!isActive && <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset>
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        3. Other than early access to beta level software, what else do you hope to gain by joining our
                        program?
                    </legend>
                    <textarea id="w3review" name="question3" rows="4" cols={width > 650 ? "50" : "32"}
                              placeholder="Description" maxLength={3000}
                              data-testid={"question3"}
                              onChange={onChange} value={selectedValues["question3"]}/>
                    <div className={EIQuestionnaireCss.charFeedBack}>
                        {`${3000 - selectedValues["question3"].length} characters remaining`}
                    </div>
                </fieldset>
            </div>}
            <br/>
            <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset className="fmc-check">
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        {isActive ? "1" : "4"} . Please rate your level of agreement with the following statements:
                    </legend>
                    <legend>
                        Don't worry. Please answer truthfully. We're looking for customers
                        from all levels of tech backgrounds
                    </legend>
                    <legend className={"fds-m--t-1"}>
                        a. I am “tech savvy”
                    </legend>
                    {agreementLevels("question4a")}
                    <br/>
                </fieldset>
            </div>
            <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset className="fmc-check">
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        b. People often ask me for help when troubleshooting technical problems
                    </legend>
                    {agreementLevels("question4b")}

                </fieldset>
            </div>
            <br/>
            <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset className="fmc-check">
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        c. I’m usually the first of my friends and family to try a new type of technology
                    </legend>
                    {agreementLevels("question4c")}

                </fieldset>
            </div>
            <br/>
            <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset className="fmc-check">
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        {isActive ? "2" : "5"} . What is your age?
                    </legend>
                    {options["age"].map((items, i) => {
                        return (<label key={`${items}_${i}`}>
                            <input type="radio" name="question5" value={items} data-testid={`question5_${items}`}
                                   onChange={onChange} checked={selectedValues["question5"] === items}/>
                            <span className={EIQuestionnaireCss.optionsStyle}> {items} </span>
                        </label>)
                    })
                    }
                </fieldset>
            </div>
            <br/>
            <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset className="fmc-check">
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        {isActive ? "3" : "6"} Which of the following best describes your gender identity?
                    </legend>
                    {options["gender"].map((items, i) => {
                        return (<label key={`${items}_${i}`}>
                            <input type="radio"
                                   name="question6"
                                   data-testId={`question6_${items}`}
                                   value={items}
                                   onChange={onChange}
                                   checked={selectedValues["question6"] === items}/>
                            <span className={EIQuestionnaireCss.optionsStyle}> {items} </span>

                            {items === "Other(please specify)" &&
                            selectedValues["question6"] === "Other(please specify)" &&
                            width > 650 &&
                            <input type="text"
                                   onChange={onChange}
                                   style={width > 650 ? {width: "15%", height: "85%"} : {}}
                                   placeholder="other"
                                   name="otherGender"
                                   maxLength={30}
                                   value={selectedValues["otherGender"]}/>
                            }
                        </label>)
                    })}
                </fieldset>
                {
                    selectedValues["question6"] === "Other(please specify)" &&
                    width < 650 &&
                    <input type="text"
                           onChange={onChange}
                           style={width > 650 ? {width: "15%", height: "85%"} : {}}
                           name="otherGender"
                           maxLength={30}
                           value={selectedValues["otherGender"]}/>
                }
            </div>
            <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset>
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        {isActive ? "4" : "7"} What is your phone number?
                        <br/>
                        <div className={EIQuestionnaireCss.phoneNote}>
                            * NOTE: The phone number you provide will only be used in the event we need to reach you to
                            provide support and resolve issues quicker. Message and data rates may apply.
                        </div>
                    </legend>
                    <label>
                        <InputMask
                            className="fmc-input"
                            type="tel"
                            mask="(999) 999-9999"
                            placeholder="Phone number"
                            name="question7"
                            data-testid={"question7"}
                            onChange={onChange}
                            value={selectedValues["question7"]}
                        />
                    </label>
                </fieldset>
            </div>
            <div className={EIQuestionnaireCss.questionDiv}>
                <fieldset>
                    <legend className={EIQuestionnaireCss.questionStyle}>
                        {isActive ? "5" : "8"} Where are you located?
                    </legend>
                    <label>
                        <div style={width > 650 ? {
                            display: "flex",
                            justifyContent: "spaceBetween",
                            width: "600px"
                        } : {}}>
                            <input className="fmc-input fds-m--r-1" type="text" onChange={onChange}
                                   placeholder="City" name="question8a" value={selectedValues["question8a"]}
                                   data-testid={"question8a"}/>
                            <select name="question8b" value={selectedValues["question8b"]} data-testid={"question8b"}
                                    className={width > 650 ? "fmc-select fds-m--l-1 fds-m--r-1" : "fmc-select fds-m--t-3 fds-m--b-3"}
                                    onChange={onChange}>
                                {states.map((items, i) => {
                                <option value="" style={{color: "#4D4D4D"}}> State</option>
                                    return <option key={`${items}_${i}`} data-testid={`${items}_options`} value={items}>{items}</option>
                                })}
                            </select>

                            <input className={width > 650 ? "fmc-input fds-m--l-1" : "fmc-input fds-m--t-3"}
                                   type="text"
                                   onChange={onChange}
                                   maxLength={5}
                                   minLength={5}
                                   placeholder="Zip code"
                                   name="question8c"
                                   data-testid={"question8c"}
                                   value={selectedValues["question8c"]}/>
                        </div>
                    </label>

                </fieldset>
            </div>
            <div className="fds-align--center fds-p--t-3">
                <button className="fmc-button"
                        onClick={() => props.onContinue("Questionnaire")}
                        data-testid={"goToSignUp"}
                        disabled={
                            Object.keys(selectedValues)
                                .filter(items => selectedValues[items] === "").length > 0 || (Object.keys(selectedValues["question7"]).length < 10) || selectedValues["question8c"].length < 5}>
                    {isActive ? "Submit" : "Next"} <span
                    className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"/>
                </button>
            </div>
            <div className="fds-align--center fds-p--b-3">
                <button className="fmc-text-button fds-m--t-2"
                        data-testid="goBackQuesBtn"
                        onClick={() => props.onPrevious("Questionnaire")}>
                    Go back
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};
export default connect(mapStateToProps)(ExpressInterestQuestionnaire);
