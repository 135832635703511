import fetchOptions from "./fetchOptions";
import store from "../redux/store/userStore";
import {errorUserData} from "../redux/Actions/UserDataActions";

export const consentService = {

    saveConsents: async (vin) => {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/consent/${vin}`,
            fetchOptions("POST", null)
        );

        try{
            const data = await response.json();

            if (response.ok) {
                return data
            } else {
                store.dispatch(errorUserData(data));
            }
        }catch (e){
            console.log(e)
        }
    }
};
