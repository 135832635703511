import React, { useEffect, useState } from "react";
import ErrorDisplay from "components/common/ErrorDisplay/ErrorDisplay";
import TermsAndConditionCss from "./TermsAndConditions.module.css";

export default function TermsAndConditions() {
  const [error, setError] = useState(null);
  const [fpTerms, setFpTerms] = useState();
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()

  useEffect(() => {
    (async () => {
      let url = ["/fpterms"].includes(window.location.pathname)?`${process.env.REACT_APP_BACKEND_URL}/consent/getLegalLanguageFordPassText`:`${process.env.REACT_APP_BACKEND_URL}/consent/getLegalLanguageEAPText`
      try {
        let res = await fetch(url);
        let result = await res.json();
        setFpTerms(result["legalLanguage"][0]["legalLanguageText"]);
      } catch (e) {
        console.error(e);
        setError(e);
      }
    })();
  }, []);
  if (error) {
    return <ErrorDisplay errors={[error]} />;
  }
  return (
      <div
          className={TermsAndConditionCss.paddingSides}
          dangerouslySetInnerHTML={{ __html: fpTerms }}
      />
  );
}
