import React from "react";
import IneligibleVehicleStyle from "./InvalidGarageRefresh.css"
import ReactPrimaryButton from "../../../assets/js/ReactPrimaryButton";
import fmaFunctions from "../../../services/fmaFunctions";
import {AmplitudeEvent, useAmplitude} from "useAmplitude/src/useAmplitude";

export default function InvalidGarageRefresh() {
  const { logger } = useAmplitude();
  const returnHome = () => {
    logger("return to home tapped", {
      screenName: "ineligible user",
      previousUrl: document.referrer,
    });
    fmaFunctions().logout();
  };

  return (
    <div className="fds-app">
      <AmplitudeEvent
        event="ineligible user screen viewed"
        properties={{
          screenName: "ineligible user",
          previousUrl: document.referrer,
        }}
      />

        <div >
            <div className="warning" data-testid="warn">
            <span className="fds-icon fds-icon--32 fds-icon--offset-right-sm fds-font--ford-icons__error "/>
            </div>
            <br/>
            <p className="were-sorry" data-testid="sorry">Sorry</p>
                <br/>
            <p className="thank-you-for-your-i" data-testid="thank-you-content" >Looks like your Ford Account doesn’t have any vehicles in it that are eligible for Ford Early Access. <br/> If you believe this is an error, please email us at <a href="mailto:pearlyac@ford.com">pearlyac@ford.com</a> for assistance.</p>
            <br/>  <br/>
            <div className="cards-horizontal-building-bloc">
            <ReactPrimaryButton  dataTestId="returnHome"  colorScheme="light"  onClick={returnHome}>
                <span className="fds-primary-button__button-text center">Go Back</span>
              </ReactPrimaryButton>
            </div>
        </div>

    </div>
  );
}
