const fetchOptions = (method, keys, header) => {
    return {
        ...{
            mode: "cors",
            method: method,
            headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                "auth-token": window?.fma?.["CATBundle"]?.["access_token"] ?? null,
                "application-id": process.env.REACT_APP_APPLICATION_ID,
                ...header
            },
        },
        ...keys,
    };
};

export default fetchOptions;
