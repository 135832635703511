import React, {useState} from "react";
import fmaFunctions from "../../../services/fmaFunctions";
import ReactSecondaryButton from "../../../assets/js/ReactSecondaryButton";
import Modal from "../../../components/common/Model/Model";

export default function EmptyEmail() {

    const [emailPopUpShow, setEmailPopUpShow] = useState(true);


    const closeEmailPopUp = () => {
        window.open(
            'https://www.ford.com/myaccount/account-settings',
            '_blank'
        );
        fmaFunctions().logout();
    }


    return (<div>
        <Modal
            id="emailInfo"
            show={emailPopUpShow}
            close={
                <ReactSecondaryButton
                    style={{float: "right", padding: "10px"}}
                    colorScheme="light"
                    onClick={() => {
                        fmaFunctions().logout()
                    }}
                >
                            <span className="fds-secondary-button__icon-wrapper fds-secondary-button__icon-left">
                              <span className="fds-icon fds-icon fds-font--ford-icons__clear" aria-label="close"
                                      tabIndex={1}/>
                            </span>
                </ReactSecondaryButton>
            }
            modelIcon={null}
            modelHeader="Oops! We’re missing some important info!"
            modelSubHeader={
                <p className="fds-p--t-1">
                    Thank you for your interest in the Ford Early Access Program!
                    We’d love to have you on board! We rely on email to communicate
                    with you about the Ford Early Access Program, including to provide
                    you with the feature surveys that are an essential part of the program.
                    We currently don’t have an email on file for you. Please visit
                    https://www.ford.com/myaccount/account-settings/ and
                    add your email address to your Ford account, then return here so you can continue in the program.
                </p>
            }
            modelContent={null

            }
            modelFooter={<button className="fmc-button" onClick={() => {
                setEmailPopUpShow(false)
                closeEmailPopUp()
            }}>
                <span className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right">Go to My Ford Account</span>
            </button>}
        />
    </div>)
}