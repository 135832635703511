import React from "react";
import IPBillboard from "./IntelligentPowerSubModule/IPBillboard";
import IntelligentPowerDescription from "./IntelligentPowerSubModule/IntelligentPowerDescription";
import IPCss from "./IntelligentPower.module.css"


const IntelligentPower = (props) => {

    const surveyURL = "http://ips-invite.iperceptions.com/webValidator.aspx?sdfc=355b766d-131475-c733c77b-141a-42d7-be9f-2e52b426622b&lID=1&source=102226"

    const handleSurvey = ()=>{
        window.open(surveyURL,"_blank")
    }

    return (
        <div>
            <div className={IPCss.ICMainContainer}>
                <IPBillboard surveyURL = {surveyURL} handleSurvey={handleSurvey}/>
                <IntelligentPowerDescription surveyURL = {surveyURL} handleSurvey={handleSurvey}/>
            </div>
        </div>
    )
}

export default (IntelligentPower)