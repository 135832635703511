import {connect} from "react-redux";
import FinalizeEnrollment from "../enrollment/FinalizeEnrollment";
import ExpressInterestQuestionnaire from "../expressInterest/ExpressInterestQuestionnaire/ExpressInterestQuestionnaire";
import React, {useEffect, useState} from "react";
import {ENROLLED} from "../../Constants/Status";
import {
    filterConsentedVehicles,
    getNotConsentedVins,
    isActiveInactive,
    processQuestionnaire
} from "../../util/EnrollmentFlow";
import {consentService as cs} from "../../services/consentService";
import {userDetailsService} from "../../services/userDetailsService";
import ExpressInterestSignUpCss from "../expressInterest/ExpressInterestSignUp/ExpressInterestSignUp.module.css";
import {useHistory} from "react-router-dom";
import Checkmark from "./../../assets/Checkmark.svg"
import NetworkLoader from "../../util/NetworkLoader";
import ModelCloseButton from "../../components/common/Model/ModelCloseButton";
import ModelCTAButton from "../../components/common/Model/ModelCTAButton";
import ModelContent from "../../components/common/Model/ModelContent";
import ModalUnScrollable from "../../components/common/Model/ModalUnScrollable";

const ActiveInactiveEnrollment = (props)=>{

    let userVehicles = props?.user?.userVehicles || []
    const notConsentedVehicles = filterConsentedVehicles(userVehicles)
    const vinsEnrolledNotConsented = getNotConsentedVins(userVehicles)
    const [showQuestionaire,setShowQuestionaire] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    let [selectedValues, setSelectedValues] = useState({
        "question4a": "",
        "question4b": "",
        "question4c": "",
        "question5": "",
        "question6": "",
        "question7": "",
        "question8a": "",
        "question8b": "",
        "question8c": "",
        "question9": "Yes, I am in"
    })
    const [selectedVehicles, setSelectedVehicles] = useState([])
    const [vehicleConsents, setVehicleConsents] = useState({})
    const [sync, setSync] = useState(false)
    const [confirmSignUp, setConfirmSignUp] = useState(false)
    const [submitClicked, setSubmitClicked] =  useState(false)
    const history =  useHistory();

    if(!isActiveInactive(userVehicles))  history.push("/account")

    useEffect( () => {

        let vins = []
        let vinConsents = {}
        notConsentedVehicles.forEach( items => {
            let consentDetail = items.status === ENROLLED ?
                {"privacyPolicy": true}
                : {"privacyPolicy": true, "agreementStatement": true}
            vins.push(items.vin)
            vinConsents[items.vin] = consentDetail
        })
        setSelectedVehicles(vins)
        setVehicleConsents(vinConsents)
    },[] )

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    })

    const handleChange = (e) => {
        let {name, value} = e.target;
        if(name === "question6" ) {
            if(value !== "Other(please specify)") delete selectedValues["otherGender"]
            else selectedValues["otherGender"] = ""
        }
        if(name === "question7" || name === "question8c") value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        selectedValues[name] = value
        setSelectedValues(selectedValues)
        setSync(!sync)
    }

    const handleContinue = async (currentPage) => {
        if(currentPage==="Questionnaire") {
            setSubmitClicked(true)
            let contract = processQuestionnaire(selectedValues, selectedVehicles)
            await Promise.all(selectedVehicles.map(async items => {
                await cs.saveConsents(items)
            })).then(()=> userDetailsService.expressInterestSignUp(contract).then(()=> {
                setConfirmSignUp(true)
                setSubmitClicked(false)

            }))

        }
        setShowQuestionaire(currentPage==="FinalizeEnrollment")
        window.scrollTo(0, 0)
    }

    const navigateToAccount =()=>{
        history.push("/account")
        window.location.reload()
    }

    const onCheckboxClick = (id) => {
        let [clickedVin, category] = id.split(" ")
        let selectedVehiclesCopy = selectedVehicles
        let vehicleConsentsCopy = vehicleConsents
        let vehicleDetails = userVehicles.find(items => items.vin === clickedVin)
        let consentDetail = vehicleDetails.status === ENROLLED ?
            {"privacyPolicy": true}
            : {"privacyPolicy": true, "agreementStatement": true}

        if(!Boolean(category)){
            setSelectedVehicles([])
            setVehicleConsents({})
            if(selectedVehiclesCopy.includes(clickedVin)){
                selectedVehiclesCopy = selectedVehiclesCopy.filter( items => items !== clickedVin)
                delete vehicleConsentsCopy[clickedVin]
            }else{
                selectedVehiclesCopy.push(clickedVin)
                vehicleConsentsCopy[clickedVin] = consentDetail
            }
        }else{
            let currentConsent = vehicleConsentsCopy[clickedVin]
            if(currentConsent===undefined){
                vehicleConsentsCopy[clickedVin] = {category:true}
            }else {
                currentConsent[category] = !currentConsent[category]
                vehicleConsentsCopy[clickedVin] = currentConsent
            }

        }
        setSelectedVehicles(selectedVehiclesCopy)
        setVehicleConsents(vehicleConsentsCopy)
        setSync(!sync)
    }

    const getConsentStatus = () => {
        for(const element of vinsEnrolledNotConsented) {
            if (!selectedVehicles.includes(element)) return true
        }
        for(const element of selectedVehicles) {
            let currentConsents = vehicleConsents[element]
            if(currentConsents["privacyPolicy"] === false || currentConsents["agreementStatement"] === false) return true
        }
        return false
    }

    const handlePrevious = (currentPage) => {
        setShowQuestionaire(currentPage==="FinalizeEnrollment")
        window.scrollTo(0, 0)
    }

    const getComponent = ()=>{
        return !showQuestionaire ? <FinalizeEnrollment onContinue={handleContinue}
                                                         selectedVehicles={selectedVehicles}
                                                         vehicleConsents={vehicleConsents}
                                                         onCheckboxClick={onCheckboxClick}
                                                         consentStatus={getConsentStatus}
                                                         userVehicles={notConsentedVehicles}/>
            : <ExpressInterestQuestionnaire onContinue={handleContinue}
                                            onPrevious={handlePrevious}
                                            onChange={handleChange}
                                            selectedValues={selectedValues}
                                            width={width}/>

    }

    return(
        <>
           <NetworkLoader isLoading={submitClicked}>
               {getComponent()}
               <ModalUnScrollable
                   id="expressSignUp"
                   data-cy = "expressSignUpPopUp"
                   show={confirmSignUp}
                   close={<ModelCloseButton onClick={navigateToAccount}/>}
                   modelIcon={ <img src={Checkmark} alt={"success"}/> }
                   modelHeader="Success"
                   modalCenter={ExpressInterestSignUpCss.modalCenter}
                   modalScroll={ExpressInterestSignUpCss.modalScroll}
                   modelSubHeader={null}
                   modelContent={<ModelContent text={"Thank you for reviewing our new program guidelines and terms."}/>}
                   modelFooter={<ModelCTAButton onClick={navigateToAccount} text={"Go to Account"}/>}
               />
           </NetworkLoader>

        </>


    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user,
    };
};
export default connect(mapStateToProps)(ActiveInactiveEnrollment)