import {connect} from "react-redux";
import ModalUnScrollable from "../../components/common/Model/ModalUnScrollable";
import ModelCloseButton from "../../components/common/Model/ModelCloseButton";
import ExpressInterestSignUpCss from "../expressInterest/ExpressInterestSignUp/ExpressInterestSignUp.module.css";
import TermsAndConditionCss from "./TermsAndConditions.module.css";

import React, {useState} from "react";
import fmaFunctions from "../../services/fmaFunctions";
import {ENROLLED} from "../../Constants/Status";
import {consentService as cs} from "../../services/consentService";
import {userDetailsService as uds} from "../../services/userDetailsService";
import {useHistory} from "react-router-dom";

const TermsAndConditionsPopUp =(props)=>{

    const [confirmTerms, setConfirmTerms] = useState(true)
    const [showLoader, setShowLoader] = useState(false)
    const [checkBoxStatus, setCheckBoxStatus] = useState({
        checkBox: false,
    })
    const history = useHistory()

    let userVehicles = props?.user?.userVehicles || []

    let enrolledVehicles =  userVehicles.filter(items=>(items.status===ENROLLED))

    console.log(enrolledVehicles, "Enrolled Vehicles")


    const onCheckboxClick = (id) => {
        setCheckBoxStatus(() => {
            return {
                checkBox: document.getElementById("checkbox").checked
            };
        })

    }

    const handleAcceptTerms = async () => {
        setShowLoader(true)
        await Promise.all(enrolledVehicles.map(async items => {
            await cs.saveConsents(items.vin)
            await uds.saveUser(items.vin)
        })).then(() => {
            setConfirmTerms(false)
            history.go(0);
        }).catch(e=>{
            console.log(e)
        })
    }
    const declineTerms=()=>{
        fmaFunctions().logout();
    }


    const handleClickTerms = ()=>{
        window.open('/eapterms', '_blank')
    }

    const LoaderScreen = <div className="fmc-activity-circle">
        <div className="fmc-activity-circle__progress"
             data-testid={"activityIndicator"}/>
    </div>

    return(
        <div>

            <ModalUnScrollable
                id="consentTerms"
                show={confirmTerms}
                close={showLoader?null:<ModelCloseButton onClick={declineTerms}/>}
                modelHeader={showLoader?null:<p className={TermsAndConditionCss.headerText}>We’ve Updated Our Terms and Conditions</p>}
                modalCenter={ExpressInterestSignUpCss.modalCenter}
                modalScroll={ExpressInterestSignUpCss.modalScroll}
                modelSubHeader={null}
                modelContent={showLoader?LoaderScreen:<div>

                    <fieldset className="fmc-check">
                        <label>
                            <input id={"checkbox"} type="checkbox" name="checkbox" value="checkbox" data-testid="acceptCheckBox" onClick={() => {
                                onCheckboxClick("checkbox");
                            }}/>
                            <span style={{textAlign:"left"}} data-testid="popupText">Please review our updated terms by clicking on the link
                                <br/><a className={TermsAndConditionCss.eapTermsLink} onClick={handleClickTerms} target={"_blank"}> Ford Early Access Program Terms </a></span>

                        </label>
                    </fieldset>
                </div>}
                modelFooter={showLoader?null:<div className={TermsAndConditionCss.ctaBtnContainer}>

                    <button
                        onClick={declineTerms}
                        data-testid ="declineBtn"
                        class="fmc-button fmc-button--outlined"
                    >
                        Decline

                    </button>


                    <button
                        onClick={handleAcceptTerms}
                        data-testid ="acceptBtn"
                        className="fmc-button"
                        disabled={!checkBoxStatus.checkBox}
                    >
                        Accept

                    </button>
                </div>}
            />
        </div>
    )


}
const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user,
    };
};
export default connect(mapStateToProps)(TermsAndConditionsPopUp)