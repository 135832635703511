import React, {useEffect, useState} from "react";
import VehicleImageWithTextCss from "./VehicleImageWithText.module.css"
import {vehicleService} from "../../../services/vehicleService";
import Loading from "../Loading/Loading";

const VehicleImageWithText = (props) => {

    let {vehicle} = props
    const getTextFromModelName = {
        "Mustang Mach-E": "Mustang Mach-E",
        "F-150": "F-150",
        "Edge": "EDGE",
        "Bronco":"Bronco",
        "Expedition":"Expedition",
        "F-250":"F-250",
        "F-350":"F-350",
        "Lightning":"Lightning",
        "Transit":"Transit"
    }

    const [width, setWidth] = useState(window.innerWidth);
    const [loader, setLoader] = useState(false)
    const [imageURLResult, setImageURLResult] = useState("")

    useEffect(() => {
        let isMounted=true;
        setLoader(true)

        getImage()

        return()=>{
            isMounted = false
        }
    }, [vehicle?.vin,vehicle?.modelYear]);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    })

    const getImage=  async () => {
        try {
            await vehicleService.getVehicleImage(vehicle?.vin, vehicle?.modelYear)
                .then(response => response.blob())
                .then(blob => {
                    const imageUrl = URL.createObjectURL(blob);
                    setImageURLResult(imageUrl);
                    setLoader(false)
                })
                .catch(error => console.error('Error fetching image:', error));
        } catch (e) {
        }
    }

    return (
        <>
            {
                loader ? <Loading/>:<div className={VehicleImageWithTextCss.container}>
                    <div className={VehicleImageWithTextCss.stroke}>
                        {getTextFromModelName[vehicle?.modelName]}
                    </div>
                    <div className={VehicleImageWithTextCss.backGroundImg}>
                        <img src={imageURLResult}
                             alt="Vehicle"
                             data-testid={vehicle?.vin}
                             width={width < 630 ? "300px" : ""}/>
                    </div>
                </div>
            }
        </>
    )
}

export default VehicleImageWithText;