import React from "react";
import {ENROLLED, INVITED, NOT_INVITED, WAITLISTED} from "../Constants/Status";
import {userDetailsService} from "../services/userDetailsService";

export const isActiveInactive = (props) => {
    let enrolledVehicles = props
        .filter((items => items.status === ENROLLED))
    let notConsentedVehicles = enrolledVehicles
        .filter(items => items.consents.find(i=> i.status===false))
    return notConsentedVehicles.length > 0;
}

export const getNotConsentedVins = (props) => {
    let enrolledVehicles = props
        .filter((items => items.status === ENROLLED))
    let notConsentedVehicles = enrolledVehicles
        .filter(items => items.consents.find(i=> i.status===false))
    let out = []
    notConsentedVehicles.forEach( items => out.push(items.vin))
    return out
}

export const filterConsentedVehicles = (props) => {
     return props
        .filter(items => items.consents.find(i=> i.status===false) || items.status===WAITLISTED || items.status===NOT_INVITED || items.status===INVITED)
}

export const processQuestionnaire = (selectedValues, selectedVehicleList) => {
    let answers = {...selectedValues}

    if(Boolean(answers["otherGender"])) {
        answers["question6"] = answers["otherGender"]
        delete answers["otherGender"]
    }

    answers["question8"] = `${answers["question8a"]}, ${answers["question8b"]}, ${answers["question8c"]}`

    delete answers["question8a"]
    delete answers["question8b"]
    delete answers["question8c"]

    let answerArray = []
    Object.keys(answers).map( items => {
        return answerArray.push( { "answer": answers[items], "questionCode": items}  )
    })

    return {
        "questionnaireAnswers": answerArray,
        "vins": selectedVehicleList
    }
}

export const isAnyVehicleEnrolled = (props) => {
    let enrolledVehicles = props
        .filter((items => items.status === ENROLLED))
    return enrolledVehicles.length > 0;
}

export const removeSelectedVehicles = async (enrolledVehicles, props) => {
    let enrolledDetails = props.filter(items => enrolledVehicles.includes(items.vin))

    for(const elem of enrolledDetails){
        await userDetailsService.removeVehicleForUser(elem.userVehicleId).catch((error) => {});
    }
}


