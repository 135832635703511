import ReactActivityIndicator from "assets/js/ReactActivityIndicator";

export default function Loading() {
    return (
        <div
            style={{paddingTop: "250px", paddingBottom: "200px"}}
        >
            <ReactActivityIndicator/>
        </div>
    );
}
