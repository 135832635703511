import {connect} from "react-redux";
import NoticeTemplateCss from "./NoticeTemplate.module.css"
import ExpressInterestSignUpCss from "../../expressInterest/ExpressInterestSignUp/ExpressInterestSignUp.module.css";
import React, {useState} from "react";
import classNames from "classnames";
import {userDetailsService as uds, userDetailsService} from "../../../services/userDetailsService";
import {setUserProfile} from "../../../services/userAuthentication";
import {MANAGEVEHICLES} from "../../../Constants/Navigation";
import Loading from "../../../components/common/Loading/Loading";
import {removeSelectedVehicles} from "../../../util/EnrollmentFlow";
import {useAmplitude} from "../../../useAmplitude/src/useAmplitude";
import {consentService as cs, consentService} from "../../../services/consentService";
import {LegalSubHeader} from "../../../Constants/Legal";
import {ENROLL, WAITLIST} from "../../../Constants/CTA";
import {useHistory} from "react-router-dom";
import {ENROLLED, WAITLISTED} from "../../../Constants/Status";
import fmaFunctions from "../../../services/fmaFunctions";

const NoticeTemplate = (props) => {
    const {logger} = useAmplitude();
    const history = useHistory();

    let enrolledVehicles = props?.vehicleReducer?.enrolledVehicles?.enrolledVehicles || []
    let userVehicles = props?.user?.userVehicles || []

    let allVehiclesVin = []
    userVehicles.forEach(items=> allVehiclesVin.push(items.vin))

    let manageVehicles = enrolledVehicles.filter(items=>items.status === ENROLLED || items.status === WAITLISTED)
    const isManageVehicles = MANAGEVEHICLES.includes(props.title)
    const lowerCaseTitle = props.title.replaceAll(" ", "").toLowerCase()
    const isEnrollVehicle = lowerCaseTitle.includes(ENROLL.toLowerCase())
    const isWaitlistVehicle = lowerCaseTitle.includes(WAITLIST.toLowerCase())

    const [selectedVehicleList, setSelectedVehicleList] = useState([])
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [checkBoxStatus, setCheckBoxStatus] = useState({
        checkBox1: false,
        checkBox2: false,
    });

    const onCheckboxClick = (id) => {
        setCheckBoxStatus(() => {
            return {
                checkBox1: document.getElementById("checkbox1").checked,
                checkBox2: document.getElementById("checkbox2").checked,
            };
        })
        const analytics = {
            checkbox1: "terms and conditions tapped",
            checkbox2: "contact consent tapped",
        };
        logger(analytics[id], {
            screenName: "invited consent",
            termsAccepted: document.getElementById(id).checked,
        });
    }

    const onJointheWaitlist = async () => {
        logger("join waitlist tapped", {
            programStatus: "waitlisted",
            vehicleMakeModelYear: props.model,
            screenName: "waitlist consent",
        });
        await consentService.saveConsents(props.vin)
        await userDetailsService.saveUser(props.vin)
        await setUserProfile().then();
    };

    const onEnroll = async () => {
        logger("sign up tapped", {
            programStatus: "enrolled",
            vehicleMakeModelYear: props?.user?.userVehicles[0]?.modelName,
            screenName: "invited consent",
            previousUrl: document.referrer,
        });
        await cs.saveConsents(props.vin);
        await uds.saveUser(props.vin);
        await setUserProfile().then(() => {
            history.push("/welcome");
        });
    };
    const onVinSelect = (item) => {
        let itemPresent = selectedVehicleList.indexOf(item)
        let selectedVehicleListCopy = [...selectedVehicleList]
        if (itemPresent >= 0) {
            selectedVehicleListCopy.splice(itemPresent)
        } else {
            selectedVehicleListCopy.push(item)
        }
        setSelectedVehicleList(selectedVehicleListCopy)
    }

    const ctaHandler = () => {
        setShowProgressBar(true)
        if (isWaitlistVehicle) return onJointheWaitlist()
        if (isEnrollVehicle) return onEnroll()
        if (isManageVehicles)  {
             return removeSelectedVehicles(selectedVehicleList,enrolledVehicles).then(()=>history.go(0)).catch((error) => {});
        }
        return exitEAPCompletely()
    }

    const exitEAPCompletely = async () => {
        try {
            await userDetailsService.leaveEAP(allVehiclesVin).then(()=> fmaFunctions().logout()).catch((error) => {});
        } catch (e) {
        }
    };

    const getNoticeContent = () => {
        if (isManageVehicles) {
            return <>
                <div className={classNames(NoticeTemplateCss.descriptionText, " fds-m--t-1")}>
                    Select vehicle(s) to remove from the Early Access Program:
                </div>

                <fieldset className="fmc-check">
                    {manageVehicles.map((items, i) => {
                        return <div className={"fds-m--t-2"} key={i}>
                            <label>
                                <input type="checkbox"
                                       name="checkbox-item-1"
                                       value={items.vin}
                                       id={items.vin}
                                       data-testid={items.vin}
                                       checked={selectedVehicleList.includes(items.vin)}
                                       onChange={() => onVinSelect(items.vin)}
                                       className={ExpressInterestSignUpCss.textInputBody}/>
                                <span
                                    className={ExpressInterestSignUpCss.textInputBody}> {items.modelYear} {items.modelName} </span>
                            </label>
                        </div>
                    })}
                </fieldset>
            </>
        }

        if (isEnrollVehicle) {
            return <>
                <div className="fds-p--t-2">
                    {LegalSubHeader}
                </div>
                <>
                    <fieldset className="fmc-check ">
                        <label className={NoticeTemplateCss.labelContainer}>
                            <input
                                data-testid="checkbox1"
                                id="checkbox1"
                                type="checkbox"
                                onClick={() => {
                                    onCheckboxClick("checkbox1");
                                }}
                            />
                            <>
                                <span>
                                I have read and agree to the{" "}
                                    <a
                                        style={{textDecoration: "underline"}}
                                        target="_blank"
                                        href="/eapterms"
                                    >
                                    Ford Early Access Program Terms
                                </a>
                                , as well as the{" "}
                                    <a
                                        rel="noreferrer"
                                        style={{textDecoration: "underline"}}
                                        href="/fpterms"
                                        target="_blank"
                                    >
                                    Ford.com Terms & Conditions
                                </a>{" "}
                                    and{" "}
                                    <a
                                        rel="noreferrer"
                                        style={{textDecoration: "underline"}}
                                        href="https://www.ford.com/support/how-tos/fordpass/manage-my-fordpass-account/fordpass-terms-and-conditions/#privacy_policy"
                                        target="_blank"
                                    >
                                    Ford.com Privacy Policy
                                </a>

                                    </span>
                            </>



                        </label>
                    </fieldset>


                    <hr className="fds-divider__horizontal-middle"/>
                    <div className="fds-p--t-1 fds-type--body1">
                        We will contact you when there are new features available for
                        you to experience
                    </div>

                    <fieldset className="fmc-check ">
                        <label className={NoticeTemplateCss.labelContainer}>
                    <input
                        className="fds-p--t-1"
                        dataTestId="checkbox2"
                        id="checkbox2"
                        type="checkbox"
                        disabled={!checkBoxStatus.checkBox1}
                        onClick={() => {
                            onCheckboxClick("checkbox2");
                        }}
                    />
                            <span>
                                I agree to be contacted by email and by FordPass in-app
                                messaging to participate in new Early Access features.
                            </span>

                        </label>

                    </fieldset>
                </>
            </>
        }

        if (isWaitlistVehicle) {
            return <>
                <div className="fds-p--t-2">
                    {LegalSubHeader}
                </div>
                <p className="fds-type--body1 fds-p--t-1">
                    <fieldset className="fmc-check ">
                        <label className={NoticeTemplateCss.labelContainer}>
                            <input type="checkbox" data-testId="checkbox1"
                                   id="checkbox1"
                                   onClick={() => {
                                       onCheckboxClick("checkbox1");
                                   }}/>
                            <>
                               <span> I have read and agree to the{" "}
                                   <a
                                       style={{textDecoration: "underline"}}
                                       target="_blank"
                                       href="/terms"
                                   >
                                    FordPass Terms & Privacy Policy
                                </a>
                                , as well as the{" "}
                                   <a
                                       rel="noreferrer"
                                       style={{textDecoration: "underline"}}
                                       href="https://www.ford.com/help/terms/"
                                       target="_blank"
                                   >
                                    Ford.com Terms & Conditions
                                </a>{" "}
                                   and{" "}
                                   <a
                                       rel="noreferrer"
                                       style={{textDecoration: "underline"}}
                                       href="https://www.ford.com/help/privacy/"
                                       target="_blank"
                                   >
                                    Ford.com Privacy Policy
                                </a>
                               </span>
                            </>
                        </label>
                    </fieldset>
                    <hr className="fds-divider__horizontal-middle"/>
                    <div className="fds-type--body1 fds-p--t-1">
                        We will contact you when there are new features available
                        for you to experience
                    </div>
                    <fieldset className="fmc-check ">
                        <label className={NoticeTemplateCss.labelContainer}>
                            <input type="checkbox" name="checkbox-item-1"
                                   dataTestId="checkbox2"
                                   id="checkbox2"
                                   disabled={!checkBoxStatus.checkBox1}
                                   onClick={() => {
                                       onCheckboxClick("checkbox2");
                                   }}/>
                            <span>I agree to be contacted by email and by FordPass in-app
                                messaging to participate in new Early Access features.</span>
                        </label>
                    </fieldset>

                </p>

            </>
        }
        return <div className={NoticeTemplateCss.leaveProgramMessage}>
            Thanks for participating in the Ford Early Access Program.
        </div>
    }

    const getCTAText = () => {
        if (isEnrollVehicle) return "Enroll"
        if (isWaitlistVehicle) return "Join the waitlist"
        if (isManageVehicles) return "Remove Vehicle"
        return "Leave Program"
    }

    const checkCTADisabled = () => {
        if (isWaitlistVehicle || isEnrollVehicle) {
            return !(checkBoxStatus.checkBox1 && checkBoxStatus.checkBox2)
        }
        if (isManageVehicles) {
            return !selectedVehicleList.length
        }
        return false
    }

    if (showProgressBar) return <Loading/>

    return (
        <div className={NoticeTemplateCss.manageVehicleContainer}>
            <div>
                <span className="fds-icon fds-icon--32 fds-font--ford-icons__info-outlined"/>
                <div className={NoticeTemplateCss.manageVehicle}> {props.title} </div>
                {getNoticeContent()}
                <div className={NoticeTemplateCss.buttonContainer}>
                    <button className="fmc-button"
                            onClick={ctaHandler}
                            disabled={checkCTADisabled()}
                            data-testid={"leaveProgram"}>
                        {getCTAText()}
                    </button>
                    <button className={"fmc-text-button fds-p--t-2"}
                            onClick={() => props.backToAccount("account")}
                            data-testid={"cancelLeaveProgram"}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user,
    };
};


export default connect(mapStateToProps)(NoticeTemplate);