import VehicleImageWithText from "../../components/common/VehicleImage/VehicleImageWithText";
import GarageRefreshCss from "./GarageRefresh.module.css";
import React, {useState} from "react";
import {connect} from "react-redux";
import {removeSelectedVehicles} from "../../util/EnrollmentFlow";
import Loading from "../../components/common/Loading/Loading";
import {userDetailsService} from "../../services/userDetailsService";
import {useHistory} from "react-router-dom";
import {NOT_INVITED} from "../../Constants/Status";

const GarageRefresh = (props) => {
    let {erroneousVehicle} = props
    let enrolledVehicles = props?.vehicleReducer?.enrolledVehicles?.enrolledVehicles || []

    let userVehicles = props?.user?.userVehicles || []
    let availableVehicles = userVehicles.filter(items => items?.status === NOT_INVITED)

    const history = useHistory();
    let [showLoading, setShowLoading] = useState(false)

    if(showLoading) return <Loading />
    const handleLeaveProgram = () => {
        setShowLoading(true)
        removeSelectedVehicles(enrolledVehicles).then()
    }
    const handleReplaceVehicle = () => {
        setShowLoading(true)
        try{
            userDetailsService.replaceVehicle(erroneousVehicle.vin)
                .then(() => {
                    history.push("/consent");
                    history.go(0);
                }).catch((error) => {});
        }catch(e){
            console.log(e)
        }
        setTimeout(() => setShowLoading(false), 5000)
    }
    const handleRemoveVehicle = () => {
        setShowLoading(true)
        try{
            userDetailsService.leaveEAP([props.erroneousVehicle.vin])
                .then(() => {
                    history.push("/consent");
                    history.go(0);
                }).catch((error) => {});
        }catch(e){
            console.log(e)
        }
        setTimeout(() => setShowLoading(false), 5000)
    }

    return (
        <React.Fragment>
            <VehicleImageWithText vehicle={erroneousVehicle}/>
            <div className="fds-align--center fds-p--t-3 fds-m--t-3">
                <div className={GarageRefreshCss.title}>
                    Ford Account Update
                </div>
                <div className={GarageRefreshCss.noticeText}>
                    We noticed a change in your Ford account. Would
                    you like to remove your old vehicle, or
                    <br/>
                    replace it with your new eligible vehicle?
                </div>
                <div className={GarageRefreshCss.noticeText}>
                    Please be advised that any products your previous
                    vehicle was enrolled in will not carry over
                    <br/>
                    to your new vehicle.
                </div>
                <button className="fmc-button fmc-button--outlined fds-m--t-2"
                        disabled={availableVehicles.length===0}
                        onClick={handleReplaceVehicle}
                        data-testid="replace_vehicle_cta">
                    Replace Vehicle
                </button>
                <br/>
                <button className="fmc-button fmc-button--outlined fds-m--t-2"
                        onClick={handleRemoveVehicle}
                        data-testid="remove_vehicle_cta">
                    Remove Vehicle
                </button>
                <br/>
                <button className="fmc-text-button fds-m--t-2"
                        onClick={handleLeaveProgram}
                        data-testid="leave_program_gr">
                    Leave Program
                </button>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        ...state,
        user: state.userDataReducer.user,
    };
};


export default connect(mapStateToProps)(GarageRefresh);