import ModelCss from "./Model.module.css";
import React, { useEffect, useState } from "react";

const Model = ({
  id = "id",
  close = "closeButton",
  show = false,
  modelIcon = "icon",
  modelHeader = "modelHeader",
  modelSubHeader = "modelSubHeader",
  modelContent = "modelContent",
  modelFooter = "modelFooter",
  focusableElement = 0,
}) => {
  const [activeElement, setActiveActiveElement] = useState(null);

  const focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  useEffect(() => {
    if (show) {
      setActiveActiveElement(() => {
        return {
          actElement: document.activeElement,
        };
      });
      document.body.style.overflow = "hidden";
      const modal = document.querySelector("#" + id); // select the modal by it's id


      const firstFocusableElement = modal.querySelectorAll(
        focusableElements
      )[0]; // get first element to be focused inside modal
      const focusableContent = modal.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

      document.addEventListener("keydown", function (e) {
        let isTabPressed = e.key === "Tab" || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus(); // add focus for the last focusable element
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      });
      modal.querySelectorAll(focusableElements)[focusableElement].focus();
    } else {
      document.body.style.overflow = "auto";
      activeElement?.actElement.focus();
    }
  }, [activeElement?.actElement, focusableElement, id, show]);

  return (
    show === true && (
      <div id={id} className=" fds-align--center" >
        <div role="dialog" aria-labelledby={id} className={ModelCss.modal}>
          <div className={ModelCss.modalContent}>
            <div className={ModelCss.close} >{close}</div>
            <div className={ModelCss.modelContentInner} >
              {modelIcon}
              <h1 aria-labelledby={id} className="fds-type--title2 fds-p--t-2">
                {modelHeader}
              </h1>
              <div className="fds-type--subtitle1 fds-p--t-2" >
                {modelSubHeader}
              </div>
              <p className="fds-type--body1 fds-p--t-1">{modelContent}</p>
              <div className="fds-p--t-2">{modelFooter}</div>
            </div>
          </div>
        </div>
        <div className={ModelCss.modalBackdrop} />
      </div>
    )
  );
};
export default Model;
