import {
    PRODUCTS_SUCCESS,
    PRODUCTS_ERROR,
} from "../ActionTypes/UserDataActionTypes";

const initialState = {
    enrolledProducts: [],
    availableProducts: []
}

const productReducer = (state = initialState, action) => {
    switch (action?.type) {

        case PRODUCTS_SUCCESS:

            return {
                ...state,
                enrolledProducts: action.payload.enrolledProducts,
                availableProducts: action.payload.availableProducts,
            }
        case PRODUCTS_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}

export default productReducer;