import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import IneligibleVehicle from "./IneliglibleVehicle/IneligibleVehicle";
import IneligibleCountry from "./IneliglibleCountry/IneligibleCountry";
import EmptyEmail from "./EmptyEmail/EmptyEmail";
import {useAmplitude} from "../../useAmplitude/src/useAmplitude";
import Loading from "../../components/common/Loading/Loading";
import {ENROLLED, INVITED, NOT_INVITED, WAITLISTED} from "../../Constants/Status";
import {isActiveInactive} from "../../util/EnrollmentFlow";
import GarageRefresh from "../garageRefresh/GarageRefresh";
import InvalidGarageRefresh from "../garageRefresh/InvalidGarageRefresh/InvalidGarageRefresh";
import ConflictVehicle from "./ConflictVehicle/ConflictVehicle";
import TermsAndConditionsPopUp from "../termsAndConditions/TermsAndConditionsPopUp";

const ConsentModule = (props) => {

    const history = useHistory();
    let previousNavigation = localStorage.getItem("path")

    const {setUserProfile} = useAmplitude();
    let userVehicles = props?.user?.userVehicles || []
    let {garageRefresh} = props?.user?.userProfile || []
    if (garageRefresh === undefined || garageRefresh === null) garageRefresh = []
    setUserProfile(props?.user?.userProfile?.userGuid);

    let questionnaireAnswered = props?.user?.questionnaireCompleted

    const invalidEmail = props.user?.userProfile?.email === null || props.user?.userProfile?.email === "";
    let notInvitedVehicles = userVehicles.filter(items => items?.status === NOT_INVITED)
    let anyVehiclePartOfEAP = userVehicles.filter((items => (items.status !== ENROLLED && items.status !== WAITLISTED && items.status !== INVITED && items.status !== NOT_INVITED)))
    let finalizeEnrollment = userVehicles.filter((items => (items.status !== ENROLLED && items.status !== WAITLISTED && items.status === INVITED)))
    let vehiclesToBeEnrolledInEAP = userVehicles.filter(items => items?.status === INVITED || items?.status === NOT_INVITED || items?.status === ENROLLED || items?.status === WAITLISTED)
    let conflictingVehicles = userVehicles.filter(items => items?.conflict === true)

    if (props.user?.userProfile?.country !== "USA") return <IneligibleCountry/>
    if (garageRefresh && garageRefresh.length && notInvitedVehicles.length === 0 && vehiclesToBeEnrolledInEAP.length === 0) return <InvalidGarageRefresh/>
    if (vehiclesToBeEnrolledInEAP.length === 0) return <IneligibleVehicle/>
    if (invalidEmail) return <EmptyEmail/>
    if (garageRefresh.length) return <GarageRefresh erroneousVehicle={garageRefresh[0]}/>
    if (garageRefresh && garageRefresh.length) return <GarageRefresh erroneousVehicle={garageRefresh[0]}/>

    if (conflictingVehicles && conflictingVehicles.length) return <ConflictVehicle/>

    if (isActiveInactive(userVehicles) && questionnaireAnswered) {
        return <TermsAndConditionsPopUp/>
    } else if (isActiveInactive(userVehicles)) {
        history.push("/activeInactiveEnrollment")
    } else if (previousNavigation != null) {
        localStorage.clear()
        history.push(previousNavigation)
    } else if (finalizeEnrollment.length > 0 && anyVehiclePartOfEAP.length === 0) {
        history.push("/finalizeEnrollment")
    } else if (notInvitedVehicles.length === userVehicles.length) {
        history.push("/expressInterest")
    } else history.push("/account")

    return (
        <div className="fds-app">
            <Loading/>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user,
    };
};

export default connect(mapStateToProps)(ConsentModule);