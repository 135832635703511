import React, { useState } from "react";
import classNames from "classnames";

const ReactSecondaryButton = ({
  className, // additional classname utilized by outer div
  onClick,
  onMouseOver,
  onMouseOut,
  onKeyDown,
  onKeyUp,
  onBlur,
  disabled,
  colorScheme = "dark",
  chevron, // "left or right" (not implemented)
  buttonProps, // props to set on the button contained in the div
  children, // default property passed by React
  dataTestId = "reactSecondaryButton",
  ...rest // any additional props to set on the outer div, such as data-testid
}) => {
  const classes = {
    COMPONENT: "js-fds-secondary-button",
    BUTTON: "fds-secondary-button__button",
    HOVER: "fds-secondary-button--hover",
    ACTIVE: "fds-secondary-button--active",
    FOCUSED: "fds-secondary-button--focused",
    DISABLED: "fds-secondary-button--disabled",
    TOUCH: "fds-secondary-button--touch",
    ICON: "fds-icon",
    ICON_WRAPPER: "fds-primary-button__icon-wrapper",
    ICON_CHEVRON_PATH1: "fds-primary-button__chevron-filled-right-path1",
    ICON_CHEVRON_PATH2: "fds-primary-button__chevron-filled-right-path2",
    COLOR_SCHEME: `fds-color__bg--${colorScheme}`,
  };

  const [state, setState] = useState({
    displayHover: false,
    displayActive: false,
    displayFocused: false,
  });

  const onMouseOverWrapper = () => {
    if (!disabled) {
      setState((prevState) => {
        return { ...prevState, displayHover: true };
      });
      if (onMouseOver) {
        onMouseOver();
      }
    }
  };

  const onMouseOutWrapper = () => {
    if (!disabled) {
      setState((prevState) => {
        return { ...prevState, displayHover: false };
      });
      if (onMouseOut) {
        onMouseOut();
      }
    }
  };

  const onClickWrapper = (e) => {
    if (!disabled) {
      setState((prevState) => {
        return { ...prevState, displayHover: false, displayActive: true };
      });
      if (onClick) {
        onClick(e);
      }
    }
  };

  const onBlurWrapper = () => {
    if (!disabled) {
      setState((prevState) => {
        return {
          ...prevState,
          displayHover: false,
          displayActive: false,
          displayFocused: false,
        };
      });
      if (onBlur) {
        onBlur();
      }
    }
  };

  const onKeyUpWrapper = (e) => {
    if (!disabled) {
      if (e.keyCode === 9) {
        onMouseOverWrapper();
        setState((prevState) => {
          return { ...prevState, displayFocused: true };
        });
      }
      if (onKeyUp) {
        onKeyUp(e);
      }
    }
  };

  const onKeyDownWrapper = (e) => {
    if (!disabled) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        e.preventDefault();
        onClickWrapper(e);
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    }
  };

  /* uses this package: https://www.npmjs.com/package/classnames to make conditional
    class names easier to manage. */
  const classList = classNames(
    "js-fds-secondary-button fds-secondary-button",
    classes.COLOR_SCHEME,
    { [classes.FOCUSED]: state.displayFocused },
    { [classes.ACTIVE]: state.displayActive },
    { [classes.HOVER]: state.displayHover },
    { [classes.DISABLED]: disabled },

    { className: className }
  );

  return (
    <div className={classList} data-testid={dataTestId} {...rest}>
      <button
        type="button"
        className="fds-secondary-button__button"
        onClick={onClickWrapper}
        onMouseOver={onMouseOverWrapper}
        onMouseOut={onMouseOutWrapper}
        onBlur={onBlurWrapper}
        onKeyUp={onKeyUpWrapper}
        onKeyDown={onKeyDownWrapper}
        {...buttonProps}
        disabled={disabled}
        data-testid={dataTestId + "Button"}
      >
        {children}
      </button>
    </div>
  );
};

export default ReactSecondaryButton;
