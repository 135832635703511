import React from "react";

const ReactActivityIndicator = () => {
    return (
        <div className="fmc-activity-circle">
            <div className="fmc-activity-circle__progress"
                 data-testid={"activityIndicator"}/>
        </div>
    );
};

export default ReactActivityIndicator;
