import myProducts from "../assets/MyProducts.svg";
import support from "../assets/Support.svg";
import myAccount from "../assets/ResearchPanel.svg";
import {AVAILABLE, ENROLLED, PAST} from "./Status";

export const MANAGEVEHICLES = "Manage Vehicles"
export const LEAVEPROGRAM = "Leave Program"
export const MYPRODUCTS = "My Products"
export const SUPPORT = "Support"

export const PROFILE = "Profile"
export const stepsToEnroll = [
    {
        logo: myAccount,
        title: "Profile",
        desc: "View and edit your profile information",
        alt: "View",
        cta: "View"
    },
    {
        logo: myProducts,
        title: "My Products",
        desc: "Available features designed to elevate your Ford journey.",
        alt: "View",
        cta: "View"
    },
    {
        logo: support,
        title: "Support",
        desc: "Need a hand? Help is just around the corner. ",
        alt: "View",
        cta: "View"
    },
];
export const productTabNames = [ENROLLED, AVAILABLE, PAST]