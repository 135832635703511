import React from "react";

const ReactCard = ({
                       cardImage = null,
                       cardTitle = "Card Title",
                       cardSubtitle = null,
                       cardBody = "Card Body",
                       cardFooter = "Card Footer",
                       alertType = "success",
                       alertMessage = "Success",
                       otherCardBodyElements = "",
                   }) => {
    return (
        <Card>
            <CardMediaArea>
                <img
                    className="fds-cards__image"
                    style={{
                        margin: "auto",
                        width: "80%",
                        paddingBottom: "20px"
                    }}
                    src={
                        (cardImage &&
                            "https://build.ford.com/dig/direct/HD-FULL/Vin[" +
                            cardImage +
                            "]/EXT/4/vehicle.png") ||
                        (cardImage === null &&
                            "https://build.ford.com/dig/direct/HD-TILE/Vin%5B1FMCU9JDXHUA78907%5D/EXT/4/vehicle.png")
                    }
                    alt="Car"
                />
                {alertType === "success" && (
                    <div className="fds-cards__alert fds-cards__alert--success">
                        {alertMessage}
                    </div>
                )}
                {alertType === "caution" && (
                    <div className="fds-cards__alert fds-cards__alert--caution">
                        {alertMessage}
                    </div>
                )}
                {alertType === "error" && (
                    <div className="fds-cards__alert fds-cards__alert--error">
                        {alertMessage}
                    </div>
                )}
            </CardMediaArea>
            <CardContent>
                <CardTitle>{cardTitle}</CardTitle>
                <CardSubtitle>{cardSubtitle}</CardSubtitle>
                <div style={{marginBottom: 0}} className="fds-cards__body">
                    {otherCardBodyElements}
                </div>
                <CardFooter>{cardFooter}</CardFooter>
            </CardContent>
        </Card>
    );
};

const Card = ({children, ...rest}) => {
    return (
        <div className="fds-cards__card fds-layout-grid__cell--span-6" {...rest}>
            {children}
        </div>
    );
};

const CardMediaArea = ({children, ...rest}) => {
    return (
        <div className="fds-cards__media-area" {...rest}>
            {children}
        </div>
    );
};

const CardContent = ({children, ...rest}) => {
    return (
        <div className="fds-cards__content" {...rest}>
            {children}
        </div>
    );
};

const CardTitle = ({children, ...rest}) => {
    return (
        <h2
            className="fds-cards__title fds-type--title3"
            {...rest}
        >
            {children}
        </h2>
    );
};

const CardSubtitle = ({children, ...rest}) => {
    return (
        <h3
            className="fds-cards__body fds-type--body1"
            {...rest}
        >
            {children}
        </h3>
    );
};

const CardBody = ({children, ...rest}) => {
    return (
        <p className="fds-cards__body" {...rest}>
            {children}
        </p>
    );
};

const CardFooter = ({children, ...rest}) => {
    return (
        <div
            style={{border: "none", paddingTop: "3rem"}}
            className="fds-cards__footer"
            {...rest}
        >
            {children}
        </div>
    );
};
export {
    ReactCard as default,
    Card,
    CardMediaArea,
    CardTitle,
    CardSubtitle,
    CardBody,
    CardContent,
    CardFooter,
};
