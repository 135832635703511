import {combineReducers} from "redux";

import userDataReducer from "./UserDataReducer"
import vehicleReducer from "./VehicleReducer"
import productReducer from "./ProductReducer";

let combinedReducer = combineReducers({
    userDataReducer,
    vehicleReducer,
    productReducer
})

export default combinedReducer