import React from "react";


function ModelCTAButton(props) {

    return(
        <div style={{ display: "inline" }}>
            <div style={{ padding: "0 20px", display: "inline-block" }}>
                <button
                    onClick={props.onClick}
                    data-testid ="modelCTAButton"
                    className="fmc-button"
                >
                    {props.text}
                    <span data-dark
                          className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right">

                    </span>
                </button>
            </div>
        </div>
    )

}
export default ModelCTAButton
