export const programGuidlinesList = [ "Respond to survey and feedback requests within 7 days",
    "Testing and consumer feedback is a critical part of our development process. " +
    "Because beta software can be buggy, we ask that members come to us first instead of " +
    "discussing any issues in social media or other public platforms. " +
    "We expect our members to treat beta features and the information learned in the testing experience as confidential information." ]

export const betaLevelSoftwareList = [
    "Understand the software provided is pre-release, therefore you should expect\n" +
    "some errors, bugs or failures",
    "As beta products are still in a testing phase,\n" +
    "there may be times we need to pull back a feature while we enhance the feature\n",
    "Understand that not all issues submitted will be resolved immediately.\n" +
    "Some are put in our backlog and prioritized for future release"
]

export const communicationFeedbackList = [
    "Understand the importance of prompt and active survey participation",
    "Provide constructive feedback, both positive and negative",
    "Tell us the best way to communicate with you — phone, email or FordPass™ App",
    "Respond to program requests and inquiries in a reasonable timeframe"
]