import React from "react";
import ConflictVehicleStyle from "./ConflictVehicleStyle.css"
import ReactPrimaryButton from "../../../assets/js/ReactPrimaryButton";
import fmaFunctions from "../../../services/fmaFunctions";
import {AmplitudeEvent, useAmplitude} from "useAmplitude/src/useAmplitude";

export default function ConflictVehicle() {
  const { logger } = useAmplitude();
  const returnHome = () => {
    logger("return to home tapped", {
      screenName: "ineligible user",
      previousUrl: document.referrer,
    });
    fmaFunctions().logout();
  };

  return (
    <div className="fds-app">
      <AmplitudeEvent
        event="ineligible user screen viewed"
        properties={{
          screenName: "ineligible user",
          previousUrl: document.referrer,
        }}
      />

        <div >
            <div className="warning" data-testid="warn">
            <span className="fds-icon fds-icon--32 fds-icon--offset-right-sm fds-font--ford-icons__error "/>
            </div>
            <br/>
            <p className="were-sorry" data-testid="were-sorry">We’re Sorry</p>
                <br/>
            <p className="thank-you-for-your-i" data-testid="thank-you-content" >It looks like your Ford Account reflects a vehicle that is associated to another user. <br/> Please email us at <a href="mailto:pearlyac@ford.com">pearlyac@ford.com</a> in order to get this fixed.</p>
            <br/>  <br/>
            <div className="cards-horizontal-building-bloc">
            <ReactPrimaryButton  dataTestId="returnHome"  colorScheme="light"  onClick={returnHome}>
                <span className="fds-primary-button__button-text center">Go Back</span>
              </ReactPrimaryButton>
            </div>
        </div>

    </div>
  );
}
