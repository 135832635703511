import {
  CLEAR_ERRORS,
  USER_DATA_REQUEST,
  USER_DATA_ERROR,
  USER_DATA_SUCCESS,
  BLUE_CRUISE_SIGNUP_ERROR,
  BLUE_CRUISE_SIGNUP_REQUEST,
  BLUE_CRUISE_SIGNUP_SUCCESS,
} from "../ActionTypes/UserDataActionTypes";

const initialState = {
  loading: true,
  user: null,
  error: null,
};

const userDataReducer = (state = initialState, action) => {
  switch (action?.type) {
    case USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    }
    case USER_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case BLUE_CRUISE_SIGNUP_REQUEST: {
      return {
        ...state,
      }
    }
    case BLUE_CRUISE_SIGNUP_SUCCESS: {
      return {
        ...state,
        blueCruiseSignedIn: action.payload
      }
    }

    case BLUE_CRUISE_SIGNUP_ERROR: {
      return {
        ...state,
        blueCruiseSignedInError: action.payload

      }
    }
    default:
      return state;
  }
};
export default userDataReducer;
