import React from "react";
import IPBillboardCss from './IPBillboard.module.css';

export default function IPBillboard(props) {


    return (
        <div
            className={
                IPBillboardCss.homeContainer
            }
        >
            <div>
                <div>
                    <h1 data-testid={"billboardHeaderText"}
                        className={IPBillboardCss.headerText}>
                        Ford Intelligent Power
                    </h1>

                    <h3 className={IPBillboardCss.subHeaderText}>Energy Reimagined</h3>
                </div>
                <div className={IPBillboardCss.waitListCTA}>
                    <button className="fmc-button" onClick={props.handleSurvey} data-dark data-testid="signUpBillBoardBtn">Sign Up
                        <span
                            className="fds-icon fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"/>
                    </button>
                    <div className={IPBillboardCss.btmBillboardTxt}>
                        Will be available for all F-150 Lightnings in select markets
                    </div>
                </div>
            </div>
        </div>
    )
}