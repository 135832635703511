import listImage from "../assets/bullet.svg";
import React from "react";

const bulletImageStyle = {alignSelf: "start", paddingBlockStart: "8px"}

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

export const getFormattedList = (text) => {
    return <li className="fds-p--t-1" key={text}>
                        <span style={{display: "flex", alignContent: "flex-start"}}>
                            <img src={listImage} style={bulletImageStyle} alt="icon"/>
                            <span className="fds-p--l-2">
                                {text}
                            </span>
                        </span>
    </li>
}

export const states = [
    'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
    'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
    'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
    'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
    'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY']

export function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

export function vehicleDescription(vehicleInfo){
    return `${vehicleInfo?.modelYear} ${vehicleInfo?.modelName}`
}

export function noSpaceLowerCase(inputString){
    return inputString.toLowerCase().replaceAll(" ", "")
}

export function wait(milliseconds){
    return new Promise(resolve=>{
        setTimeout(resolve,milliseconds);
    });
}

export const getDynamicCardStyle = (width) => {
    const fixed =  "fmc-cards__card fmc-cards__card--horizontal "
    const variable = width < 630 ? " fds-layout-grid__cell--span-4 " : " fds-layout-grid__cell--span-3 "
    return fixed + variable
}

export const getFormattedDate = (inputDate)=>{
    inputDate = new Date(inputDate)
    return  months[inputDate.getMonth()] + " " + inputDate.getDate() + "th " + inputDate.getFullYear()
}