import {ENROLLED, INVITED, NOT_INVITED, WAITLISTED} from "../../Constants/Status";
import classNames from "classnames/bind";
import FinalizeEnrollmentCss from "./FinalizeEnrollment.module.css";

const VinCheckBox = ({
                         style = ENROLLED,
                         content = ""
                     }) => {

    let classes = classNames.bind(FinalizeEnrollmentCss)

    let classList = classes(
        FinalizeEnrollmentCss.VinCheckBoxStyle,
        style === ENROLLED ? FinalizeEnrollmentCss.ENROLLED : null,
        style === WAITLISTED ? FinalizeEnrollmentCss.WAITLISTED : null,
        style === INVITED ? FinalizeEnrollmentCss.INVITED : null,
        style === NOT_INVITED ? FinalizeEnrollmentCss.NOT_INVITED : null
    )

    return (
        <span className={classList}>
            {content}
        </span>

    )

}
export default VinCheckBox;