import {ENROLLED, INVITED, NOT_INVITED} from "./Status";
import {createStore} from "redux";
import userDataReducer from "../redux/Reducers/UserDataReducer";

export const loremEpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etl"

export const defaultProductSubtitle = "The Future of Driving"
export const enrollWaitListProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "userId": "sasfaw@altimetrik.com",
                "firstName": "Surafel",
                "lastName": "Asfaw",
                "email": "sasfaw@altimetrik.com",
                "userGuid": "1C019C2D-3EA7-4462-9B73-37B2060D895C",
                "consumerType": "Personal",
                "isEapUser": "active",
                "country": "USA",
                "joinedOn": "2021-07-21T03:55:00.56Z",
                "vehicleInfo": [
                    {
                        "vin": "3FMTK3R76MMA01225",
                        "make": "F",
                        "modelName": "Mustang Mach-E",
                        "modelYear": 2021,
                        "status": NOT_INVITED
                    },
                    {
                        "vin": "1FTEW1C50MFA29518",
                        "make": "F",
                        "modelName": "F-150",
                        "modelYear": 2021,
                        "status": INVITED
                    }
                ],
                "productInfo": [],
                "invites": [
                    {
                        "inviteId": 54,
                        "productId": 1,
                        "productName": "Intelligent Charging",
                        "vin": "1FTEW1C50MFA29518",
                        "expires": "2024-06-23T19:27:11.297Z",
                        "createdOn": "2021-07-09T19:27:11.297Z",
                        "updatedOn": "2021-07-09T19:27:11.297Z",
                        "userGuid": null,
                        "invitedBy": "SYSTEM"
                    },
                    {
                        "inviteId": 56,
                        "productId": 0,
                        "productName": null,
                        "vin": "3FMTK3R76MMA01225",
                        "expires": "2024-08-04T15:10:31.327Z",
                        "createdOn": "2021-08-20T15:10:31.327Z",
                        "updatedOn": "2021-08-20T15:10:31.327Z",
                        "userGuid": null,
                        "invitedBy": ""
                    }
                ],
                "survey": {
                    "status": "NOT_COMPLETE",
                    "url": "https://collect.iperceptions.com/?lID=1&rn=129779&vm=1&pID=5&hs1=102214&hs2=102226&uni=1&siteID=1&am=true&referrer=Link&sdfc=355b766d-129779-c733c77b-141a-42d7-be9f-2e52b426622b&source=102226&hv1=EAPID~21"
                },
                "genericSurveys": []
            },
            "userVehicles": [
                {
                    "vin": "3FMTK3R76MMA01225",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": NOT_INVITED
                },
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED
                }
            ],
            "userInvites": [
                {
                    "inviteId": 54,
                    "productId": 1,
                    "productName": "Intelligent Charging",
                    "vin": "1FTEW1C50MFA29518",
                    "expires": "2024-06-23T19:27:11.297Z",
                    "createdOn": "2021-07-09T19:27:11.297Z",
                    "updatedOn": "2021-07-09T19:27:11.297Z",
                    "userGuid": null,
                    "invitedBy": "SYSTEM"
                },
                {
                    "inviteId": 56,
                    "productId": 0,
                    "productName": null,
                    "vin": "3FMTK3R76MMA01225",
                    "expires": "2024-08-04T15:10:31.327Z",
                    "createdOn": "2021-08-20T15:10:31.327Z",
                    "updatedOn": "2021-08-20T15:10:31.327Z",
                    "userGuid": null,
                    "invitedBy": ""
                }
            ],
            "userInvitedProducts": [],
            "enrolledProducts": [
                {
                    "productId": 6,
                    "productName": "BlueCruise",
                    "unenrollSteps": null,
                    "unenrollStepsActivated": null,
                    "unenrollStepsNotActivated": null,
                    "surveyUrl": "https://collect.iperceptions.com/?lID=1&rn=129779&vm=1&pID=5&hs1=102214&hs2=102226&uni=1&siteID=1&am=true&referrer=Link&sdfc=355b766d-129779-c733c77b-141a-42d7-be9f-2e52b426622b&source=102226&hv1=EAPID~21",
                    "surveyStatus": "NOT_COMPLETE"
                }
            ],
            "enrolledVehicles": [
                {
                    "userVehicleId": 26,
                    "productId": null,
                    "createdOn": "2021-07-21T03:55:01.91Z",
                    "vin": "3FMTK3R76MMA01225",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": "ENROLLED",
                    "enrolledProducts": [
                        {
                            "productId": 6,
                            "productName": "BlueCruise",
                            "productDescription": null,
                            "productUnenrollSteps": null,
                            "productStatus": "active",
                            "productUnenrollStepsActivated": null,
                            "productUnenrollStepsNotActivated": null,
                            "vehicleUnenrollStepsActivated": null,
                            "vehicleUnenrollStepsNotActivated": null
                        }
                    ]
                }
            ],
            "availableProduct": [],
            "genericSurvey": []
        }
    }
}
export const invalidGarageRefreshProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "userId": "hilogi5013@duiter.com",
                "firstName": "MrG",
                "lastName": "Test",
                "email": "hilogi5013@duiter.com",
                "userGuid": "2D4E8DA4-041A-405E-8EA7-2D60B72629AB",
                "consumerType": "Personal",
                "isEapUser": "waitlisted",
                "country": "USA",
                "joinedOn": null,
                "questionnaireCompleted": true,
                "vehicleInfo": [],
                "garageRefresh": [
                    {
                        "vin": "1FTBW9CK7NKA00081",
                        "make": "F",
                        "modelName": null,
                        "modelYear": null,
                        "status": "WAITLISTED",
                        "conflict": false,
                        "consents": []
                    }
                ],
                "genericSurveys": []
            },
            "userVehicles": [],
            "userInvites": [],
            "userInvitedProducts": [],
            "enrolledProducts": [],
            "enrolledVehicles": [],
            "availableProduct": [],
            "genericSurvey": []

        }
    }
}
export const conflictVehicleProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "userId": "bjhh56@816qs.com",
                "firstName": "John",
                "lastName": "Doe",
                "email": "bjhh56@816qs.com",
                "userGuid": "7218A77A-98E9-4A3B-88F5-D858899F936F",
                "consumerType": "Personal",
                "isEapUser": null,
                "country": "USA",
                "joinedOn": null,
                "questionnaireCompleted": false,
                "vehicleInfo": [
                    {
                        "vin": "1FTBW9CK7NKA00081",
                        "make": "F",
                        "modelName": "Transit",
                        "modelYear": 2022,
                        "status": "NOT_INVITED",
                        "conflict": true,
                        "consents": []
                    }
                ],
                "garageRefresh": null,
                "genericSurveys": null
            },
            "userVehicles": [
                {
                    "vin": "1FTBW9CK7NKA00081",
                    "make": "F",
                    "modelName": "Transit",
                    "modelYear": 2022,
                    "status": "NOT_INVITED",
                    "conflict": true,
                    "consents": []
                }
            ],
            "userInvites": [],
            "userInvitedProducts": [],
            "enrolledProducts": [],
            "enrolledVehicles": [],
            "availableProduct": [],
            "genericSurvey": []

        }
    }
}
export const activeInactiveProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "userId": "sasfaw@altimetrik.com",
                "firstName": "Surafel",
                "lastName": "Asfaw",
                "email": "sasfaw@altimetrik.com",
                "userGuid": "1C019C2D-3EA7-4462-9B73-37B2060D895C",
                "consumerType": "Personal",
                "isEapUser": "active",
                "country": "USA",
                "joinedOn": "2021-07-21T03:55:00.56Z",
                "vehicleInfo": [
                    {
                        "vin": "WF0TK3SU6MMA00110",
                        "make": "F",
                        "modelName": "Mustang Mach-E",
                        "modelYear": 2021,
                        "status": "ENROLLED",
                        "consents": [
                            {
                                "name": "eapTerms",
                                "status": false
                            },
                            {
                                "name": "fpTerms",
                                "status": true
                            }
                        ]
                    },
                    {
                        "vin": "1FTEW1C50MFA29518",
                        "make": "F",
                        "modelName": "F-150",
                        "modelYear": 2021,
                        "status": INVITED,
                        "consents": []
                    }
                ],
                "productInfo": [],
                "invites": [],
                "survey": {
                    "status": "NOT_COMPLETE",
                    "url": "https://collect.iperceptions.com/?lID=1&rn=129779&vm=1&pID=5&hs1=102214&hs2=102226&uni=1&siteID=1&am=true&referrer=Link&sdfc=355b766d-129779-c733c77b-141a-42d7-be9f-2e52b426622b&source=102226&hv1=EAPID~21"
                },
                "genericSurveys": []
            },
            "userVehicles": [
                {
                    "vin": "WF0TK3SU6MMA00110",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": "ENROLLED",
                    "consents": [
                        {
                            "name": "eapTerms",
                            "status": false
                        },
                        {
                            "name": "fpTerms",
                            "status": true
                        }
                    ]
                },
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED,
                    "consents": []
                }
            ],
            "userInvites": [],
            "userInvitedProducts": [],
            "enrolledProducts": [],
            "enrolledVehicles": [],
            "availableProduct": [],
            "genericSurvey": []
        }
    }
}

export const consentUpdatedProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "userId": "sasfaw@altimetrik.com",
                "firstName": "Surafel",
                "lastName": "Asfaw",
                "email": "sasfaw@altimetrik.com",
                "userGuid": "1C019C2D-3EA7-4462-9B73-37B2060D895C",
                "consumerType": "Personal",
                "isEapUser": "active",
                "country": "USA",
                "questionnaireCompleted":true,
                "joinedOn": "2021-07-21T03:55:00.56Z",
                "vehicleInfo": [
                    {
                        "vin": "WF0TK3SU6MMA00110",
                        "make": "F",
                        "modelName": "Mustang Mach-E",
                        "modelYear": 2021,
                        "status": "ENROLLED",
                        "consents": [
                            {
                                "name": "eapTerms",
                                "status": false
                            },
                            {
                                "name": "fpTerms",
                                "status": true
                            }
                        ]
                    },
                    {
                        "vin": "1FTEW1C50MFA29518",
                        "make": "F",
                        "modelName": "F-150",
                        "modelYear": 2021,
                        "status": INVITED,
                        "consents": []
                    }
                ],
                "productInfo": [],
                "invites": [],
                "survey": {
                    "status": "NOT_COMPLETE",
                    "url": "https://collect.iperceptions.com/?lID=1&rn=129779&vm=1&pID=5&hs1=102214&hs2=102226&uni=1&siteID=1&am=true&referrer=Link&sdfc=355b766d-129779-c733c77b-141a-42d7-be9f-2e52b426622b&source=102226&hv1=EAPID~21"
                },
                "genericSurveys": []
            },
            "userVehicles": [
                {
                    "vin": "WF0TK3SU6MMA00110",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": "ENROLLED",
                    "consents": [
                        {
                            "name": "eapTerms",
                            "status": false
                        },
                        {
                            "name": "fpTerms",
                            "status": true
                        }
                    ]
                },
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED,
                    "consents": []
                }
            ],
            "userInvites": [],
            "userInvitedProducts": [],
            "enrolledProducts": [],
            "enrolledVehicles": [],
            "availableProduct": [],
            "genericSurvey": []
        }
    }
}

export const invalidCountryProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "country": "CAN",
                "email": "abc@gmail.com"
            },
            "userVehicles": [
                {
                    "vin": "3FMTK3R76MMA01225",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": NOT_INVITED
                },
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED
                }
            ],
        }
    }


}

export const inValidEmailProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "country": "USA",
                "email": ""
            },
            "userVehicles": [
                {
                    "vin": "3FMTK3R76MMA01225",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": NOT_INVITED
                },
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED
                }
            ],
        }
    }
}

export const accountProp = {
    userDataReducer: {
        user: {
            "isAuthenticated": true,
            "userProfile": {
                "userId": "mickias6@gmail.com",
                "firstName": "MICKIAS",
                "lastName": "HAILEMARIAM",
                "email": "mickias6@gmail.com",
                "userGuid": "EA2B4277-25AD-499A-967C-861FF5129F13",
                "consumerType": "Personal",
                "isEapUser": "active",
                "country": "USA",
                "joinedOn": "2022-08-29T12:50:07.627-04:00",
            },
            "userVehicles": [
                {
                    "vin": "3FMTK3SU5MMA00096",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": "ENROLLED",
                    "consents": [
                        {
                            "name": "EAPTerms",
                            "status": true
                        },
                        {
                            "name": "FordPassTerms",
                            "status": true
                        }
                    ]
                },
                {
                    "vin": "WF0TK3SU6MMA00110",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": "ENROLLED",
                    "consents": [
                        {
                            "name": "EAPTerms",
                            "status": true
                        },
                        {
                            "name": "FordPassTerms",
                            "status": true
                        }
                    ]
                }
            ],
        }
    }

}

export const enrolledVehiclesProp = {
    ...inValidEmailProp,
    vehicleReducer: {
        enrolledVehicles: {
            enrolledVehicles: [
                {
                    "vin": "3FMTK3R76MMA01225",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": NOT_INVITED
                },
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED
                }
            ]
        }
    }
}

export const enrolledVehiclesProps = {
    ...accountProp,
    vehicleReducer: {
        enrolledVehicles: {
            enrolledVehicles: [
                {
                    "vin": "3FMTK3SU5MMA00096",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": "ENROLLED",
                    "enrolledProducts": []
                },
                {
                    "vin": "WF0TK3SU6MMA00110",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": "ENROLLED",
                    "enrolledProducts": []
                }
            ],
        },
    },
    productReducer: {
        availableProducts: [],
        enrolledProducts:[{
            "productId": 9,
            "productName": "Electrify America",
            "description": "Electrify America",
            "endDate": "2025-07-20T19:38:58.000+00:00",
            "unenrollSteps": "We’re sorry to see you go!",
            "unenrollStepsActivated": null,
            "unenrollStepsNotActivated": null,
            "surveyUrl": null,
            "surveyStatus": "NO_ACTIVE_SURVEY"
        }]

    },

}

export const invitedVehicleProp = {
    ...inValidEmailProp,
    vehicleReducer: {
        enrolledVehicles: {
            enrolledVehicles: [
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED
                }
            ]
        }
    }
}


export const enrolledAndWaitListedVehicleProp = {
    ...inValidEmailProp,
    vehicleReducer: {
        enrolledVehicles: {
            enrolledVehicles: [
                {
                    "vin": "1FTEW1C50MFA29518",
                    "make": "F",
                    "modelName": "F-150",
                    "modelYear": 2021,
                    "status": INVITED
                },

                {
                    "vin": "WF0TK3SU6MMA00110",
                    "make": "F",
                    "modelName": "Mustang Mach-E",
                    "modelYear": 2021,
                    "status": ENROLLED
                }
            ]
        }
    }
}

export const enrollWaitlistStore = createStore(userDataReducer, enrollWaitListProp)
export const invalidEmailStore = createStore(userDataReducer, inValidEmailProp)
export const accountStore = createStore(userDataReducer, accountProp)
export const invalidCountryStore = createStore(userDataReducer, invalidCountryProp)
export const activeInactiveStore = createStore(userDataReducer, activeInactiveProp)
export const updateConsentStore = createStore(userDataReducer, consentUpdatedProp)
export const enrolledVehiclesStore = createStore(userDataReducer, enrolledVehiclesProp)
export const enrolledVehiclesStores = createStore(userDataReducer, enrolledVehiclesProps)
export const invitedVehicleStore = createStore(userDataReducer, invitedVehicleProp)
export const autoEnrollStore = createStore(userDataReducer, enrolledAndWaitListedVehicleProp)
export const invalidGarageRefreshStore = createStore(userDataReducer, invalidGarageRefreshProp)
export const conflictVehicleStore = createStore(userDataReducer, conflictVehicleProp)
