import ExpressInterestSignUpCss from "../../../modules/expressInterest/ExpressInterestSignUp/ExpressInterestSignUp.module.css";

function ModelContent(props) {

    return(
        <div className={ExpressInterestSignUpCss.modalScroll}>
            <div className="fds-p--t-1" aria-label={props.text}>
                <p data-testid={"modelContentText"}>{props.text}</p>
                <br/>
            </div>
        </div>
    )

}
export default ModelContent
