import React, {useEffect, useRef} from "react";
import {FMCAccordion} from '@ford/ford-design-system';


const Accordion  =({
        accordionTitle = "Sample Title",
        accordionContent = "Sample Content"
    })=>{

    const tabsRef = useRef(null);
    useEffect(() =>  new FMCAccordion(tabsRef.current), [])

    return (
        <div ref={tabsRef}
            className="js-fmc-accordion fmc-accordion fds-color__bg--light fmc-accordion--light">
            <div className="fmc-accordion__panel">
                <button type="button" className="fmc-accordion__button" id="panel-1-button"
                        aria-controls="panel-1-content">

                    <span className="fmc-accordion__button-title" data-testid="accordionTitle">{accordionTitle}</span>

                    <span className="fmc-accordion__button-expand fds-icon"/>
                </button>
                <div className="fmc-accordion__body">
                    <div
                        className="fmc-accordion__content"
                        aria-labelledby="panel-1-button"
                        id="panel-1-content"
                        role="region"
                        aria-hidden="false"
                    >
                        <p className="fmc-type--body1" data-testid="accordionContent">
                            {accordionContent}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Accordion