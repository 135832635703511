import React from "react";
import eligibility from "assets/Car.svg";
import participation from "assets/User.svg";
import benefits from "assets/star.svg";
import HomeModulePart2CSS from "./HomeText.module.css";
import {useHistory} from "react-router-dom";

export default function HomeText() {

    const history = useHistory()

    const handleExpressInterest = () => {
        history.push("/expressInterest")
    }

    const stepsToEnroll = [
        {
            header: "Eligibility",
            logo: eligibility,
            desc:
                <div>You must be 18 years or older and <br/> drive a Ford vehicle capable of software
                    updates <br/> within the U.S. </div>,
            alt: "eligibility",
        },
        {
            header: "Participation",
            logo: participation,
            desc: <div>Complete surveys and give essential <br/>feedback that will help us shape the <br/> future of
                Ford Motor Company </div>,
            alt: "participation",
        },
        {
            header: "Benefits",
            logo: benefits,
            desc:
                <div>You’ll influence the design of <br/>products and experiences for<br/> all Ford vehicles now
                    and<br/> into the future</div>,
            alt: "",
        },
    ];

    const middleContent = <div className={HomeModulePart2CSS.middleContent}>
        Early Access members are part of an engaged community of Ford owners who get early access to software updates
        and sneak peeks at <br/>
        beta-level software in exchange for giving feedback. The insights and opinions they share are essential to
        developing and improving <br/>
        Ford products.If you decide to join our community of early adopters, we simply ask for your active participation
        and <br/>that you follow our guidelines.
    </div>
    return (
        <div className={HomeModulePart2CSS.middleContentContainer}>
            <div className={HomeModulePart2CSS.noPadding + " fds-layout-grid"}>
                <div className="fds-align--center">
                    <h3 className={HomeModulePart2CSS.middleContentTitle}>
                        Introducing the Early Access Program
                    </h3>
                    {middleContent}

                </div>
                <div className={HomeModulePart2CSS.desktopView + " fds-p--b-3"}/>
            </div>

            <div className={" fds-color__bg--lt-gray fds-layout-grid"}>
                <div className={HomeModulePart2CSS.carouselHeader}>
                    <div className=" fds-layout-grid__inner fds-p--b-3">
                        {stepsToEnroll.map((step, idx) => {
                            return (
                                <div className="fmc-cards__card  fds-layout-grid__cell">
                                    <div className="fmc-cards__content">
                                        <div className="fmc-cards__footer" style={{borderTop: "none", paddingBottom:"3rem"}}>
                                            <div

                                                className={
                                                    HomeModulePart2CSS.colStyles +
                                                    " fds-align--center fds-layout-grid__cell--span-4 fds-p--x-1"
                                                }
                                            >
                                                <img
                                                    className={HomeModulePart2CSS.imageView + " fds-p--y-3"}
                                                    src={step.logo}
                                                    alt={step.alt}
                                                />
                                                <h3 data-testid={step.header + `CardHeader`}
                                                    className={HomeModulePart2CSS.header}>{step.header}</h3>
                                                <div data-testid={step.header + `Description`}
                                                     className={HomeModulePart2CSS.description}>
                                                    {step.desc}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="fds-align--center fds-m--b-3">
                <button className="fmc-button" onClick={handleExpressInterest}
                        data-testid="expressInterestFooterBtn">Express Interest

                </button>

            </div>
        </div>
    );

}

