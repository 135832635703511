import React, {useEffect, useState} from "react";
import ExpressInterestQuestionnaire from "./ExpressInterestQuestionnaire/ExpressInterestQuestionnaire";
import ExpressInterestSignUp from "./ExpressInterestSignUp/ExpressInterestSignUp";
import ExpressInterestOverview from "./ExpressInterestOverView/ExpressInterestOverview";
import expressInterestCss from "./ExpressInterest.module.css"
import {connect} from "react-redux";
import {ENROLLED, WAITLISTED} from "../../Constants/Status";
import {useHistory} from "react-router-dom";

function ExpressInterest(props) {
    const history = useHistory();

    let vehicleList =  props?.user?.userVehicles || []
    let questionnaireAnswered = props?.user?.questionnaireCompleted
    vehicleList = vehicleList.filter((items=>(items.status !== ENROLLED && items.status!== WAITLISTED)))
    if (questionnaireAnswered){
        history.push("/account")
    }

    const [selectedVehicleList, setSelectedVehicleList] = useState([])
    const [width, setWidth] = useState(window.innerWidth);
    const [currentStep, setCurrentStep] = useState("Overview")
    const [completedSteps, setCompletedSteps] = useState([])
    const [sync , setSync] = useState(false)
    let [selectedValues, setSelectedValues] = useState({
        "question1": "",
        "question2": "",
        "question3": "",
        "question4a": "",
        "question4b": "",
        "question4c": "",
        "question5": "",
        "question6": "",
        "question7": "",
        "question8a": "",
        "question8b": "",
        "question8c": "",
        "question9": "Yes, I am in"
    })

    useEffect( () => {
        let list = []
        vehicleList.map( items => {
            return list.push(items.vin)
        } )
        setSelectedVehicleList(list)
    },[] )


    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    })

    const handleVinSelect = (e)=>{
        let {id: name} = e.target;

        if(selectedVehicleList.includes(name)){
            let updatedList = selectedVehicleList.filter(items => items !== name)
            setSelectedVehicleList(updatedList)
        }else{
            let list = selectedVehicleList
            list.push(name)
            setSelectedVehicleList(list)
            setSync(!sync)
        }

    }

    const handleChange = (e) => {
        let {name, value} = e.target;
        if(name === "question6" ) {
            if(value !== "Other(please specify)") delete selectedValues["otherGender"]
            else selectedValues["otherGender"] = ""
        }

        if(name === "question7" || name === "question8c") value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')

        selectedValues[name] = value
        setSelectedValues(selectedValues)
        setSync(!sync)
    }

    const panelLabels = ["Overview", "Questionnaire", "Sign Up"]

    let getList = (label) => {
        let buttonStyle = "fmc-tracker__dot"
        if(currentStep === label) buttonStyle += " fmc-tracker__dot--active"
        else if(completedSteps.includes(label)) buttonStyle += " fmc-tracker__dot--success"

        let buttonContent = <span> {panelLabels.indexOf(label)+1}/3 </span>
        if(currentStep !== label && completedSteps.includes(label)) buttonContent = <span className="fds-icon fds-font--ford-icons__success"/>

        let listStyle = "fmc-tracker__step"
        if(completedSteps.includes(label)) listStyle += " fmc-tracker__step--complete"

        return (<li className={width < 650? listStyle : {}} key={`${label}_${currentStep === label}`}>
            <div style={width < 650 ? null : {display: "flex", justifyContent: "space-between"}}>
            <button
                className={buttonStyle}
                aria-label="Lorem Ipsum"
                name={label}
            >
                {buttonContent}
            </button>
            <p style={width < 650 ? null : {padding: "1em 0 1em 0.5em" }} className={width < 650 ? "fmc-tracker__title": ""}>{label}</p>
        </div>
        </li>)
    }

    const handleContinue = (currentPage) => {
        completedSteps.push(currentPage)
        let currentIdx = panelLabels.indexOf(currentPage)
        setCurrentStep(panelLabels[currentIdx+1])
        setCompletedSteps(completedSteps)
        window.scrollTo(0, 0)
    }

    const handlePrevious = (currentPage) => {
        let newSteps = completedSteps.filter(items => items !== currentPage)
        let currentIdx = panelLabels.indexOf(currentPage)
        setCurrentStep(panelLabels[currentIdx-1])
        setCompletedSteps(newSteps)
        window.scrollTo(0, 0)
    }

    const getPanelContent = () => {
        switch (currentStep){
            case "Overview":
                return <ExpressInterestOverview onContinue={handleContinue} width={width}/>

            case "Questionnaire":
                return <ExpressInterestQuestionnaire onContinue={handleContinue}
                                                     onPrevious={handlePrevious}
                                                     onChange={handleChange}
                                                     selectedValues={selectedValues}
                                                     width={width}
                />

            case "Sign Up":
                return <ExpressInterestSignUp onChange={handleChange}
                                              selectedValues={selectedValues}
                                              selectedVehicleList = {selectedVehicleList}
                                              onVinSelect = {handleVinSelect}
                                              onPrevious={handlePrevious}
                                              width={width}/>

            default:
        }
    }

    const desktopVersion =  <div className={expressInterestCss.panelContainer}>
        <ul className="fmc-tracker" style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", paddingTop: "70px", width: "20%" }} >
            {panelLabels.map((items, i) => {
                return (<div key={`${items}_${i}`}>
                    {getList(items)}
                    { i < panelLabels.length-1 ? <div className={expressInterestCss.vl}/> : null}
                </div>)
            })}
        </ul>

        <div className={expressInterestCss.vlPage}/>
        <div className={expressInterestCss.rightPanel}>
            {getPanelContent()}
        </div>
    </div>

    const mobileVersion =  <div>
        <div style={ {margin : "2em", padding:"0 2em 0 2em"} }>
            <ul className="fmc-tracker"  >
                {panelLabels.map((items) => {
                    return getList(items)
                })}
            </ul>
        </div>
        <hr className={expressInterestCss.mobileTopHr} />
        <div>
            {getPanelContent()}
        </div>
    </div>


    return (
        <div className="fds-app" >

            {width >= 630 ? desktopVersion : mobileVersion}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user,
    };
};
export default  connect(mapStateToProps)(ExpressInterest);
