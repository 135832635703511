import React, {useEffect, useState} from "react";
import EIQuestionnaireCss
    from "../expressInterest/ExpressInterestQuestionnaire/ExpressInterestQuestionnaire.module.css";
import InputMask from "react-input-mask";
import {states} from "../../util/Format";
import AccountModuleCss from "./Account.module.css";

const EditProfile = (props)=>{

    const options = {
        "binary": ["Yes", "No"],
        "age": ["18-24", "25-35", "36-44", "45-54", "55-64", "65 or older", "Prefer not to say"],
        "gender": ["Woman", "Man", "Non-binary", "Transgender", "Prefer not to say", "Other(please specify)"],
    }


    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    })

    return(
        <div className={AccountModuleCss.editFormContainer}>
            <div className="fds-p--t-3" style={width <= 650 ? {padding: "0 40px 0 40px"} : {}} data-testid="outerDiv">

                <div className={EIQuestionnaireCss.questionDiv}>
                    <fieldset className="fmc-check">
                        <legend className={EIQuestionnaireCss.questionStyle}>
                           5 . What is your age?
                        </legend>
                        {options["age"].map((items, i) => {
                            return (<label key={`${items}_${i}`}>
                                <input type="radio" name="question5" value={items} data-testid={`question5_${items}`}
                                       onChange={props.onChange} checked={props.formValue["question5"] === items}/>
                                <span className={EIQuestionnaireCss.optionsStyle}> {items} </span>
                            </label>)
                        })
                        }
                    </fieldset>
                </div>
                <br/>
                <div className={EIQuestionnaireCss.questionDiv}>
                    <fieldset className="fmc-check">
                        <legend className={EIQuestionnaireCss.questionStyle}>
                            6. Which of the following best describes your gender identity?
                        </legend>
                        {options["gender"].map((items, i) => {
                            return (<label key={`${items}_${i}`}>
                                <input type="radio"
                                       name="question6"
                                       data-testId={`question6_${items}`}
                                       value={items}
                                       onChange={props.onChange}
                                       checked={props.formValue["question6"] === items}/>
                                <span className={EIQuestionnaireCss.optionsStyle}> {items} </span>

                                {items === "Other(please specify)" &&
                                    props.formValue["question6"] === "Other(please specify)" &&
                                    width > 650 &&
                                    <input type="text"
                                           onChange={props.onChange}
                                           style={width > 650 ? {width: "15%", height: "85%"} : {}}
                                           placeholder="other"
                                           name="otherGender"
                                           maxLength={30}
                                           value={props.formValue["otherGender"]}/>
                                }
                            </label>)
                        })}
                    </fieldset>
                    {
                        props.formValue["question6"] === "Other(please specify)" &&
                        width < 650 &&
                        <input type="text"
                               onChange={props.onChange}
                               style={width > 650 ? {width: "15%", height: "85%"} : {}}
                               name="otherGender"
                               maxLength={30}
                               value={props.formValue["otherGender"]}/>
                    }
                </div>
                <div className={EIQuestionnaireCss.questionDiv}>
                    <fieldset>
                        <legend className={EIQuestionnaireCss.questionStyle}>
                            7. What is your phone number?
                            <br/>
                            <div className={EIQuestionnaireCss.phoneNote}>
                                * NOTE: The phone number you provide will only be used in the event we need to reach you
                                to
                                provide support and resolve issues quicker. Message and data rates may apply.
                            </div>
                        </legend>
                        <label>
                            <InputMask
                                className="fmc-input"
                                type="tel"
                                mask="(999) 999-9999"
                                placeholder="Phone number"
                                name="question7"
                                data-testid={"question7"}
                                onChange={props.onChange}
                                value={props.formValue["question7"]}
                            />
                        </label>
                    </fieldset>
                </div>
                <div className={EIQuestionnaireCss.questionDiv}>
                    <fieldset>
                        <legend className={EIQuestionnaireCss.questionStyle}>
                            8. Where are you located?
                        </legend>
                        <label>
                            <div style={width > 650 ? {
                                display: "flex",
                                justifyContent: "spaceBetween",
                                width: "600px"
                            } : {}}>
                                <input className="fmc-input fds-m--r-1" type="text" onChange={props.onChange}
                                       placeholder="City" name="question8a" value={props.formValue["question8a"]}
                                       data-testid={"question8a"}/>
                                <select name="question8b" value={props.formValue["question8b"]}
                                        data-testid={"question8b"}
                                        className={width > 650 ? "fmc-select fds-m--l-1 fds-m--r-1" : "fmc-select fds-m--t-3 fds-m--b-3"}
                                        onChange={props.onChange}>
                                    {states.map((items, i) => {
                                        <option value="" style={{color: "#4D4D4D"}}> State</option>
                                        return <option key={`${items}_${i}`} data-testid={`${items}_options`}
                                                       value={items}>{items}</option>
                                    })}
                                </select>

                                <input className={width > 650 ? "fmc-input fds-m--l-1" : "fmc-input fds-m--t-3"}
                                       type="text"
                                       onChange={props.onChange}
                                       maxLength={5}
                                       minLength={5}
                                       placeholder="Zip code"
                                       name="question8c"
                                       data-testid={"question8c"}
                                       value={props.formValue["question8c"]}/>
                            </div>
                        </label>

                    </fieldset>
                </div>
                <div className={AccountModuleCss.saveAndCancelBtnContainer}>
                    <button className="fmc-button"
                            onClick={() => props.handleSubmit()}
                            data-testid={"goToSignUp"}
                            disabled={
                                Object.keys(props.formValue)
                                    .filter(items => props.formValue[items] === "").length > 0 || (Object.keys(props.formValue["question7"]).length < 10) || props.formValue["question8c"].length < 5}>
                        Save
                    </button>

                    <button className="fmc-text-button"
                            data-testid="goBackQuesBtn"
                            onClick={() => props.handleCancel()}
                    >
                        Cancel
                    </button>
            </div>
        </div>
        </div>
    )
}
export default EditProfile;