export const USER_DATA_REQUEST = "FETCH_USER_DATA";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_ERROR = "USER_DATA_ERROR";

export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";

export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";
export const VEHICLES_ERROR = "VEHICLES_ERROR";


export const BLUE_CRUISE_SIGNUP_REQUEST = "BLUE_CRUISE_SIGNUP_REQUEST";
export const BLUE_CRUISE_SIGNUP_SUCCESS = "BLUE_CRUISE_SIGNUP_SUCCESS";
export const BLUE_CRUISE_SIGNUP_ERROR = "BLUE_CRUISE_SIGNUP_ERROR";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
