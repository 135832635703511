import ReactSecondaryButton from "../../../assets/js/ReactSecondaryButton";
import React from "react";


function ModelCloseButton(props) {
    return(
        <ReactSecondaryButton
            style={{ float: "right", padding: "10px" }}
            colorScheme="light"
            onClick={props.onClick}
            data-testid="close"
        >
            <span className="fds-secondary-button__icon-wrapper fds-secondary-button__icon-left">
                <span className="fds-icon fds-icon fds-font--ford-icons__clear"
                      aria-label="close"
                      tabIndex={1}/>
            </span>
        </ReactSecondaryButton>
    )
}
export default ModelCloseButton
