import fetchOptions from "./fetchOptions";
import store from "../redux/store/userStore";
import {errorVehicles} from "../redux/Actions/UserDataActions";

export const vehicleService = {

    getVehiclesForProduct: async (productId) => {
        let enrolledVehicles = null;

        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/vehicle/product/${productId}/enroll`,
            fetchOptions("GET")
        );

        const data = await response.json();

        if (response.ok) {
            enrolledVehicles = data
        } else {
            store.dispatch(errorVehicles(data));
            throw new Error(data);
        }

        return enrolledVehicles;
    },

    getVehicleImage: async (vin,modelYear) => {

        if (vin !== null && modelYear !== null ) {
            return await
                fetch(`${process.env.REACT_APP_BACKEND_URL}/vehicle/image/${vin}/5/${modelYear}`, {
                    method: "GET",
                    headers: {
                        'Accept': 'image/png',
                        "Content-Type": "image/png",
                        "auth-token": window?.fma?.["CATBundle"]?.["access_token"] ?? null,
                        "application-id": process.env.REACT_APP_APPLICATION_ID,
                    }
                })
        }
    },
};