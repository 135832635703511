import React from "react";

const ReactHorizontalCard = ({
                                 cardImage = null,
                                 cardAlert = null,
                                 cardAlertStyle = null,
                                 children,
                                 cardStyle = null,
                                 imageStyle = null,
                                 cardClassName = "fmc-cards__card  fds-layout-grid__cell "
                             }) => {
    return (
        <div
            className={cardClassName}
            style={cardStyle}
        >
            {
                cardImage !== null && <div className="fds-cards__media-area">
                    <img
                        className={"fds-cards__image " + imageStyle}
                        style={{objectFit: "contain"}}
                        src={
                            "https://build.ford.com/dig/direct/HD-THUMB/Vin[" +
                            cardImage +
                            "]/EXT/4/vehicle.png"
                        }
                        alt=""
                    />
                    {cardAlert && <div className={"fds-cards__alert fds-cards__alert--" + cardAlertStyle}>
                        {cardAlert}
                    </div>}
                </div>
            }

            <div className="fmc-cards__content-wrapper">
                <div className="fmc-cards__content" style={{padding:"15px"}}>
                        {children}

                </div>
            </div>
        </div>
    );
};
export default ReactHorizontalCard;