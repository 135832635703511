import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/userStore";
import "./App.css";
import Header from "components/common/Header/Header";
import AppRouter from "./routes/AppRouter";
import GlobalErrorHandler from "./components/ErrorHandler/GlobalErrorHandler/GlobalErrorHandler";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Header />
        </Switch>
        <GlobalErrorHandler />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <main>
              <AppRouter />
            </main>
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
