import React from "react";
import classNames from "classnames";

export default function ReactTopBannerNotification({
  onCloseMethod = null,
  show = true,
  notificationType = "dismissible",
  notificationStyle = "error",
  notificationMessage = "Something went wrong. Please try again later.",
    customBannerButton = false
}) {
  const classList =customBannerButton?  classNames(
      "js-fds-primary-button fds-primary-button",
      `fds-banner-notification__type--${notificationType}`,
      `fds-banner-notification__style--${notificationStyle}`,
      { "fds-banner-notification--show": show },
      { "fds-banner-notification--hide": !show }
  ): classNames(
    "js-fds-primary-button fds-primary-button",
      "fds-banner-notification",
    `fds-banner-notification__type--${notificationType}`,
    `fds-banner-notification__style--${notificationStyle}`,
    { "fds-banner-notification--show": show },
    { "fds-banner-notification--hide": !show }
  );
  return (
    <div className="fmc-top-banner fmc-top-banner--persistent fmc-top-banner--error">
      <div className="fmc-top-banner__content">
        <div
          aria-live="assertive"
          aria-atomic="true"
          className="fds-banner-notification__section__type--persistent"
        />
      </div>
      <div className="fds-banner-notification__section">
        <div
          aria-live="assertive"
          aria-atomic="true"
          className="fds-banner-notification__section__type--dismissible"
        >
          <div id="FDSDismissibleErrorNotification" className={classList}>
            <div className={"fds-banner-notification__wrapper"} tabIndex="0">
              <span className="fds-banner-notification__content">
                <span className="fds-banner-notification__text">
                  {notificationMessage}
                </span>
              </span>
            </div>
            {
              !customBannerButton && <button
              className="fds-banner-notification__close-button fds-font--ford-icons__clear fds-icon"
              onClick={onCloseMethod}
              aria-label="Close notification"
            />
            }

            {
              customBannerButton &&

              <button
                  id={"remindMeButton"}
                  className=" fds-banner-notification__close-button "
                  onClick={onCloseMethod}
                  aria-label="Close notification"
              style={{color:"#4D4D4D", fontSize:"16px",fontFamily: "Ford Antenna Regular"}}>
                Remind me later
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
