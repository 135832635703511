const fmaFunctions = () => {
    return {
        login: () => {
            if (window?.fma) {
                window.fma.login();
            } else {
                throw new Error("FMA not loaded. Cannot login.");
            }
        },
        logout: () => {
            if (window?.fma) {
                window.fma.logout();
                localStorage.removeItem("loginTimestamp")
                sessionStorage.removeItem("surveyBannerClosed")
            } else {
                throw new Error("FMA not loaded. Cannot logout.");
            }
        }
    };
};
export default fmaFunctions;
