import {defaultProductSubtitle, loremEpsum} from "./TestConstants";
export const FORD_POWERUP_SOFTWARE_UPDATES = "Ford Power-Up Software Updates"
export const productDescription = {
    'ford power-up software updates':"As a member of the Early Access Program, " +
        "you’re able to receive Ford Power-Up Software Updates before the general public based on your vehicle type and model year.",
    "connected charge station": loremEpsum,
    "ota_software_updates": loremEpsum,
    'intelligent charging' : loremEpsum,
    "blue_cruise": loremEpsum,
    'ford connected charge station - auto unlock': loremEpsum
}
export const productSubtitle = {
    'ford power-up software updates':"Over the Air Updates",
    "connected charge station": defaultProductSubtitle,
    "ota_software_updates": defaultProductSubtitle,
    'intelligent charging' : defaultProductSubtitle,
    "blue_cruise": defaultProductSubtitle,
    'ford connected charge station - auto unlock': defaultProductSubtitle
}
export const powerUpSoftwareUpdateDescription = {
    "expired": false,
    "lastInvited": "2022-12-02T15:30:49.098Z",
    "productId": 0,
    "productName": "Ford Power-Up Software Updates",
    "vehicleNames": "Mustang Mach E"
}
export const availableProductStatus = {
    "expired": ""
}