import MyProductSModuleCss from "./MyProducts.module.css";
import React from "react";

const MyProductsDefaults = (props) => {

    const {tabName} = props
    const userMessage = {
        ENROLLED: "You are not enrolled in any products at this time.",
        AVAILABLE: "You do not have any new early access product(s) at the moment, but when we launch \n" +
            "more products, you’ll be the first to know. ",
        PAST: "You are not enrolled in any products at this time."
    }

    return(
        <div className={MyProductSModuleCss.alignMiddle}>
            {userMessage[tabName]}
        </div>
    )
}

export default MyProductsDefaults;

