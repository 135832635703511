import ContactUsCss from "./ContactUs.module.css"
import React from "react";
import {connect} from "react-redux";
import NoticeTemplateCss from "../account/accountSubModules/NoticeTemplate.module.css";
import {useHistory} from "react-router-dom";

const ContactUs = (props) => {

    const history = useHistory();

    const handleBackBtn =()=>{
        if(props.backToAccount === undefined){
            history.push("/account")
        }else {
           return  props.backToAccount("account")
        }
    }

    return (
        <div className={ContactUsCss.contactUsContainer}>
            <h4 className={"fmc-type--heading4 fds-align--center"}>Let’s Get This Fixed</h4>
            <div className={ContactUsCss.messageContainer}>
            <div className={"fmc-cards"} >
                <div className="fmc-cards__card  fds-layout-grid__cell">
                    <div className="fmc-cards__content">
                        <div className="fmc-cards__footer" style={{borderTop: "none"}}>
                                <h6 className={"fmc-type--heading6 fds-align--center fmc-type--body1"}>We’re working to
                                    improve your experience</h6>
                                <p className={"fds-align--center fmc-type--body1"}>
                                    We are ramping up our ability to handle incoming issues on this site in order to
                                    meet the needs of <br/>
                                    our growing Early Access Member Community. Until then, please call our Customer
                                    Relationship <br/>
                                    Center at <a data-testid={"phoneNumber"} style={{color: "#1700F4"}}
                                                 href="tel:1-866-259-0176" target="_blank" rel="noreferrer">
                                    1-866-259-0176 </a> to report any issues, and they will ensure you are promptly
                                    taken care of.
                                </p>
                                <p className={"fds-align--center "}>For any feedback or suggestion regarding the Ford
                                    Early Access Program contact us at <br/>
                                    <a className={"fds-align--center"} style={{color: "#1700F4"}}
                                       href="mailto:pearlyac@ford.com" target="_blank" rel="noreferrer">
                                        pearlyac@ford.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            <div className={NoticeTemplateCss.buttonContainer}>
                    <button className={"fmc-text-button fds-p--t-2"}
                            onClick={handleBackBtn}
                            data-testid={"cancelLeaveProgram"}>
                        Back to account
                    </button>
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};

export default connect(mapStateToProps)(ContactUs);
