import React from "react";
import ReactCard from "../../../assets/js/ReactCard";
import ReactPrimaryButton from "../../../assets/js/ReactPrimaryButton";
import fmaFunctions from "../../../services/fmaFunctions";

export default function IneligibleCountry() {
    const returnHome = () => fmaFunctions().logout();

    return (
        <div className="fds-app">
            <div className="fds-color__bg--lt-gray">
                <div
                    style={{
                        maxWidth: "650px",
                        marginRight: "auto",
                        marginLeft: "auto",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                    className="fds-p--t-2 fds-p--b-2"
                >
                    <ReactCard
                        alertMessage="Not Available"
                        alertType="error"
                        cardImage={null}
                        cardTitle="Thank you for your interest. At this time, the Ford Early Access Program is available in the U.S. only."
                        cardBody={null}
                        cardSubtitle={null}
                        cardFooter={
                            <ReactPrimaryButton
                                dataTestId="returnHome"
                                colorScheme="light"
                                onClick={returnHome}
                            >
                <span
                    className="
                            fds-primary-button__button-text
                            fds-primary-button__button-icon--chevron-right"
                >
                  Return to Homepage
                </span>
                            </ReactPrimaryButton>
                        }
                    />
                </div>
            </div>
        </div>
    );
}
