import React from "react";
import Loading from "../../components/common/Loading/Loading";
import AccountModuleCss from "./Account.module.css";


const ViewProfile = (props)=>{

    return (
        <div className={AccountModuleCss.profileUser}>
            <div className="fmc-cards fds-align--center">
                <div className="fmc-cards__card fds-cards__card--horizontal">
                    <div className="fmc-cards__content">
                        <div className={AccountModuleCss.editViewFieldContainer}>
                            <h3> Personal Details </h3>
                            {!props.loader && <button className={"fmc-button fmc-button--outlined "}
                                     onClick={props.handleEdit}
                            >Edit
                            </button>}

                        </div>
                        {props?.loader ? <Loading/> :
                            <div className={AccountModuleCss.editViewTableContainer}>
                                <table className={"fmc-table"}>

                                    <tr>
                                        <th>Age</th>
                                        <td data-testid="age">{props?.userData.age}</td>
                                    </tr>
                                    <tr>
                                        <th>Gender</th>
                                        <td data-testid="gender">{props?.userData.gender}</td>
                                    </tr>
                                    <tr>
                                        <th>Phone Number</th>
                                        <td>{props?.userData.phoneNumber}</td>

                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>{props?.userData.city}</td>
                                    </tr>

                                    <tr>
                                        <th>State</th>
                                        <td>{props?.userData.state}</td>
                                    </tr>

                                    <tr>
                                        <th>Zip Code</th>
                                        <td>{props?.userData.zipcode}</td>
                                    </tr>
                                </table>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )


}
export default ViewProfile;