/**
 * Displays error within components.
 * Props:
 * errors - array, the list of errors being reported. Can be either strings or
 * error objects. In prod these are hidden. The purpose of this is to render
 * data to be captured by Dynatrace.
 * children: JSX contained within the ErrorDisplay component. Rendered as provided.
 * rest: Any additional properties, appended to the outer div.
 */
import "./ErrorDisplay.css";
import Error from "./Error";

const ErrorDisplay = ({errors = [], children, ...rest}) => {
    return (
        <div className="errorDisplay" {...rest}>
            {children}
            <ul>
                {errors.map((error, index) => {
                    return (
                        <li key={index}>
                            <Error error={error}/>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ErrorDisplay;
