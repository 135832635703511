import  {VEHICLES_SUCCESS, VEHICLES_ERROR,} from "../ActionTypes/UserDataActionTypes";

const initialState = {
    enrolledVehicles: []
}

const vehicleReducer = (state = initialState, action) => {
    switch (action?.type) {
        case VEHICLES_SUCCESS:
            return {
                ...state,
                enrolledVehicles: action.payload
            }
        case VEHICLES_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;

    }
}

export  default  vehicleReducer