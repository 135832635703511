import React from "react";
import Loading from "../components/common/Loading/Loading";
import {Route, Switch} from "react-router-dom";
import HomeModule from "../modules/home/HomeModule";
import ConsentModule from "../modules/consent/ConsentModule";
import ContactUs from"../modules/contactUs/ContactUs";
import TermsAndConditions from "../modules/termsAndConditions/TermsAndConditions";
import PrivateRoute from "./PrivateRoute";
import userAuthentication from "../services/userAuthentication";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import fmaFunctions from "../services/fmaFunctions";
import ExpressInterest from "../modules/expressInterest/ExpressInterest";
import FinalizeEnrollment from "../modules/enrollment/FinalizeEnrollment";
import FinalizeEnrollmentConfirmation from "../modules/enrollment/finalizeEnrollmentSubModules/FinalizeEnrollmentConfirmation";
import ActiveInactiveEnrollment from "../modules/activeInactiveEnrollment/ActiveInactiveEnrollment";
import AccountNavigation from "../modules/account/AccountNavigation";
import IntelligentPower from "../modules/intelligentpower/IntelligentPower";

const AppRouter = () => {

    let history = useHistory()
    let pathCheck = !["/","/fpterms","/eapterms"].includes(window.location.pathname)

    if(window.fma.isAuthenticated===false && pathCheck) {
        fmaFunctions().login()
        localStorage.setItem("path",history.location.pathname)
    }else if(window.fma.isAuthenticated === undefined) {
        userAuthentication();
    }

    return (
        <Switch>
            <Route exact path="/" component={HomeModule}/>
            <PrivateRoute exact path="/consent" component={ConsentModule}/>
            <PrivateRoute exact path="/account" component={AccountNavigation}/>
            <PrivateRoute exact path="/welcome" component={FinalizeEnrollmentConfirmation}/>
            <PrivateRoute exact path="/contactus" component={ContactUs}/>
            <PrivateRoute exact path="/expressInterest" component={ExpressInterest}/>
            <PrivateRoute exact path="/finalizeEnrollment" component={FinalizeEnrollment}/>
            <PrivateRoute exact path="/activeInactiveEnrollment" component={ActiveInactiveEnrollment}/>
            <Route exact path="/intelligentpower" component={IntelligentPower}/>
            <Route exact path="/fpterms" component={TermsAndConditions}/>
            <Route exact path="/eapterms" component={TermsAndConditions}/>
            <Route exact path="/loading" component={Loading}/>
        </Switch>
    );
};
export default AppRouter;
