import HomeModulePart2CSS from "../home/homeModulePart2/HomeText.module.css";
import ReactHorizontalCard from "../../assets/js/ReactHorizontalCard";
import AccountModuleCss from "../account/Account.module.css";
import MyProductsModuleCss from "./MyProducts.module.css";
import {getDynamicCardStyle, getFormattedDate} from "../../util/Format";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {
    FORD_POWERUP_SOFTWARE_UPDATES,
    powerUpSoftwareUpdateDescription,
    productDescription,
} from "../../Constants/Products";
import {JOIN, TAKESURVEY} from "../../Constants/CTA";
import {AVAILABLE, ENROLLED, PAST} from "../../Constants/Status";
import {useHistory} from "react-router-dom";

const MyProductsCard = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const history = useHistory();
    let {products, tabName} = props

    let enrolledProduct = props?.productReducer?.enrolledProducts || []

    if (tabName === ENROLLED) {
        let powerUpSoftwareExists = products.find(items => items.productName === FORD_POWERUP_SOFTWARE_UPDATES)
        if (!Boolean(powerUpSoftwareExists)) products.unshift(powerUpSoftwareUpdateDescription)
    }

    const tabCtaMap = {
        ENROLLED: TAKESURVEY,
        AVAILABLE: JOIN
    }
    const handleDynamicCta = async (parameters) => {
        if (tabName === ENROLLED) {
        }
        let {productName} = parameters;
        if (productName === "BlueCruise") {
            history.push("/bluecruise-signup")
        }
    }

    const checkDisabled = (items) => {
        if (tabName === ENROLLED) {
            return (enrolledProduct.find(item => item.productName === items.productName && item.surveyStatus === "NO_ACTIVE_SURVEY"))
        }
        return false
    }

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    },)

    const productStatus = {
        ENROLLED: "Testing in-progress",
        AVAILABLE: "Ready to join"
    }

    const productStatusStyle = {
        ENROLLED: " fds-color__bg--success2 ",
        AVAILABLE: " fds-color__bg--secondary "
    }

    return (
        <div className="fds-layout-grid fds-m--t-4">
            <div className={HomeModulePart2CSS.carouselHeader}>
                <div className=" fds-layout-grid__inner fds-p--b-3">
                    {products.map((step, idx) => {
                        return (
                            <ReactHorizontalCard key={idx}
                                                 className={classNames(getDynamicCardStyle(width), MyProductsModuleCss.productCardContainer)}
                            >
                                <div className=" fmc-layout-grid__cell--span-3">
                                    <div>
                                        <div className={MyProductsModuleCss.productTitleLabelContainer}>
                                            <div className={MyProductsModuleCss.productTitleLabelFlex}>
                                                <div className={MyProductsModuleCss.productCardTitle}>
                                                    {step.productName}
                                                </div>
                                                {step.productName !== FORD_POWERUP_SOFTWARE_UPDATES && <span
                                                    className={classNames(MyProductsModuleCss.productStatusLabel, productStatusStyle[tabName])}>
                                                    {productStatus[tabName]}
                                                </span> || <span
                                                    className={classNames(MyProductsModuleCss.productStatusLabel, productStatusStyle[tabName])}>Ongoing</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={MyProductsModuleCss.productCardDescription}>
                                        {step.description || productDescription["ford power-up software updates"]}
                                    </div>
                                    {(step.productName !== FORD_POWERUP_SOFTWARE_UPDATES) &&
                                    <div data-testid={step.header + `Description`}
                                         className={MyProductsModuleCss.productCardDescriptionContainer}>
                                        <div className={MyProductsModuleCss.invitedOn}>
                                            {tabName === ENROLLED ?
                                                "Enrolled on" : "Invited on"}
                                        </div>
                                        <div className={MyProductsModuleCss.invitedOn}>
                                            {tabName === AVAILABLE && getFormattedDate(step.lastInvited)}
                                            {tabName === ENROLLED && getFormattedDate(step.createdOn)}
                                            {tabName === PAST && getFormattedDate(step.endDate)}
                                        </div>
                                    </div>}
                                    {(step.productName !== FORD_POWERUP_SOFTWARE_UPDATES) && <hr/>}
                                </div>
                                {(step.productName !== FORD_POWERUP_SOFTWARE_UPDATES) && <button
                                    onClick={() => handleDynamicCta(step)}
                                    disabled={checkDisabled(step)}
                                    className={classNames("fmc-button ", AccountModuleCss.accountBtnContainer)}>
                                    {tabCtaMap[tabName]}
                                </button>
                                }
                            </ReactHorizontalCard>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        ...state,
        loading: state.userDataReducer.loading,
        user: state.userDataReducer.user
    };
};
export default connect(mapStateToProps)(MyProductsCard);