import classNames from "classnames";
import AccountModuleCss from "../../../modules/account/Account.module.css";
import React from "react";

export const CustomMenu = ({vehicleName, status, menuOpen, needChevron = false }) => {
    const bgColor = {
        ENROLLED: " fds-color__bg--primary",
        ELIGIBLE: " fds-color__bg--secondary",
        WAITLISTED: " fds-color__bg--error1",
        NOT_INVITED: " fds-color__bg--error1",
        INVITED: " fds-color__bg--secondary"
    }

    const chevronDirection = {
        false: " fds-font--ford-icons__chevron-down ",
        true: " fds-font--ford-icons__chevron-up "
    }

    return (
        <div style={{display: "flex", justifyContent: "space-between", width: "16em"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <span> {vehicleName} </span>
                <span
                    className={classNames(AccountModuleCss.vehicleStatusContainer, bgColor[status.toUpperCase()])}>
                        {status}
                    </span>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                {needChevron ? <span data-testId={"menuChevron"}
                                     className={chevronDirection[menuOpen] + " fds-icon fds-icon--16 fds-m--l-3"}/>
                    : <span className={" fds-icon fds-icon--16 fds-m--l-3 "}/>}
            </div>
        </div>)
}