import fetchOptions from "./fetchOptions";
import store from "../redux/store/userStore";
import {errorUserData} from "../redux/Actions/UserDataActions";

export const userDetailsService = {
    getUserProfile: async () => {
        let user = null;
        let userVehicleInfo = null;
        let userProductsInfo = null;
        let userInvites = null;
        let genericSurvey = null;
        let questionnaireCompleted = null;

        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/user/memberInfo`,
            fetchOptions("GET")
        );
        const data = await response.json().catch((error) => {});
        if (response.ok) {
            user = data;
            userVehicleInfo = data["vehicleInfo"];
            userProductsInfo = data["productInfo"];
            userInvites = data["invites"];
            genericSurvey = data["genericSurveys"];
            questionnaireCompleted = data["questionnaireCompleted"];
        } else {
            store.dispatch(errorUserData({error: data.error}));
            throw new Error(data);
        }

        return [user, userVehicleInfo, userProductsInfo, userInvites, genericSurvey,questionnaireCompleted];
    },
    saveUser: async (vin) => {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/user/${vin}/save`,
            fetchOptions("POST", null)
        );

        const data = await response.json().catch((error) => {});

        if (!response.ok) {
            store.dispatch(errorUserData({error: data.error}));
            throw new Error(data);
        }
    },
    removeVehicleForUser: async (vehicleId) => {
        //soft delete
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/vehicle/${vehicleId}/leave`,
            fetchOptions("DELETE")
        );

        const data = await response.json().catch((error) => {});

        if (!response.ok) {
            store.dispatch(errorUserData({error: data.error}));
            throw new Error(data);
        }
    },
    leaveEAP: async (vinsArray) => {
        let vinObject = {
            "vins": vinsArray
        }
        let postBody = {
            body: JSON.stringify(vinObject)
        }

        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/vehicle/leave-eap`,
            fetchOptions("DELETE", postBody)
        );

        const data = await response.json().catch((error) => {});

        if (!response.ok) {
            store.dispatch(errorUserData({error: data.error}));
            throw new Error(data);
        }
    },
    getAccountInfo: async () => {
        let accountEnrolledVehicles = null;
        let accountAvailableProducts = null;
        let accountEnrolledProducts = null;
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/user/accountInfo`,
            fetchOptions("GET")
        );
        const data = await response.json().catch((error) => {});

        if (response.ok) {
            accountEnrolledVehicles = data["enrolledVehicles"];
            accountAvailableProducts = data["availableProducts"];
            accountEnrolledProducts = data["enrolledProducts"]
        } else {
            store.dispatch(errorUserData({error: data.error}));
            throw new Error(data);
        }
        return [ accountEnrolledVehicles, accountAvailableProducts, accountEnrolledProducts]
    },
    expressInterestSignUp: async (signUpDetails)=>{
        let postBody = {
            body: JSON.stringify(signUpDetails)
        }
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/user/signup`,
            fetchOptions("POST", postBody)
        );
        try{
            const data = await response.text()

            if (!response.ok) {
                store.dispatch(errorUserData({error: data}));
            }
        }catch (e){
            console.log(e)
        }
    },
    replaceVehicle: async (vin) => {
        try{
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/vehicle/replaceVehicle?vin=${vin}`,
                fetchOptions("POST")
            )
            const data = await response.text()
            if (!response.ok) {
                store.dispatch(errorUserData({error: data}));
            }
        }catch (e){
            console.log(e)
            store.dispatch(errorUserData({error: e}));
            throw new Error(e);
        }
    },lastLogin:async ()=>{
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/user/last-login`,
                fetchOptions("POST")
            );
            try{
                const data = await response.text();

                if (!response.ok) {
                    store.dispatch(errorUserData({error: data}));
                }
            }catch (e){
                console.log(e)
            }
        }catch (e){
            store.dispatch(errorUserData({error: e}));
            throw new Error(e);
        }
    },
    userAccountInfo:async ()=>{
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/user/userInfo`,
            fetchOptions("GET")
        );
        const data = await response.json().catch((error) => {});

        if (!response.ok) {
            store.dispatch(errorUserData({error: data.error}));
            throw new Error(data);
        }
        return data;
    },
    updateAccount:async (contract)=>{
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/user/update-userInfo`,{
            mode: "cors",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                "auth-token": window?.fma?.["CATBundle"]?.["access_token"] ?? null,
                "application-id": process.env.REACT_APP_APPLICATION_ID,
            },
            body:JSON.stringify(contract)
        }
        );
        if (!response.ok) {
            store.dispatch(errorUserData({error: contract.error}));
            throw new Error(contract);
        }
        return response.status;
    }

};
